import {TranslateService} from "@ngx-translate/core";

export function donorIndividualSteps(translate: TranslateService) {
    return [
        {
            label: translate.instant('entity.donor.steps.donorType'),
            routerLink: '/auth/register/donor/step1'
        },
        {
            label: translate.instant('entity.donor.steps.individualDetails'),
            routerLink: '/auth/register/donor/individual'
        },
        {
            label: translate.instant('entity.donor.steps.otpConfirmation'),
            routerLink: '/auth/register/donor/step2'
        },
        {
            label: translate.instant('entity.donor.steps.credentials'),
            routerLink: '/auth/register/donor/step3'
        }
        // {
        //     label: translate.instant('entity.donor.steps.credentials'),
        //     routerLink: '/auth/register/donor/step4'
        // }
    ];
}
