import {Component, HostListener} from '@angular/core';
import {DialogService} from "primeng/dynamicdialog";
import {TermsAndConditionsComponent} from "./terms-conditions.component";
import {TermsFAQComponent} from "./terms-faq.component";
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../components/generics/language.service';
import {Language} from '../components/generics/language.service';
import {Subscription } from 'rxjs';
import {PrivacyPolicyComponent} from "./privacy-policy.component";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent {
    language: Language;
    isDesktop: boolean = true;
    private langSubscription: Subscription;
    constructor(private dialogService: DialogService ,
                private router: Router, private sanitizer: DomSanitizer,
                private translate: TranslateService,
                private languageService: LanguageService) {
        this.onResize();
    }

    showTermsAndConditions() {
        const translatedHeader = this.translate.instant('footer.termsConditions');
        this.dialogService.open(TermsAndConditionsComponent, {
            header: translatedHeader,
            width: '90%',
            style: {
                'max-width': '700px'
            }
        });
    }


      showPrivacy() {
            const translatedHeader = this.translate.instant('footer.privacy');
            this.dialogService.open(PrivacyPolicyComponent, {
                header: translatedHeader,
                width: '90%',
                style: {
                    'max-width': '700px'
                }
            });
        }

    showFAQ() {
        const translatedHeader = this.translate.instant('footer.copyright_part8');
        this.dialogService.open(TermsFAQComponent, {
        header: translatedHeader,
            width: '90%',
            style: {
                'max-width': '700px'
            }
        });
    }

    contactUs() {
        this.router.navigate(['/send-email', { email:'info@opop.mk' }]);
    }

    techSupport() {
        this.router.navigate(['/send-email', { email:'support@opop.mk' }]);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    ngOnInit(): void {
        this.langSubscription = this.languageService.getSelectedLanguage().subscribe(lang => {
            this.language = lang;
        });
    }

    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }

    getTrustedHtml(key: string): any {
        const value = this.translate.instant(key);
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }

}



