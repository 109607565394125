import {Component, OnInit, ViewEncapsulation,HostListener} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {errorHandler} from "../../../../support/functions";
import {UserService} from "../../../../services/user.service";
import {AuthService} from "../../../../support/auth.service";
import {StorageService} from "../../../../support/storage.service";
import {Donor} from "../../../../model/donor";
import {Role} from "../../../../enums/role";
import {environment} from "../../../../../environments/environment";
import {donorIndividualSteps} from "./donor-individual-steps";
import {donorOrganizationSteps} from "./donor-organization-steps";
import {DonorType} from "../../../../enums/donor-type";


@Component({
    templateUrl: './donor-register-step3.component.html',
    styles: [`body {
        background: var(--surface-ground);
    }`],
    encapsulation: ViewEncapsulation.None
})
export class DonorRegisterStep3Component implements OnInit {
    isDesktop: boolean = true;
    public form: UntypedFormGroup;
    public readonly donor: Donor;
    public showDialog = false;
    displayName = ''
    items: MenuItem[];

    constructor(public layoutService: LayoutService, private router: Router, private userService: UserService,
                private msgService: MessageService, public translate: TranslateService,
                private confirmationService: ConfirmationService, private authService: AuthService,
                private storageService: StorageService) {
            this.onResize();
            this.donor = this.router.getCurrentNavigation().extras?.state?.donor;
            this.items = this.donor.donorType === DonorType.INDIVIDUAL ? donorIndividualSteps(this.translate) : donorOrganizationSteps(this.translate);

            if (this.donor.company) {
                this.form = new UntypedFormGroup({
                    email: new UntypedFormControl({value: this.donor?.company?.contactPerson?.email, disabled: true}),
                    phoneNumber: new UntypedFormControl({value: this.donor?.company?.contactPerson?.phone, disabled: true}),
                    password: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(8)])),
                    passwordRetype: new UntypedFormControl(null, Validators.required),
                    donorId: new UntypedFormControl(this.donor?.id),
                    role: new UntypedFormControl(Role.DONOR),
                });
            } else {
                this.form = new UntypedFormGroup({
                    email: new UntypedFormControl({value: this.donor?.person?.email, disabled: true}),
                    phoneNumber: new UntypedFormControl({value: this.donor?.person?.phone, disabled: true}),
                    password: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(8)])),
                    passwordRetype: new UntypedFormControl(null, Validators.required),
                    donorId: new UntypedFormControl(this.donor?.id),
                    role: new UntypedFormControl(Role.DONOR),
                });
        }
    }

    ngOnInit(): void {
        if (!this.donor) {
            this.router.navigate(['/'])
        }
        this.displayName = this.getDonorName();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    get dark(): boolean {
        return this.layoutService.config.colorScheme !== 'light';
    }

    getDonorName(): string {
        if (this.donor.donorType === 'INDIVIDUAL') {
            return this.donor.person.firstName;
        }
        else if (this.donor.donorType === 'ORGANIZATION') {
            return this.donor.company.companyName;
        }
        return ''; // Default return value if none of the conditions are met
    }
    submit() {
        this.form.controls.donorId.setValue(this.donor?.id);


        this.userService.signUpAndLogin(this.form.getRawValue()).subscribe(token => {
            if (token) {
                localStorage.setItem(environment.LOCAL_STORAGE_TOKEN, token);
                this.authService.fetchUserDetails().subscribe(userDetails => {
                    this.storageService.setValue(environment.USER_DETAILS, userDetails);

                    if (this.donor.donorType === DonorType.INDIVIDUAL) {
                        this.showDialog = true;
                    } else if (this.donor.donorType === DonorType.ORGANIZATION) {
                        this.showDialog = true;
                    }
                }, error => {
                    console.error("Failed to fetch user details", error);
                });

            } else {
                console.log("SIGNUP NOT OK");
            }
        }, error => {
            console.error("Signup failed", error);
        });
    }
    onDialogOk() {
        this.showDialog = false;
        this.router.navigate(['/']);

//         if (this.donor.donorType === DonorType.INDIVIDUAL)
//             this.router.navigate(['/']);
//
//         else  if (this.donor.donorType === DonorType.ORGANIZATION)
//             this.router.navigateByUrl(`auth/register/donor/step4`, {
//             state: { donor: this.donor, individual: false }
//         });

    }



    // async submit() {
    //     console.log("SUBMIING");
    //     this.form.controls.donorId.setValue(this.donor?.id);
    //
    //     const signUp = await firstValueFrom(this.userService.signUpAndLogin(this.form.getRawValue()))
    //         .then(token => {
    //                 localStorage.setItem(environment.LOCAL_STORAGE_TOKEN, token);
    //                 return true;
    //             },
    //             error => {
    //                 errorHandler(this.translate.instant('authentication.registrationFailed'), error, this.msgService, this.translate, this.router);
    //                 return false;
    //             }
    //         );
    //
    //     if (signUp) {
    //         console.log("SIGNUP OK");
    //         firstValueFrom(this.authService.fetchUserDetails())
    //             .then(userDetails => {
    //                     this.storageService.setValue(environment.USER_DETAILS,userDetails);
    //                     this.confirmationService.confirm({
    //                         message: this.translate.instant('msg.successfullyCreatedDonor'),
    //                         rejectVisible: false,
    //                         closeOnEscape: false,
    //                         acceptLabel: 'OK',
    //                         icon: 'pi pi-info-circle',
    //                         accept: () => {
    //                             this.confirmationService.confirm({
    //                                 message: this.translate.instant('msg.successfullySaved'),
    //                                 rejectVisible: false,
    //                                 closeOnEscape: false,
    //                                 acceptLabel: 'OK',
    //                                 icon: 'pi pi-info-circle',
    //                                 accept: () => {
    //                                     if (this.donor.donorType=== DonorType.INDIVIDUAL) {
    //                                         console.log("DonorType.INDIVIDUAL");
    //                                         this.router.navigate(['/']);
    //                                         return;
    //                                     }
    //                                     else if (this.donor.donorType === DonorType.ORGANIZATION) {
    //                                         console.log("DonorType.ORGANIZATION");
    //                                         this.router.navigateByUrl(`auth/register/donor/step4`, {
    //                                             state: {donor: this.donor, individual: true}
    //                                         });
    //
    //                                     }
    //                                     else {
    //                                         console.log("DonorType.ELSE");
    //                                         this.router.navigateByUrl(`/`, {
    //                                             state: {donor: this.donor, individual: true}
    //                                         });
    //                                     }
    //                                 }
    //                             });
    //                         }
    //                     });
    //                 },
    //                 error => {
    //                     errorHandler(this.translate.instant('login.authFailed'), error, this.msgService, this.translate, this.router);
    //                     localStorage.removeItem(environment.LOCAL_STORAGE_TOKEN);
    //                 }
    //             );
    //     }
    //     else {
    //         console.log("SIGNUP NOT OK");
    //     }
    // }


    // async submit() {
    //     this.form.controls.donorId.setValue(this.donor?.id);
    //
    //     const signUp = await firstValueFrom(this.userService.signUpAndLogin(this.form.getRawValue()))
    //         .then(token => {
    //                 localStorage.setItem(environment.LOCAL_STORAGE_TOKEN, token);
    //                 return true;
    //             },
    //             error => {
    //                 errorHandler(this.translate.instant('authentication.registrationFailed'), error, this.msgService, this.translate, this.router);
    //                 return false;
    //             }
    //         );
    //
    //     if (signUp) {
    //         firstValueFrom(this.authService.fetchUserDetails())
    //             .then(userDetails => {
    //                     this.storageService.setValue(environment.USER_DETAILS, userDetails);
    //
    //                     this.confirmationService.confirm({
    //                         message: this.translate.instant('msg.successfullyCreatedDonor'),
    //                         rejectVisible: false,
    //                         closeOnEscape: false,
    //                         acceptLabel: 'OK',
    //                         icon: 'pi pi-info-circle',
    //
    //                         accept: () => {
    //                             this.router.navigateByUrl(`auth/register/donor/step4`, {
    //                                 state: {donor: this.donor, individual: true}
    //                             });
    //                         },
    //                         reject: () => {
    //                             this.confirmationService.confirm({
    //                                 message: this.translate.instant('msg.successfullySaved'),
    //                                 rejectVisible: false,
    //                                 closeOnEscape: false,
    //                                 acceptLabel: 'OK',
    //                                 icon: 'pi pi-info-circle',
    //                                 accept: () => {
    //                                     this.router.navigateByUrl(`auth/register/donor/step4`, {
    //                                         state: {donor: this.donor, individual: true}
    //                                     });
    //                                 }, // Corrected this to a comma
    //                                 reject: () => this.router.navigate(['/'])
    //                             });
    //                         }
    //                     });
    //
    //                 },
    //                 error => {
    //                     errorHandler(this.translate.instant('login.authFailed'), error, this.msgService, this.translate, this.router);
    //                     localStorage.removeItem(environment.LOCAL_STORAGE_TOKEN);
    //                 }
    //             );
    //     }
    // }
    protected readonly DonorType = DonorType;
}
