import {Municipality} from "./municipality";
import {Country} from "./country";
import {CompanyType} from "../enums/company-type";
import {CompanyPersonCreate, Person, PersonType} from "./person";
import {Bank} from "./bank";
import {IdReferenceType} from "./id-reference-type";
// import {CompanySubcategory} from "./company-subcategory";


export interface Company {
    id: string;
    companyName: string;
    companyShortName: string;
    companyInformation: string;
    goals: string;
    previousActivities: string;
    companyVat: string;
    companyRegNr: string;
    companyStreetAddress: string;
    companyMunicipality: Municipality;
    companyCountry: Country;
    companyZip: string;
    companyPhone: string;
    companyRegistrationDate: Date;
    companyCategory: CompanyCategory;
    companySubcategory: CompanySubcategory;
    companyType: CompanyType;
    companyInfoMail: string;
    companyWww: string;
    companyCity: string;
    bankAccountNumber: string;
    logoId: string
    imageId: string
    contactPerson: Person;
    signaturePerson: Person;
    companyPersons: CompanyPerson[];
    bank: Bank;
    documentIds: string[];
}

export interface CompanyPerson {
    id: string;
    person: Person;
    personType: PersonType;
}

export interface CompanyCategory {
    id: number;
    name: string;
}

export interface CompanySubcategory {
    id: number;
    name: string;
}

export interface CompanyCreate extends CompanyCreateUpdate {
    companyPersons: CompanyPersonCreate[];
}

export interface CompanyCreateUpdate {
    companyName: string;
    companyShortName: string;
    companyInformation: string;
    previousActivities: string;
    goals: string;
    companyVat: string;
    companyRegNr: string;
    companyStreetAddress: string;
    companyMunicipality: IdReferenceType;
    companyCountry: IdReferenceType;
    companyCity: string;
    companyZip: string;
    companyPhone: string;
    companyRegistrationDate: Date;
    companyCategory: IdReferenceType;
    companySubcategory: IdReferenceType;
    companyType: CompanyType;
    companyInfoMail: string;
    companyWww: string;
    logoId: string;
    imageId: string;
    contactPerson: Person;
    signaturePerson: Person;
    bank: Bank;
    documentIds: string[];
}

export interface CompanyUpdate extends CompanyCreateUpdate {
    id: string;
    companyPersons: CompanyPerson[];
}

export function toCompanyUpdate(company: Company) {
    return {
        id: company?.id,
        companyName: company?.companyName,
        companyShortName: company?.companyShortName,
        companyInformation: company?.companyInformation,
        previousActivities: company?.previousActivities,
        goals: company?.goals,
        companyVat: company?.companyVat,
        companyRegNr: company?.companyRegNr,
        companyStreetAddress: company?.companyStreetAddress,
        companyZip: company?.companyZip,
        companyPhone: company?.companyPhone,
        companyRegistrationDate: company?.companyRegistrationDate,
        companyType: company?.companyType,
        companyInfoMail: company?.companyInfoMail,
        companyWww: company?.companyWww,
        companyCity: company?.companyCity,
        contactPerson: company?.contactPerson,
        signaturePerson: company?.signaturePerson,
        bank: company?.bank,
        documentIds: company?.documentIds,
        companyCategory: {id: company?.companyCategory?.id} as IdReferenceType,
        companySubcategory: {id: company?.companySubcategory?.id} as IdReferenceType,
        companyMunicipality: {id: company?.companyMunicipality?.id} as IdReferenceType,
        companyCountry: {code: company?.companyCountry?.code} as IdReferenceType,
        logoId: company?.logoId,
        imageId: company?.imageId,
        companyPersons: company?.companyPersons
    } as CompanyUpdate
}

export function toCompanyCreate(company: Company){
    return {
        companyName: company?.companyName,
        companyShortName: company?.companyShortName,
        companyInformation: company?.companyInformation,
        previousActivities: company?.previousActivities,
        goals: company?.goals,
        companyVat: company?.companyVat,
        companyRegNr: company?.companyRegNr,
        companyStreetAddress: company?.companyStreetAddress,
        companyZip: company?.companyZip,
        companyPhone: company?.companyPhone,
        companyRegistrationDate: company?.companyRegistrationDate,
        companyType: company?.companyType,
        companyInfoMail: company?.companyInfoMail,
        companyWww: company?.companyWww,
        contactPerson: company?.contactPerson,
        signaturePerson: company?.signaturePerson,
        companyCity: company?.companyCity,
        bank: company?.bank,

        documentIds: company?.documentIds,
        companyCategory: {id: company?.companyCategory?.id} as IdReferenceType,
        companySubcategory: {id: company?.companySubcategory?.id} as IdReferenceType,
        companyMunicipality: {id: company?.companyMunicipality?.id} as IdReferenceType,
        companyCountry: {code: company?.companyCountry?.code} as IdReferenceType,
        logoId: company?.logoId,
        imageId: company?.imageId,
        companyPersons: company?.companyPersons
    } as CompanyCreate
}
