import {DocumentService} from "../../services/document.service";
import {UntypedFormGroup} from "@angular/forms";
import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {FileUpload} from "primeng/fileupload";
import {ConfirmationService, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {DocumentType} from "../../enums/document-type";
import {environment} from "../../../environments/environment";
import {Document} from "../../model/document";
import {confirmAction, errorHandler} from "../../support/functions";
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";

@Component({
    templateUrl:'opop-image-upload.component.html',
    selector: 'opop-image-upload',
    styles: [`
        .p-fileupload-choose {
            display: none !important;
        }

        .p-fileupload .p-fileupload-buttonbar {
            border: 0;
        }

        .p-fileupload .p-fileupload-content {
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            padding: 0
        }
    `],
    encapsulation: ViewEncapsulation.None
})

export class OpopImageUploadComponent implements OnInit {
    @Input()
    public form: UntypedFormGroup;
    @ViewChild('fileUploader') fileUpload: FileUpload;
    @Input() imagesControlName: string;
    @Input() documentIdsControlName: string;

    constructor(private documentService: DocumentService, private msgService: MessageService, private translate: TranslateService,
                private confirmationService: ConfirmationService, private router: Router) {
    }

    ngOnInit(): void {
        this.setDefaultSelectedImage();
        console.log ('images', this.imagesControlName);
    }

    handleFileUpload(event: any) {
        const formData = new FormData();
        for (let file of event.files) {
            formData.append('files', file, file.name);
        }

        this.documentService.uploadDocument(formData, DocumentType.PORTRAIT_PHOTO).subscribe({
            next: response => {
                if (response.body) {
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('msg.upload-success')
                    });
                    this.form.controls[this.imagesControlName].setValue([...this.form.controls[this.imagesControlName].value, ...response.body]);
                    this.form.controls[this.documentIdsControlName].value.push(response.body[0].id);
                    this.fileUpload.clear();
                    this.setDefaultSelectedImage();
                }
            }, error: error => {
                this.msgService.add({
                    severity: 'error',
                    summary: this.translate.instant('error.header'),
                    detail: error.message
                });
            }
        });
    }


    get apiRef() {
        return environment.API_REF;
    }

    async confirmDelete(doc: Document) {
        const confirm = await confirmAction(this.confirmationService, this.translate);
        if (confirm) {
            this.deleteDocument(doc);
        }
    }

    downloadDocument(id: string, fileName: string) {
        firstValueFrom(this.documentService.downloadDocument(id)).then((blob: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloadLink.remove();
        });
    }

    deleteDocument(doc: Document) {
        firstValueFrom(this.documentService.deleteDocument(doc.id)).then(() => {
            const indexToDelete = this.form.controls[this.imagesControlName].value.indexOf(doc);
            this.form.controls[this.imagesControlName].value.splice(indexToDelete, 1);

            const relationIndexToDelete = this.form.controls[this.documentIdsControlName].value.indexOf(doc.id);
            this.form.controls[this.documentIdsControlName].value.splice(relationIndexToDelete, 1);

            this.msgService.add({
                severity: 'info',
                summary: 'Info',
                detail: this.translate.instant('msg.fileDeleteSuccess')
            });
        }, error => errorHandler('Error', error, this.msgService, this.translate, this.router))
    }

    get hasDocs(): string {
        return this.form.controls[this.imagesControlName].value?.length > 0 ? 'opopUpload-docs' : 'opopUpload-noDocs';
    }

    get hasImages(): boolean {
        if (this.form.controls[this.imagesControlName].value?.length > 0)
            return true;
        return false;
    }


    get hasDocsIcon(): string {
        return this.form.controls[this.imagesControlName].value?.length > 0 ? 'opopUploadIcon-docs' : 'opopUploadIcon-noDocs';
    }
    setDefaultSelectedImage() {
        if (!this.form.controls.imageId.value && this.form.controls[this.imagesControlName].value?.length > 0) {
            this.form.controls.imageId.setValue(this.form.controls[this.imagesControlName].value[0].id);
        }
    }

    makeMainImage(doc: Document) {
        this.form.controls.imageId.setValue(doc.id);

        this.msgService.add({
            severity: 'info',
            summary: 'Info',
            detail: this.translate.instant('msg.mainImageChanged')
        });
    }
}
