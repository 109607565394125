import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { loginGuard } from './support/auth-guard';
import { MailOutComponent } from './mail-out/mail-out.component';
import { VirmanComponent } from './virman/virman.component';
import { VirmanIsplataComponent } from './virman-isplata/virman-isplata.component';
import { PledgeComponent } from './pledge/pledge.component';

import { MakePaymentComponent } from './make-payment/make-payment.component';
// import { MakePaymentFPComponent } from './make-payment/make-payment-FP.component';

import {canManageAdminBeneficiaryGuard } from './components/administration-dashboard/beneficiaries/admin-beneficiary.guard';
import {ImageDisplayComponent } from './components/image-display.component';
import {TermsAndConditionsComponent } from './footer/terms-conditions.component';
import {DonorCampaignPreviewComponent} from "./components/campaign/donor-campaign-preview.component";

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
};

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./components/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: 'db-admin',
                canActivate: [canManageAdminBeneficiaryGuard],
                loadChildren: () =>
                    import(
                        './components/administration-dashboard/admin-dashboard.module'
                    ).then((m) => m.AdminDashboardModule),
            },
            {
                path: 'db-beneficiary',
                loadChildren: () =>
                    import(
                        './components/beneficiary-dashboard/beneficiary.module'
                    ).then((m) => m.BeneficiaryModule),
            },
            {
                path: 'db-donor',
                loadChildren: () =>
                    import(
                        './components/donor-dashboard/donor-dashboard.module'
                    ).then((m) => m.DonorDashboardModule),
            },
            {
                path: 'article',
                loadChildren: () =>
                    import(
                        './components/administration-dashboard/articles/article.module'
                    ).then((m) => m.ArticleModule),
            },
        ],
    },
    {
        path: 'campaign',
        loadChildren: () =>
            import('./components/campaign/donor-campaign.module').then(
                (m) => m.DonorCampaignModule
            ),
    },
    {
        path: 'auth',
        data: { breadcrumb: 'Auth' },
        loadChildren: () =>
            import('./template/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [loginGuard],
    },
    {
        path: 'reset-password',
        loadChildren: () =>
            import('./template/auth/newpassword/newpassword.module').then(
                (m) => m.NewPasswordModule
            ),
    },
    {
        path: 'send-email',
        component: MailOutComponent,
    },
    {
        path: 'virman',
        component: VirmanComponent,
    },
    {
        path: 'virman-isplata',
        component: VirmanIsplataComponent,
    },
    {
        path: 'pledge',
        component: PledgeComponent,
    },
    {
        path: 'make-payment',
        component: MakePaymentComponent,
    },
//     {
//         path: 'make-payment-fp',
//         component: MakePaymentFPComponent,
//     },
    {
        path: 'notfound',
        loadChildren: () =>
            import('./template/notfound/notfound.module').then(
                (m) => m.NotfoundModule
            ),
    },
    { path: 'img-view/:imgId', component: ImageDisplayComponent },
    { path: '', loadChildren: () => import('./template/auth/register/register.module').then(m => m.RegisterModule) },
    { path: '**', redirectTo: '/notfound' },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
