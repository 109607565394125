<div class="layout-container"  [ngClass]="containerClass">

    <app-topbar></app-topbar>

    <!--        [ngStyle]="getStyles()"-->
    <div class="layout-content-wrapper">

        <div *ngIf="isRootRoute()" class="header-container">
            <div class="front-image">
                <img src="/assets/opop/donation/MainPage_Devojce.jpg" alt="Header Image">
            </div>

            <div class="front-text" [innerHTML]="('top-bar.pageHeader' | translate)"></div>
            <div class="front-subtext" [innerHTML]="('top-bar.pageSubHeader' | translate)"></div>
            <div class="front-logo-large" [routerLink]="['/']">
                <img src="assets/opop/Logo_Konekt_kirilica_WHITE.png" alt="OpOp Logo"/>
            </div>
        </div>

        <div [ngClass]="isRootRoute() ? 'layout-content-home' : 'layout-content'">
                <router-outlet></router-outlet>
        </div>
    </div>

    <app-footer></app-footer>
    <app-cookie-consent></app-cookie-consent>
    <div class="layout-mask"></div>
</div>
<app-config [minimal]="true"></app-config>
