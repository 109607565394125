import {ActivatedRouteSnapshot, CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {AuthService} from "../../../support/auth.service";
import {Role} from "../../../enums/role";

export const canManageAdminBeneficiaryGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);

    if (await canManageAdminBeneficiary(route)) {
        return true;
    }
    router.navigate(['/auth/access']);
    return false;
};

export const canManageAdminBeneficiary: (route: ActivatedRouteSnapshot) => Promise<boolean> = async (route: ActivatedRouteSnapshot) => {
    const authService = inject(AuthService);

    if (!authService.isAuthenticated) {
        return false;
    }

    return authService.hasAnyAuthority([Role.ADMIN, Role.ADMIN_FINANCE, Role.ADMIN_PROJECT, Role.ADMIN_BENEFICIARY, Role.ADMIN_DONOR]);
};
