import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanySubcategory } from '../model/company-subcategory';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompanySubcategoryService {
    private serviceUrl = `/${environment.API_REF}/company_categories`;

    constructor(private http: HttpClient) {}

    public filterSubcategories(query: string, categoryId: number): Observable<CompanySubcategory[]> {
        return this.http.get<CompanySubcategory[]>(`${this.serviceUrl}/${categoryId}/autocomplete?name=${query}`);
    }
    public getSubcategoriesByCategory(id: number): Observable<CompanySubcategory[]> {
        return this.http.get<CompanySubcategory[]>(`${this.serviceUrl}/${id}`);
    }
}
