import { NgModule } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { AppConfigModule } from './config/app.config.module';
import { AppLayoutComponent } from './app.layout.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppProfileSidebarComponent } from './app.profilesidebar.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { TranslateModule } from '@ngx-translate/core';
import { MenubarModule } from 'primeng/menubar';
import { ChipModule } from 'primeng/chip';
import { FooterModule } from '../footer/footer.module';
import { MailOutModule } from '../mail-out/mail-out.module';
import { FooterComponent } from '../footer/footer.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SharedModule } from '../components/generics/shared.module';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ArticleService } from '../services/article.service';
import { AuthorService } from '../components/author/author.service';
import { FileService } from '../support/file.service';
import { MunicipalityService } from '../services/municipality.service';
import { CountryService } from '../services/country.service';
import { CompanyCategoryService } from '../services/company-category.service';
import { TransactionService } from '../services/transaction.service';
import {CookieConsentComponent} from '../cookie/cookie-consent.component';
import {LocalStorageService} from '../services/local-storage.service';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
    declarations: [
        AppLayoutComponent,
        CookieConsentComponent,
        AppBreadcrumbComponent,
        AppSidebarComponent,
        AppTopbarComponent,
        AppProfileSidebarComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        FooterComponent,
    ],
    imports: [
        CheckboxModule,
        DialogModule,
        DropdownModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        StyleClassModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        TooltipModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        ButtonModule,
        TranslateModule,
        MenubarModule,
        ChipModule,
        FormsModule,
        FooterModule,
        MailOutModule,
        SplitButtonModule,
        SharedModule,
    ],
    providers: [ConfirmationService, TransactionService, LocalStorageService],
})
export class AppLayoutModule {}
