import { LazyLoadEvent } from 'primeng/api';
import { Wallet } from '../model/wallet';

export class PageResponse<E> {
    constructor(
        public totalPages: number,
        public totalElements: number,
        public content: E[],

        public totalDonations: number,
        public sumDonations: number,

        public totalRealized: number,
        public sumRealized: number,

        public totalAvailable: number,
        public sumAvailable: number,

        public wallet: Wallet,

        public totalReserved: number,
        public sumReserved: number,

        public totalTopUp: number,
        public sumTopUp: number,

        public totalPaidOut: number,
        public sumPaidOut: number,

        public totalCanceled: number,
        public sumCanceled: number
    ) {}
}

export class PageRequestByExample<E> {
    constructor(public example: E, public lazyLoadEvent: LazyLoadEvent) {}
}
