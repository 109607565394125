import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {errorHandler} from "../../../../support/functions";
import {UserService} from "../../../../services/user.service";
import {Beneficiary} from "../../../../model/beneficiary";
import {Role} from "../../../../enums/role";
import {beneficiarySteps} from "./beneficiary-steps";

@Component({
    templateUrl: './beneficiary-register-step4.component.html',
    styles: [`body {
        background: var(--surface-ground);
    }`],
    encapsulation: ViewEncapsulation.None
})
export class BeneficiaryRegisterStep4Component implements OnInit {
    public form: UntypedFormGroup;
    items: MenuItem[];
    private readonly beneficiary: Beneficiary;

    constructor(public layoutService: LayoutService, private router: Router, private userService: UserService,
                private msgService: MessageService, private translate: TranslateService) {
        this.items = beneficiarySteps(this.translate);
        this.beneficiary = this.router.getCurrentNavigation().extras?.state?.beneficiary;

        this.form = new UntypedFormGroup({
                email: new UntypedFormControl({value: this.beneficiary?.company?.contactPerson?.email, disabled: true}),
                phoneNumber: new UntypedFormControl({value: this.beneficiary?.company?.contactPerson?.phone, disabled: true}),
                password: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.minLength(8)])),
                passwordRetype: new UntypedFormControl(null, Validators.required),
                beneficiaryId: new UntypedFormControl(this.beneficiary?.id),
                role: new UntypedFormControl(Role.BENEFICIARY),
        });
    }

    ngOnInit(): void {
        if (!this.beneficiary) {
            this.router.navigate(['/'])
        }
    }

    submit() {
        this.form.controls.beneficiaryId.setValue(this.beneficiary?.id);
        firstValueFrom(this.userService.signUp(this.form.getRawValue()))
            .then(token => {
                    this.router.navigateByUrl(`auth/register/beneficiary/step5`, {state: {beneficiary: this.beneficiary}});
                },
                error => errorHandler(this.translate.instant('authentication.registrationFailed'), error, this.msgService, this.translate, this.router)
            );
    }
}
