import { Component, Inject } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { confirmAction, errorHandler } from '../../support/functions';
import { LocationStrategy } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { MunicipalityService } from '../../services/municipality.service';
import { CountryService } from '../../services/country.service';
import { Municipality } from '../../model/municipality';
import { Country } from '../../model/country';
import { Router } from '@angular/router';
import { DocumentService } from 'src/app/services/document.service';

@Component({
    template: ``,
    selector: 'app-generic-detail',
})
export class GenericDetailComponent {
    paramsId: any;
    saveButtonLoading = false;
    today = new Date();
    public municipalitySuggestions: Municipality[];
    public countrySuggestions: Country[];

    constructor(
        protected confirmationService: ConfirmationService,
        protected translate: TranslateService,
        protected msgService: MessageService,
        protected location: LocationStrategy,
        @Inject(String) protected apiService: any,
        protected router?: Router,
        protected municipalityService?: MunicipalityService,
        protected countryService?: CountryService
    ) {}

    successConfirmation(): void {
        this.confirmationService.confirm({
            message: this.translate.instant('msg.successfullySaved'),
            rejectVisible: false,
            closeOnEscape: false,
            acceptLabel: 'OK',
            icon: 'pi pi-info-circle',
        });
    }

    processValue(data: any, form: FormGroup) {
        form.patchValue(data);
        this.successConfirmation();
        this.saveButtonLoading = false;
    }

    async confirmClose() {
        const confirm = await confirmAction(
            this.confirmationService,
            this.translate
        );
        if (confirm) {
            this.location.back();
        }
    }

    handleError(error: any) {
        this.msgService.add({
            life: 7000,
            severity: 'error',
            summary: this.translate.instant('error.error'),
            detail: this.translate.instant('error.' + error.error?.message),
        });
        this.saveButtonLoading = false;
    }

    submit(form: FormGroup): void {
        this.saveButtonLoading = true;

        if (!this.paramsId || this.paramsId === 'new') {
            firstValueFrom(this.apiService.create(form.getRawValue())).then(
                (data) => {
                    this.processValue(data, form);
                    this.paramsId = form.controls.id.value;
                },
                (error) => this.handleError(error)
            );
        } else {
            // console.log(JSON.stringify(form.getRawValue()));
            firstValueFrom(this.apiService.update(form.getRawValue())).then(
                (data) => this.processValue(data, form),
                (error) => this.handleError(error)
            );
        }
    }

    completeMunicipality(event: any) {
        firstValueFrom(this.municipalityService.filter(event.query)).then(
            (results) => (this.municipalitySuggestions = results),
            (error) =>
                errorHandler(
                    'Error during auto-complete',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                )
        );
    }

    completeCountry(event: any) {
        firstValueFrom(this.countryService.filter(event.query)).then(
            (results) => (this.countrySuggestions = results),
            (error) =>
                errorHandler(
                    'Error during auto-complete',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                )
        );
    }
}
