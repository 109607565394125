
<div class="surface-0" style="top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="form" (ngSubmit)="submit()">

        <div class="surface-0 pt-4 pl-4 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-0">
                <img [src]="'assets/opop/logo.png'" alt='' class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div class="surface-0" style="margin: 0 5% 0 5%;">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>

        <div class="flex align-items-center justify-content-between flex-column">
            <div class="flex flex-column w-full
                 md:w-7 sm:w-full h-full py-6 px-0 align-items-center justify-content-center">

                <ng-container formGroupName="company">
                    <div class="p-inputgroup mt-2 mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-tag mr-2" ></i>
                            <label for="companyName">{{'entity.company.companyName' | translate}}</label>
                        </span>
                        <input id="companyName" formControlName="companyName" pInputText type="text" autocomplete="off"/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-tag mr-2"></i>
                            <label for="companyVat">{{'entity.company.companyVat' | translate}}</label>
                        </span>
                        <input id="companyVat" formControlName="companyVat" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-tag mr-2"></i>
                            <label for="companyRegNr">{{'entity.company.companyRegNr' | translate}}</label>
                        </span>
                        <input id="companyRegNr" formControlName="companyRegNr" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start " style="height: 37px;">
                            <i class="pi pi-calendar mr-2"></i>
                            <label for="companyRegistrationDate">{{'entity.company.companyRegistrationDate' | translate}}</label>
                        </span>
                        <p-calendar formControlName="companyRegistrationDate" [showIcon]="true"
                                    id="companyRegistrationDate"
                                    dateFormat="dd.mm.yy"
                                    [inputStyle]="{'border-top-left-radius':'0','border-bottom-left-radius':'0'}"
                                    [style]="{'width': '100%'}">
                        </p-calendar>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-map-marker mr-2"></i>
                            <label for="companyStreetAddress">{{'entity.company.companyStreetAddress' | translate}}</label>
                        </span>
                        <input id="companyStreetAddress" formControlName="companyStreetAddress" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-building mr-2"></i>
                            <label for="companyCity">{{'entity.company.companyCity' | translate}}</label>
                        </span>
                        <input id="companyCity" formControlName="companyCity" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-tag mr-2"></i>
                            <label for="companyZip">{{'entity.company.companyZip' | translate}}</label>
                        </span>
                        <input id="companyZip" formControlName="companyZip" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start" style="height: 30px;">
                            <i class="pi pi-shield mr-2"></i>
                            <label for="companyMunicipality">{{'entity.company.companyMunicipality' | translate}}</label>
                        </span>
                        <p-autoComplete formControlName="companyMunicipality"
                                        id="companyMunicipality"
                                        [inputStyle]="{'border-radius':'0'}"
                                        [style]="{'width': '100%','height':'30px'}"
                                        field="name" class="p-fluid"
                                        [appendTo]="'body'" [suggestions]="municipalitySuggestions"
                                        (completeMethod)="completeMunicipality($event)"
                                        [dropdown]="true" [forceSelection]="true">
                        </p-autoComplete>
                        <span class="red-star-icon">&#9733;</span>
                    </div>




<!--                    <div class="p-inputgroup mb-2" style="width:70%;">-->
<!--                        <span class="p-inputgroup-addon w-15rem justify-content-start " style="height: 30px;">-->
<!--                            <i class="pi pi-slack mr-2"></i>-->
<!--                            <label for="companyCategory">{{'entity.company.companyCategory' | translate}}</label>-->
<!--                        </span>-->
<!--                        <p-autoComplete formControlName="companyCategory"-->
<!--                                        id="companyCategory"-->
<!--                                        [inputStyle]="{'border-radius':'0'}"-->
<!--                                        [style]="{'width': '100%','height':'30px'}"-->
<!--                                        field="name" class="p-fluid"-->
<!--                                        [appendTo]="'body'" [suggestions]="companyCategorySuggestions"-->
<!--                                        (completeMethod)="completeCompanyCategory($event)"-->
<!--                                        [dropdown]="true" [forceSelection]="true">-->
<!--                        </p-autoComplete>-->
<!--                        <span class="red-star-icon">&#9733;</span>-->
<!--                    </div>-->

<!--                    <div class="p-inputgroup mb-2" style="width:70%;">-->
<!--                        <span class="p-inputgroup-addon w-15rem justify-content-start " style="height: 30px;">-->
<!--                            <i class="pi pi-slack mr-2"></i>-->
<!--                            <label for="companySubcategory">{{'entity.company.companySubcategory' | translate}}</label>-->
<!--                        </span>-->
<!--                        <p-autoComplete formControlName="companySubcategory"-->
<!--                                        id="companySubcategory"-->
<!--                                        [inputStyle]="{'border-radius':'0'}"-->
<!--                                        [style]="{'width': '100%','height':'30px'}"-->
<!--                                        field="name"-->
<!--                                        [appendTo]="'body'"-->
<!--                                        [suggestions]="companySubcategorySuggestions"-->
<!--                                        (completeMethod)="completeSubcategoryMethod($event)"-->
<!--                                        [dropdown]="true"-->
<!--                                        [forceSelection]="true">-->
<!--                        </p-autoComplete>-->
<!--                        <span class="red-star-icon">&#9733;</span>-->
<!--                    </div>-->

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-phone mr-2"></i>
                            <label for="companyPhone">{{'entity.company.companyPhone' | translate}}</label>
                        </span>
                        <input id="companyPhone" formControlName="companyPhone" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-at mr-2"></i>
                            <label for="companyInfoMail">{{'entity.company.companyInfoMail' | translate}}</label>
                        </span>
                        <input id="companyInfoMail" formControlName="companyInfoMail" type="text" pInputText/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mb-2" style="width:70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start">
                            <i class="pi pi-globe mr-2"></i>
                            <label for="companyWww">{{'entity.company.companyWww' | translate}}</label>
                        </span>
                        <input id="companyWww" formControlName="companyWww" type="text" pInputText/>
                        <span class="white-star-icon">&#9733;</span>
                    </div>
                </ng-container>

                <div class="p-inputgroup mt-0 mb-2" style="width: 70%;">
                        <span class="p-inputgroup-addon w-15rem justify-content-start " style="height: 30px;" >
                                <i class="pi pi-shield mr-2"></i>
                                    <label for="beneficiaryCategory">{{'entity.company.companyCategory' | translate}}</label>
                            </span>
                    <p-autoComplete  formControlName="selectedBeneficiaryCategory"
                                     (onSelect)="onBeneficiaryCategorySelect($event)"
                                     id="beneficiaryCategory"
                                     [inputStyle]="{'border-radius':'0'}"
                                     [style]="{'width': '100%', 'height':'30px'}"
                                     field="name" class="p-fluid"
                                     [appendTo]="'body'" [suggestions]="beneficiaryCategorySuggestions"
                                     (completeMethod)="completeBeneficiaryCategory($event)"
                                     [dropdown]="true" [forceSelection]="true">
                    </p-autoComplete>
                    <span class="red-star-icon">&#9733;</span>
                </div>

                <div class="mb-1 mt-1" style="width:70%;">
                            <p style="margin-top: 0; margin-bottom: 0">
                                {{'footer.presentTermsAndCondiditions_1' | translate}}&nbsp;
                                <strong><u class="cursor-pointer" (click)="showTerms()">
                                    {{'footer.presentTermsAndCondiditions_2' | translate}}</u></strong>&nbsp;
                                {{'footer.presentTermsAndCondiditions_3' | translate}}
                            </p>
                </div>


                <button type="submit" pButton pRipple
                        label="{{'btn.next' | translate}}"
                        class="mt-2 mb-2"
                        icon="pi pi-arrow-right"
                        [disabled]="form.invalid"
                        style="width:70%; color: white;"></button>
            </div>
        </div>
    </form>
</div>
<app-config [minimal]="true"></app-config>
