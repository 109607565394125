import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";
import {CompanyCategory} from "../model/company-category";
import {environment} from "../../environments/environment";

@Injectable()
export class CompanyCategoryService extends GenericApiService<CompanyCategory> {
    override serviceUrl = `/${environment.API_REF}/company_categories`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public filter(query: string): Observable<CompanyCategory[]> {
        return this.http.get<CompanyCategory[]>(`${this.serviceUrl}/autocomplete?name=${query}`);
    }
}
