import {Component} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {firstValueFrom} from "rxjs";
import {UserService} from "../../../../services/user.service";
import {donorIndividualSteps} from "./donor-individual-steps";
import {Donor} from "../../../../model/donor";
import {DonorType} from "../../../../enums/donor-type";
import {donorOrganizationSteps} from "./donor-organization-steps";

@Component({
    templateUrl: './donor-register-step2a.component.html'
})
export class DonorRegisterStep2aComponent {
    items: MenuItem[];
    public contactPersonForm: UntypedFormGroup;
    public signaturePersonForm: UntypedFormGroup;
    donor: Donor;
    emailExists: boolean;

    constructor(public layoutService: LayoutService, private router: Router, private userService: UserService,
                private translate: TranslateService) {
        this.donor = this.router.getCurrentNavigation().extras?.state?.donor;
        this.items = this.donor.donorType === DonorType.INDIVIDUAL ? donorIndividualSteps(this.translate) : donorOrganizationSteps(this.translate);

        this.contactPersonForm = new UntypedFormGroup({
            firstName: new UntypedFormControl(null, Validators.required),
            lastName: new UntypedFormControl(null, Validators.required),
            phone: new UntypedFormControl('389', Validators.required),
            email: new UntypedFormControl(null, Validators.required)
        });
        this.signaturePersonForm= new UntypedFormGroup({
            firstName: new UntypedFormControl(null, Validators.required),
            lastName: new UntypedFormControl(null, Validators.required),
            phone: new UntypedFormControl('389', Validators.required),
            email: new UntypedFormControl(null, Validators.required),
            inactive: new UntypedFormControl(false, Validators.required)
        });
    }

    submit() {
        this.donor.company.contactPerson = this.contactPersonForm.value;
        this.donor.company.signaturePerson = this.signaturePersonForm.getRawValue();
        this.router.navigateByUrl(`auth/register/donor/bank-details`, {state: {donor: this.donor, individual: true}});
    }

    onEmailInput() {
        if (!this.contactPersonForm.controls.email.invalid) {
            firstValueFrom(this.userService.checkEmail(this.contactPersonForm.controls.email.value))
                .then(exists => this.emailExists = exists);
        } else {
            this.emailExists = false;
        }
    }
}
