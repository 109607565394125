import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DonorRegisterIndividualComponent} from './donor/donor-register-individual.component';
import {DonorRegisterStep2Component} from "./donor/donor-register-step2.component";
import {DonorRegisterStep3Component} from "./donor/donor-register-step3.component";
import {BeneficiaryRegisterComponent} from "./beneficiary/beneficiary-register.component";
import {BeneficiaryRegisterStep3Component} from "./beneficiary/beneficiary-register-step3.component";
import {BeneficiaryRegisterStep4Component} from "./beneficiary/beneficiary-register-step4.component";
import {AdministratorRegisterComponent} from "./administrator/administrator-register.component";
import {BeneficiaryRegisterStep5Component} from "./beneficiary/beneficiary-register-step5.component";
import {BeneficiaryRegisterStep6Component} from "./beneficiary/beneficiary-register-step6.component";
import {BeneficiaryRegisterStep2Component} from "./beneficiary/beneficiary-register-step2.component";
import {DonorRegisterStep1Component} from "./donor/donor-register-step1.component";
import {DonorRegisterOrganizationComponent} from "./donor/donor-register-organization.component";
import {DonorRegisterOrganizationBankDetailsComponent} from "./donor/donor-organization-bank-details.component";
import {BeneficiaryRegisterStep2aComponent} from "./beneficiary/beneficiary-register-step2a.component";
import {DonorRegisterStep2aComponent} from "./donor/donor-register-step2a.component";
import {DonorRegisterStep4Component} from "./donor/donor-register-step4.component";
import {TermsAndConditionsComponent } from '../../../footer/terms-conditions.component'; // Adjust the path as needed

@NgModule({
    imports: [RouterModule.forChild([
        {path: 'donor/step1', component: DonorRegisterStep1Component},
        {path: 'donor/individual', component:  DonorRegisterIndividualComponent},
        {path: 'donor/organization', component: DonorRegisterOrganizationComponent},
        {path: 'donor/bank-details', component: DonorRegisterOrganizationBankDetailsComponent},
        {path: 'donor/step2a', component: DonorRegisterStep2aComponent},
        {path: 'donor/step2', component: DonorRegisterStep2Component},
        {path: 'donor/step3', component: DonorRegisterStep3Component},
        {path: 'donor/step4', component: DonorRegisterStep4Component},
        {path: 'beneficiary/step1', component: BeneficiaryRegisterComponent},
        {path: 'beneficiary/step2', component: BeneficiaryRegisterStep2Component},
        {path: 'beneficiary/step2a', component: BeneficiaryRegisterStep2aComponent},
        {path: 'beneficiary/step3', component: BeneficiaryRegisterStep3Component},
        {path: 'beneficiary/step4', component: BeneficiaryRegisterStep4Component},
        {path: 'beneficiary/step5', component: BeneficiaryRegisterStep5Component},
        {path: 'beneficiary/step6', component: BeneficiaryRegisterStep6Component},
        {path: 'administrator', component: AdministratorRegisterComponent},
    ])],
    exports: [RouterModule]
})
export class RegisterRoutingModule {
}
