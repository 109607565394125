import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";


@NgModule({
    imports: [CommonModule,ButtonModule,InputTextModule],
    exports: []
})

export class FooterModule {
}
