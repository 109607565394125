import {TranslateService} from "@ngx-translate/core";

export enum DonorType {
    INDIVIDUAL = "INDIVIDUAL",
    ORGANIZATION = "ORGANIZATION",
    ONE_TIME_DONOR = "ONE_TIME_DONOR"
}

export function donorType_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.DonorType.INDIVIDUAL'), value: DonorType.INDIVIDUAL},
        {label: translate.instant('enum.DonorType.ORGANIZATION'), value: DonorType.ORGANIZATION},
        {label: translate.instant('enum.DonorType.ONE_TIME_DONOR'), value: DonorType.ONE_TIME_DONOR}
    ];
}

export function donorTypeRegister_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.DonorType.INDIVIDUAL'), value: DonorType.INDIVIDUAL},
        {label: translate.instant('enum.DonorType.ORGANIZATION'), value: DonorType.ORGANIZATION}
    ];
}
