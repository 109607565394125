import {TranslateService} from "@ngx-translate/core";
import {DonorType} from "../../../../enums/donor-type";

export function donorOrganizationSteps(translate: TranslateService) {
    return [
        {
            label: translate.instant('entity.donor.steps.donorType'),
            routerLink: '/auth/register/donor/step1'
        },
        {
            label: translate.instant('entity.donor.steps.organizationDetails'),
            routerLink: '/auth/register/donor/organization'
        },
        {
            label: translate.instant('entity.donor.steps.contactPerson'),
            routerLink: '/auth/register/donor/step2a'
        },
        {
            label: translate.instant('entity.donor.steps.bankDetails'),
            routerLink: '/auth/register/donor/bank-details'
        },
        {
            label: translate.instant('entity.donor.steps.otpConfirmation'),
            routerLink: '/auth/register/donor/step2'
        },
        {
            label: translate.instant('entity.donor.steps.credentials'),
            routerLink: '/auth/register/donor/step3'
        }
    ];
}
