import {TranslateService} from "@ngx-translate/core";

export enum Role {
    ADMIN = "ADMIN",
    ADMIN_FINANCE = "ADMIN_FINANCE",
    ADMIN_PROJECT = "ADMIN_PROJECT",
    ADMIN_BENEFICIARY = "ADMIN_BENEFICIARY",
    ADMIN_DONOR = "ADMIN_DONOR",
    BENEFICIARY = "BENEFICIARY",
    DONOR = "DONOR"
}

export function role_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.Role.ADMIN'), value: Role.ADMIN},
        {label: translate.instant('enum.Role.ADMIN_FINANCE'), value: Role.ADMIN_FINANCE},
        {label: translate.instant('enum.Role.ADMIN_PROJECT'), value: Role.ADMIN_PROJECT},
        {label: translate.instant('enum.Role.ADMIN_BENEFICIARY'), value: Role.ADMIN_BENEFICIARY},
        {label: translate.instant('enum.Role.ADMIN_DONOR'), value: Role.ADMIN_DONOR},
        {label: translate.instant('enum.Role.BENEFICIARY'), value: Role.BENEFICIARY},
        {label: translate.instant('enum.Role.DONOR'), value: Role.DONOR}
    ];
}

export function isEffectiveDonor(role: Role): boolean {
    return role === Role.DONOR || role === Role.ADMIN_DONOR;
}
