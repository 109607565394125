import { APP_INITIALIZER, Injector, NgModule, OnInit } from '@angular/core';

import {
    HashLocationStrategy,
    NgClass,
    PathLocationStrategy,
} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
    TranslateStore,
} from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { appInitializerFactory } from './support/initializer-function';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './support/auth.service';
import { BasicAuthInterceptor } from './support/interceptors/auth.interceptors';
import { StorageService } from './support/storage.service';
import { LayoutService } from './layout/service/app.layout.service';
import { ChatAppModule } from './components/chat/chat.app.module';
import { DialogService } from 'primeng/dynamicdialog';
import { ImageDisplayComponent } from './components/image-display.component';
import { MailOutComponent } from './mail-out/mail-out.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { LanguageService } from './components/generics/language.service';
import { SharedModule } from './components/generics/shared.module';
import './enums/locale-mk';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import localeMk from '@angular/common/locales/mk';
import {LanguageInterceptor } from './components/generics/language.interceptor';
import {DialogModule } from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {TermsAndConditionsComponent } from './footer/terms-conditions.component';
import {RegisterModule } from './template/auth/register/register.module';


registerLocaleData(localeMk);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent, ImageDisplayComponent, MailOutComponent],
    imports: [
        RegisterModule,
        AppRoutingModule,
        DialogModule,
        DynamicDialogModule,
        AppLayoutModule,
        ChatAppModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ToastModule,
        NgClass,
        FormsModule,
        InputTextModule,
        ReactiveFormsModule,
        ButtonModule,
        SharedModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [
                TranslateService,
                Injector,
                AuthService,
                StorageService,
                LayoutService,
            ],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true,
        },
        // {provide: LOCALE_ID, useValue: 'mk' },
        { provide: PathLocationStrategy, useClass: HashLocationStrategy },
        TranslateService,
        TranslateStore,
        MessageService,
        AuthService,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        DialogService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule implements OnInit {
    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private router: Router
    ) {
        const savedLanguage = localStorage.getItem('language') || 'mk';
        this.translate.setDefaultLang(savedLanguage);
        const languageObj = {
            label: savedLanguage.toUpperCase(),
            code: savedLanguage,
            icon: `flag-icon-${savedLanguage}`,
        };
        this.languageService.setSelectedLanguage(languageObj);
    }
    ngOnInit() {
        this.router.events
            .pipe(
                // Filter out events that are not NavigationEnd events
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                // Scroll to the top of the page
                window.scrollTo(0, 0);
            });
    }
}
