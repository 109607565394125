import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LazyLoadEvent } from 'primeng/api';
import { GenericApiService } from '../components/generics/generic-api.service';
import { User } from '../model/user';
import { PageRequestByExample, PageResponse } from '../support/page';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService extends GenericApiService<any> {
    override serviceUrl = `/${environment.API_REF}/account`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public create(item: User): Observable<User> {
        const options = {
            headers: this.getHeaders(),
        };
        return this.http
            .post<User>(`${this.serviceUrl}/saveUser`, item, options)
            .pipe(map((res) => this.parse(res)));
    }

    public update(item: User): Observable<User> {
        return this.http
            .put<User>(`${this.serviceUrl}`, item)
            .pipe(map((res) => this.parse(res)));
    }

    public getPage(
        item: User,
        event: LazyLoadEvent
    ): Observable<PageResponse<User>> {
        const body = new PageRequestByExample(item, event);
        return this.http
            .post<PageResponse<User>>(`${this.serviceUrl}/getAllUsers`, body)
            .pipe(map((res) => this.parse(res)));
    }

    public getById(id: string): Observable<User> {
        return this.http
            .get<any>(`${this.serviceUrl}/${id}`)
            .pipe(map((res) => this.parse(res)));
    }

    public checkEmail(email: string): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.serviceUrl}/check_email/${email}`
        );
    }

    public archiveUser(id: string): Observable<User> {
        return this.http
            .delete<any>(`${this.serviceUrl}/${id}`)
            .pipe(map((res) => this.parse(res)));
    }

    public signUp(userSignUp: any): Observable<User> {
        return this.http
            .post<any>(`${this.serviceUrl}/sign_up`, userSignUp)
            .pipe(map((res) => this.parse(res)));
    }

    public signUpAndLogin(userSignUp: any): Observable<string> {
        const options = {
            headers: new HttpHeaders(),
            params: new HttpParams(),
            responseType: 'text',
        } as any;

        return this.http
            .post<string>(
                `${this.serviceUrl}/sign_up_donor_and_login`,
                userSignUp,
                options
            )
            .pipe(map((res) => this.parse(res)));
    }

    public updateEmailPin(userId: string): Observable<void> {
        return this.http
            .post<void>(
                `${this.serviceUrl}/update-email-pin?userId=${userId}`,
                {}
            )
            .pipe(map((res) => this.parse(res)));
    }

    public updateSmsPin(userId: string): Observable<void> {
        return this.http
            .post<void>(
                `${this.serviceUrl}/update-phone-pin?userId=${userId}`,
                {}
            )
            .pipe(map((res) => this.parse(res)));
    }

    public updatePassword(newpassword: any): Observable<void> {
        const body = {
            email: newpassword.email,
            password: newpassword.password,
            repeatedPassword: newpassword.repeatedPassword,
        };
        return this.http
            .put(`${this.serviceUrl}/update_password`, body)
            .pipe(map((res) => this.parse(res)));
    }
}
