import {Person, PersonUpdate, toPersonUpdate} from "./person";
import {Company, CompanyUpdate, toCompanyUpdate} from "./company";
import {DonorType} from "../enums/donor-type";
import {Wallet} from "./wallet";
import {User} from "./user";
import {DonorStatus} from "../enums/donor-status";
import {Document} from "./document";

export interface Donor {
    id: string;
    donorType: DonorType;
    anonymous: boolean;
    donorCategory: { id: number; };
    donorStatus: DonorStatus;
    yearlyDonation: number;
    availableFunds: number;
    totalFunds: number;
    totalFundsReserved: number;
    totalFundsDonated: number;
    totalFundsAccomplished: number;
    company: Company;
    person: Person;
    user: User;
    wallet: Wallet;
    uniqueId:string;
    documents: Document[];
}

export interface DonorCreate {
    donorType: DonorType;
    donorCategory:  { id: number; };
    company: CompanyUpdate;
    person: PersonUpdate;
    documentIds: string[];
    anonymous: boolean;
}

export interface DonorUpdate {
    id: string;
    donorType: DonorType;
    donorCategory:  { id: number; };
    company: CompanyUpdate;
    person: PersonUpdate;
    documentIds: string[];
    anonymous: boolean;
}

export interface TopUp {
    amount: number;
}

export function toDonorCreate(donor: Donor) {

    const companyUpdate = donor?.company ? toCompanyUpdate(donor?.company) : null
    const personUpdate = donor?.person ? toPersonUpdate(donor?.person) : null

    return {
        donorType: donor.donorType,
        donorCategory: { id: donor.donorCategory?.id },
        company: companyUpdate,
        person: personUpdate,
        documentIds: donor.documents?.map(doc => doc.id),
        anonymous: donor.anonymous
    } as DonorCreate
}



export function toDonorUpdate(donor: Donor) {
    const companyUpdate= donor?.company ? toCompanyUpdate(donor?.company) : null
    const personUpdate   = donor?.person ? toPersonUpdate(donor?.person) : null

    console.log (donor.anonymous);
    return {
        id: donor.id,
        donorType: donor.donorType,
        donorCategory: { id: donor.donorCategory?.id },
        company: companyUpdate,
        person: personUpdate,
        documentIds: donor.documents?.map(doc => doc.id),
        anonymous: donor.anonymous
    } as DonorUpdate
}
