import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Role } from '../enums/role';
import { StorageService } from './storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { GenericApiService } from '../components/generics/generic-api.service';
import { User } from '../model/user';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService extends GenericApiService<any> {
    constructor(
        protected override http: HttpClient,
        private router: Router,
        private storageService: StorageService,
        private jwtHelperService: JwtHelperService
    ) {
        super(http);
    }

    get isAuthenticated(): boolean {
        const token = localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
        return token && !this.jwtHelperService.isTokenExpired(token);
    }

    login(authDetails: any): Observable<string> {
        const options = {
            headers: new HttpHeaders(),
            params: new HttpParams(),
            responseType: 'text',
        } as any;
        const body = {
            email: authDetails.username,
            password: authDetails.password,
        };
        return this.http
            .post(`/${environment.API_REF}/account/login`, body, options)
            .pipe(map((res) => this.parse(res)));
    }

    fetchUserDetails(): Observable<User> {
        return this.http
            .post(`/${environment.API_REF}/account/me`, {})
            .pipe(map((res) => this.parse(res)));
    }

    get userDetails(): User {
        const token = localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
        if (!token || this.jwtHelperService.isTokenExpired(token)) {
            return null;
        }
        return this.storageService.getValue(environment.USER_DETAILS) ?? null;
    }

    getJwtToken() {
        return localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
    }

    forgotPassword(inputEmail: String): Observable<void> {
        const body = { email: inputEmail };
        console.log(body);
        return this.http
            .put(`/${environment.API_REF}/account/reset_password`, body)
            .pipe(map((res) => this.parse(res)));
    }

    logout() {
        localStorage.removeItem(environment.LOCAL_STORAGE_TOKEN);
        this.storageService.removeValue(environment.USER_DETAILS);
        this.router.navigate(['/']);
    }

    get loggedUserName(): string {
        if (this.isAuthenticated) {
            const userDetails = this.userDetails;
            if (userDetails && userDetails.role === Role.DONOR) {
                if (userDetails.donor.person) {
                    return userDetails.donor?.person?.firstName;
                } else {
                    return userDetails.donor?.company?.companyName;
                }
            } else if (
                userDetails &&
                (userDetails.role === Role.ADMIN_BENEFICIARY ||
                    userDetails.role === Role.BENEFICIARY)
            ) {
                return this.userDetails.beneficiary?.company?.companyName;
            } else if (userDetails && userDetails.role === Role.ADMIN) {
                return 'Administrator';
            }
        }
        return '';
    }

    get userRole(): Role {
        const token = localStorage.getItem(environment.LOCAL_STORAGE_TOKEN);
        if (!token || this.jwtHelperService.isTokenExpired(token)) {
            return null;
        }
        return this.userDetails?.role;
    }

    hasAnyAuthority(authorities: Role[] | Role): boolean {
        const userAuthority: Role = this.userRole;
        if (!userAuthority) {
            return false;
        }
        if (!Array.isArray(authorities)) {
            authorities = [authorities];
        }

        return authorities.includes(userAuthority);
    }

    get chatAdminRoles() {
        return [Role.ADMIN];
    }
}
