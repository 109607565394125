import {AuthService} from "../../support/auth.service";
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ChatMessage, ChatType} from "./chat-message";
import {Client, StompConfig} from '@stomp/stompjs';
import * as SockJS from "sockjs-client";
import {environment} from "../../../environments/environment";

@Injectable()
export class ChatService {
    private stompClient: Client;
    username: string;

    _availableTopics = [];
    private availableTopics = new BehaviorSubject<any>(this._availableTopics);
    availableTopics$ = this.availableTopics.asObservable();

    _msgsMap: Map<string, ChatMessage[]> = new Map();
    private msgsMap = new BehaviorSubject<Map<string, ChatMessage[]>>(this._msgsMap);
    msgsMap$ = this.msgsMap.asObservable();

    _isConnected = false;
    private isConnected = new BehaviorSubject<any>(false);
    isConnected$ = this.isConnected.asObservable();


    constructor(private authService: AuthService) {
        const stompConfig: StompConfig = {
            webSocketFactory: () => new SockJS(`/${environment.WS_REF}`),
            debug: (debug) => console.log(debug)
        };
        this.stompClient = new Client(stompConfig);

        this.username = this.authService.userDetails?.email ?? `Guest_${new Date().getTime()}`;
    }

    connect(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.stompClient.onConnect = (frame) => {
                this._isConnected = true;
                this.isConnected.next(this._isConnected);
                this.subscribeToAvailableTopics();
                resolve(frame);
            };
            this.stompClient.onStompError = (frame) => {
                reject(frame);
            };

            this.stompClient.onWebSocketClose = (closeEvent) => {
                this._isConnected = false;
                this.isConnected.next(this._isConnected);
            }

            this.stompClient.activate();
        });
    }

    disconnect(): Promise<void> {
        return this.stompClient.deactivate();
    }

    subscribeToTopic(topic: string): void {

        this.stompClient.subscribe(`/topic/${topic}`, (payload) => {
            const chatMessages = this._msgsMap.get(topic);
            let body = JSON.parse(payload.body);

            if (Array.isArray(body)) {
                const chatMsgs = [...body, chatMessages];
                this._msgsMap.set(topic, chatMsgs);
            } else {
                chatMessages.push(body);
                this._msgsMap.set(topic, chatMessages);
            }

            this.msgsMap.next(this._msgsMap);
        });
    }

    sendMessage(msg: ChatMessage) {

        this.stompClient.publish({destination: `/app/chat/sendMessage/${msg.topic}`, body: JSON.stringify(msg)});
    }

    addUser(msg: ChatMessage) {
        this.stompClient.publish({destination: `/app/chat/addUser/${msg.topic}`, body: JSON.stringify(msg)});
    }

    subscribeToAvailableTopics() {

        this.stompClient.subscribe(`/topic/topics`, (payload: any) => {
            const message = JSON.parse(payload.body);

            if (message.content !== '') {
                this._availableTopics = message.content.split(',');
                this.availableTopics.next(this._availableTopics);

                this._availableTopics.forEach(availableTopic => {
                    if (!this._msgsMap.has(availableTopic)) {
                        this._msgsMap.set(availableTopic, [])
                    }
                });
            }
        });

        this.stompClient.publish({destination: `/app/chat/topics`, body: JSON.stringify({type: ChatType.TOPICS})});
    }

    getHistory(topic: string) {

        this.stompClient.publish({destination: `/app/chat/history/${topic}`, body: JSON.stringify({})});
    }
}
