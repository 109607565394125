<div class="surface-0" style="top: 0; left: 0;
    width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="surface-0 pt-4 pl-6 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-2">
                <img [src]="'assets/opop/logo.png'" class="login-logo"
                     style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div class="surface-0" style="margin-left: 15%; margin-right: 15%; margin-top:0%; margin-bottom:0%;">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>

        <div class="surface-0">
            <div class="flex align-items-center justify-content-between flex-column">
                <div
                    class="flex flex-column w-full
                    md:w-8 sm:w-full h-full py-6 px-0 align-items-center justify-content-center"
                    style="border:0 solid red;">

                    <ng-container formGroupName="company">
                        <div class="p-inputgroup mt-2 mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="companyName">{{'entity.company.companyName' | translate}}</label>
                            </span>
                            <input id="companyName" formControlName="companyName" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="companyVat">{{'entity.company.companyVat' | translate}}</label>
                            </span>
                            <input id="companyVat" formControlName="companyVat" type="text" pInputText/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="companyRegNr">{{'entity.company.companyRegNr' | translate}}</label>
                            </span>
                            <input id="companyRegNr" formControlName="companyRegNr" type="text" pInputText/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 37px;">
                                <i class="pi pi-calendar mr-2"></i>
                            <label for="companyRegistrationDate">{{'entity.company.companyRegistrationDate' | translate}}</label>
                            </span>
                            <p-calendar formControlName="companyRegistrationDate" [showIcon]="true"
                                        id="companyRegistrationDate"
                                        dateFormat="dd.mm.yy"
                                        [inputStyle]="{'border-top-left-radius':'0','border-bottom-left-radius':'0'}"
                                        [style]="{'width': '100%'}">
                            </p-calendar>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-map-marker mr-2"></i>
                                <label
                                    for="companyStreetAddress">{{'entity.company.companyStreetAddress' | translate}}</label>
                            </span>
                            <input id="companyStreetAddress" formControlName="companyStreetAddress" type="text" pInputText/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-map-marker mr-2"></i>
                                <label for="city">{{'entity.company.companyCity' | translate}}</label>
                            </span>
                            <input id="city" formControlName="companyCity" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="companyZip">{{'entity.company.companyZip' | translate}}</label>
                            </span>
                            <input id="companyZip" formControlName="companyZip" type="text" pInputText/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 30px;">
                                <i class="pi pi-shield mr-2"></i>
                                    <label for="companyMunicipality">{{'entity.company.companyMunicipality' | translate}}</label>
                            </span>
                            <p-autoComplete formControlName="companyMunicipality"
                                            id="companyMunicipality"
                                            [inputStyle]="{'border-radius':'0'}"
                                            [style]="{'width': '100%', 'height':'30px'}"
                                            field="name" class="p-fluid"
                                            [appendTo]="'body'" [suggestions]="municipalitySuggestions"
                                            (completeMethod)="completeMunicipality($event)"
                                            [dropdown]="true" [forceSelection]="true">
                            </p-autoComplete>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 30px;">
                                <i class="pi pi-shield mr-2"></i>
                                    <label for="companyCountry">{{'entity.company.companyCountry' | translate}}</label>
                            </span>
                            <p-autoComplete formControlName="companyCountry"
                                            id="companyCountry"
                                            [inputStyle]="{'border-radius':'0'}"
                                            [style]="{'width': '100%', 'height':'30px'}"
                                            field="name" class="p-fluid"
                                            [appendTo]="'body'" [suggestions]="countrySuggestions"
                                            (completeMethod)="completeCountry($event)"
                                            [dropdown]="true" [forceSelection]="true">
                            </p-autoComplete>
                            <span class="red-star-icon">&#9733;</span>
                        </div>


                        <div class="p-inputgroup mb-2" style="width: 80%;">
                             <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 30px;">
                                <i class="pi pi-slack mr-2"></i>
                                 <label for="donorCategory">{{'entity.company.companyCategory' | translate}}</label>
                             </span>

                            <p-dropdown
                                [options]="categories"
                                [style]="{'width':'100%','border-top-left-radius':'0','border-bottom-left-radius':'0', 'height':'30px'}"
                                formControlName="donorCategory"
                                optionLabel="name"
                                optionValue="id"
                                (onChange)="onCategoryChange($event)">
                            </p-dropdown>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-phone mr-2"></i>
                                <label for="companyPhone">{{'entity.company.companyPhone' | translate}}</label>
                            </span>
                            <input id="companyPhone" formControlName="companyPhone" type="text" pInputText/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-at mr-2"></i>
                                <label for="companyInfoMail">{{'entity.company.companyInfoMail' | translate}}</label>
                            </span>
                            <input id="companyInfoMail" formControlName="companyInfoMail" type="text" pInputText/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-globe mr-2"></i>
                                <label for="companyWww">{{'entity.company.companyWww' | translate}}</label>
                            </span>
                            <input id="companyWww" formControlName="companyWww" type="text" pInputText/>
                            <span class="white-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mb-2" style="width: 80%;">
                            <span class="p-inputgroup-addon w-21rem justify-content-start " style="height: 37px;">
                                <i class="pi pi-user mr-2"></i>
                                    <label for="userAnonymous">{{'entity.person.anonymous' | translate}}</label>
                            </span>

                            <form [formGroup]="form">
                                <div style="display: flex; align-items: start;">
                                    <p-checkbox
                                        id="userAnonymous"
                                        [style]="{'width': '100%','margin-left':'10px', 'margin-top':'10px'}"
                                        formControlName="anonymous"
                                        binary="true">
                                    </p-checkbox>
                                    <label for="userAnonymous"
                                           style=" color:darkgrey;margin-left:10px; height:37px; margin-top: 4px;">
                                        {{'entity.person.anonymousDesc' | translate}}
                                    </label>
                                </div>
                            </form>
                        </div>

                    </ng-container>
                    <div
                        class="flex flex-column w-full md:w-6 sm:w-full h-full py-2 px-0 align-items-center justify-content-center ">
                        <button type="submit" pButton pRipple label="{{'btn.next' | translate}}" class="w-full mb-2"
                                icon="pi pi-arrow-right" [disabled]="form.invalid"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<app-config [minimal]="true"></app-config>
