import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {OpopDocumentUploadComponent} from "../../../../components/generics/opop-document-upload.component";

import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {Beneficiary} from "../../../../model/beneficiary";
import {DocumentService} from "../../../../services/document.service";
import {beneficiarySteps} from "./beneficiary-steps";
import {DocumentType} from "../../../../enums/document-type";
import {HttpClient} from '@angular/common/http';
import {FileUpload, FileUploadModule} from "primeng/fileupload/fileupload";


@Component({
    templateUrl: './beneficiary-register-step6.component.html',
    styles: [`body {
        background: var(--surface-ground);
    }`, `
      .p-fileupload-choose {
        display: none !important;
      }

      .p-fileupload .p-fileupload-buttonbar {
        border: 0;
      }

      .p-fileupload .p-fileupload-content {
        -moz-border-radius: 8px;
        -webkit-border-radius: 8px;
        padding: 0
      }
    `],
    encapsulation: ViewEncapsulation.None
})
export class BeneficiaryRegisterStep6Component implements OnInit {
    params: any;
    translatedTitle: string;
    public form: UntypedFormGroup;
    protected readonly DocumentType = DocumentType;
    items: MenuItem[];
    public readonly beneficiary: Beneficiary;
    @ViewChild('fileUploader') fileUpload: FileUpload;
    @ViewChild(OpopDocumentUploadComponent) documentUploadComponent: OpopDocumentUploadComponent;
    generatedDocument: Blob;


    constructor(public layoutService: LayoutService,
                private router: Router,
                private translate: TranslateService,
                private confirmationService: ConfirmationService,
                private http: HttpClient,
                public documentService: DocumentService) {
        this.items = beneficiarySteps(this.translate);
        this.beneficiary = this.router.getCurrentNavigation().extras?.state?.beneficiary;

        this.form = new UntypedFormGroup({
            documentIds: new UntypedFormControl([]),
            documents: new UntypedFormControl([])
        });
    }

    printFormContents() {
    }

    getTodayDate(): string {
        const date = new Date();
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    getCompanyName(): string { return this.beneficiary.company.companyName; }
    getCompanyVat(): string { return this.beneficiary.company.companyVat; }
    getCompanyRegNr(): string { return this.beneficiary.company.companyRegNr; }
    getCompanyFullAddress(): string {
        return `${this.beneficiary.company.companyStreetAddress} - ${this.beneficiary.company.companyCity} - ${this.beneficiary.company.companyZip}`;
    }
    getCompanyBankName(): string { return this.beneficiary.company.bank.bankName; }
    getCompanyAccountNumber(): string { return this.beneficiary.company.bank.accountNumber; }
    getCompanySignaturePerson(): string {
        return `${this.beneficiary.company.signaturePerson.firstName} ${this.beneficiary.company.signaturePerson.lastName}`;
    }


    onDocumentGenerated(document: Blob) {
        const currentLang = this.translate.currentLang; // Get the current language
        let docName = `DogovorPristapuvanje_${currentLang.toUpperCase()}.docx`;

        this.generatedDocument = new File([document], docName,
            { type: 'DocumentType.CONTRACT' });
        this.uploadDocument(this.generatedDocument);
    }

    uploadDocument(document: Blob) {
        const event = {
            files: [document],
            formData: new FormData()
        };
        const currentLang = this.translate.currentLang; // Get the current language
        let docName = `DogovorPristapuvanje_${currentLang.toUpperCase()}.docx`;

        event.formData.append('files', document, docName);

        // Use the reference to call the handleFileUpload method.
        this.documentUploadComponent.handleFileUpload(event);
    }

    ngOnInit(): void {
        this.translatedTitle = this.translate.instant('login.downloadAgreement');
        if (!this.beneficiary) {
            this.router.navigate(['/'])
        }

        const currentLang = this.translate.currentLang; // Get the current language
        let docNameFullPath = `assets/wordTemplates/DogovorPristapuvanje_${currentLang.toUpperCase()}.docx`;
        let docName = `DogovorPristapuvanje_${currentLang.toUpperCase()}.docx`;

        this.params = {
            templateDocumentPath: docNameFullPath,
            replacements: [
                { find: 'DD.MM.YYYY', replaceWith: this.getTodayDate()},
                { find: 'XYZ_naziv_na_pravno_lice_XYZ', replaceWith: this.getCompanyName()},
                { find: 'XYZ______adresa_na_sedishte______XYZ', replaceWith: this.getCompanyFullAddress()},
                { find: 'XYZ____ime_na_banka____XYZ', replaceWith: this.getCompanyBankName()},
                { find: 'XYZ________broj_____XYZ', replaceWith: this.getCompanyAccountNumber()},
                { find: 'XYZ_EMBbroj_XYZ', replaceWith: this.getCompanyRegNr()},
                { find: 'XYZ___EDBbroj___XYZ', replaceWith: this.getCompanyVat()},
                { find: 'XYZ_ime_prezime_praven_zastapnik_XYZ', replaceWith:this.getCompanySignaturePerson() },
                { find: 'XYZ_naziv_na_pravno_lice_XYZ', replaceWith:  this.getCompanyName()},
                { find: 'XYZ_ime_prezime_praven_zastapnik_XYZ', replaceWith:this.getCompanySignaturePerson() }
            ],
            outputDocumentName: docName,
            title: docName,
            emailTo: 'dino.gurcilov@gmail.com'
        };
    }


    submit() {
    }

    closeTheWindow() {
        if (this.form.controls.documents.value.length === 0) {
            this.confirmationService.confirm({
                message: this.translate.instant('msg.contractNotDownloaded'),
                rejectVisible: false,
                closeOnEscape: false,
                acceptLabel: 'OK',
                icon: 'pi pi-info-circle',
                accept: () => this.router.navigate(['/']),
                // reject: () => this.router.navigate(['/'])
            });
        }
        else if (this.form.controls.documents.value.length >= 1) {
            this.confirmationService.confirm({
                message: this.translate.instant('msg.contractCreatedAndDownloaded'),
                rejectVisible: false,
                closeOnEscape: false,
                acceptLabel: 'OK',
                icon: 'pi pi-info-circle',
                accept: () => this.router.navigate(['/']),
                // reject: () => this.router.navigate(['/'])
            });
        }
    }

}
