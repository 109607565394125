import {TranslateService} from "@ngx-translate/core";

export enum BeneficiaryStatus {
    NEW = "NEW",
    OTP_VERIFIED = "OTP_VERIFIED",
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    WAITING_FOR_COMPLETION = "WAITING_FOR_COMPLETION",
    TEMPORARY_DISABLED = "TEMPORARY_DISABLED",
    TERMINATED = "TERMINATED"
}

export function beneficiaryStatus_items(translate: TranslateService) {
    return [
        {label: translate.instant('enum.BeneficiaryStatus.NEW'), value: BeneficiaryStatus.NEW},
        {label: translate.instant('enum.BeneficiaryStatus.OTP_VERIFIED'), value: BeneficiaryStatus.OTP_VERIFIED},
        {label: translate.instant('enum.BeneficiaryStatus.PENDING'), value: BeneficiaryStatus.PENDING},
        {label: translate.instant('enum.BeneficiaryStatus.APPROVED'), value: BeneficiaryStatus.APPROVED},
        {label: translate.instant('enum.BeneficiaryStatus.REJECTED'), value: BeneficiaryStatus.REJECTED},
        {
            label: translate.instant('enum.BeneficiaryStatus.WAITING_FOR_COMPLETION'),
            value: BeneficiaryStatus.WAITING_FOR_COMPLETION
        },
        {
            label: translate.instant('enum.BeneficiaryStatus.TEMPORARY_DISABLED'),
            value: BeneficiaryStatus.TEMPORARY_DISABLED
        },
        {label: translate.instant('enum.BeneficiaryStatus.TERMINATED'), value: BeneficiaryStatus.TERMINATED}
    ];
}
