import {Component} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MenuItem, MessageService} from "primeng/api";
import {firstValueFrom} from "rxjs";
import {UserService} from "../../../../services/user.service";
import {DonorType} from "../../../../enums/donor-type";
import {DonorService} from "../../../../services/donor.service";
import {donorIndividualSteps} from "./donor-individual-steps";
import {companyRegisterForm} from "../company-form";
import {errorHandler} from "../../../../support/functions";
import {MunicipalityService} from "../../../../services/municipality.service";
import {CountryService} from "../../../../services/country.service";
// import {CompanyCategoryService} from "../../../../services/company-category.service";
import {Municipality} from "../../../../model/municipality";
import {Country} from "../../../../model/country";
import {donorOrganizationSteps} from "./donor-organization-steps";
import {staticDonorCategories} from "../../../../enums/donor-category"


@Component({
    templateUrl: './donor-register-organization.component.html'
})
export class DonorRegisterOrganizationComponent {
    items: MenuItem[];
    selectedCategory: any;
    public form: UntypedFormGroup;
    donorType: DonorType;
    municipalitySuggestions: Municipality[] = [];
    countrySuggestions: Country[] = [];
    categories = staticDonorCategories;
    constructor(public layoutService: LayoutService, private router: Router, private userService: UserService,
                private translate: TranslateService, private msgService: MessageService,
                private donorService: DonorService,
                public municipalityService: MunicipalityService,
                public countryService: CountryService,
    ) {
        this.donorType = this.router.getCurrentNavigation().extras?.state?.donorType;
        this.items = this.donorType === DonorType.INDIVIDUAL ? donorIndividualSteps(this.translate) : donorOrganizationSteps(this.translate);

        this.form = new UntypedFormGroup({
            donorType: new UntypedFormControl(this.donorType),
            donorCategory: new UntypedFormControl(null),
            company: companyRegisterForm(),
            anonymous: new UntypedFormControl(false)
        });
         // this.form.get('company.companyCategory').clearValidators();
         // this.form.get('company.companySubcategory').clearValidators();

        // Update value & validity of the controls to reflect the change
        // this.form.get('company.companyCategory').updateValueAndValidity();
        // this.form.get('company.companySubcategory').updateValueAndValidity();

    }
    ngOnInit() {
        //this.form.get('donorCategory').setValue({id: 8}); // Setting to Agriculture & Forestry
    }

    onCategoryChange(event) {
        const selectedId = event.value;
        this.form.get('donorCategory').setValue({id: selectedId});
    }
    submit() {
        this.router.navigateByUrl(`auth/register/donor/step2a`, {
            state: {donor: this.form.value}
        });
    }

    completeMunicipality(event: any) {
        firstValueFrom(this.municipalityService.filter(event.query)).then(results => this.municipalitySuggestions = results,
            error => errorHandler('Error during auto-complete', error, this.msgService, this.translate, this.router)
        );
    }

    completeCountry(event: any) {
            firstValueFrom(this.countryService.filter(event.query)).then(results => this.countrySuggestions = results,
                error => errorHandler('Error during auto-complete', error, this.msgService, this.translate, this.router)
            );
        }
}

