

<ul class="layout-menu" *ngIf="isDesktop">
    <ng-container *ngFor="let item of desktopModel; let i = index">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
</ul>

<ul class="layout-menu" *ngIf="!isDesktop">
    <ng-container *ngFor="let item of mobileModel; let i = index">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
</ul>

