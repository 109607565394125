<div class="top-bar-wrapper h-6rem"
     style="padding-top: 20px;
     padding-left: 2rem;
     padding-right: 2rem;">
    <app-sidebar></app-sidebar>

    <div *ngIf="!showPaymentComponent">
        <div *ngIf="isDesktop">
            <!-- The Whole Top Bar -->
            <div class="layout-topbar h-6rem" style="padding:0; margin:0; overflow: visible; display: grid; grid-template-columns: auto 1fr auto; align-items: center;">
                <!-- Left Side of Top Bar -->
                <div class="topbar-start" style="margin-left: 10px;">
                    <button type="button" class="topbar-menubutton p-link p-trigger transition-duration-300" (click)="onMenuButtonClick()">
                        <i class="pi pi-bars" style="margin-left: 1px !important;"></i>
                    </button>

                    <a [routerLink]="['/']" class="mb-0 mr-4 ml-8" style="background-color: transparent; margin-top: 0 !important;">
                        <img [src]="'assets/opop/OpOp_logo_HiRes_OneLine.png'" width="140px" alt="OpOp" />
                    </a>

                    <div class="language-dropdown" style="margin-top: -5px !important;">
                        <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="label" (onChange)="changeLanguage($event.value.code)" appendTo="body">
                            <ng-template let-language pTemplate="selectedItem">{{language.label}}</ng-template>
                            <ng-template let-language pTemplate="item">{{language.label}}</ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <!-- The Middle Section (Buttons) -->
                <div class="topbar-mitt">
                    <button pButton class="top-bar-button" label="{{translate.instant('top-bar.about_us')}}" style="margin-left:0; padding-right:0;" icon="fas fa-info-circle" iconPos="left" routerLink="about-us" routerLinkActive="active-btn"></button>
                    <button pButton class="top-bar-button" label="{{translate.instant('top-bar.beneficiary')}}" style="margin-left:0;" icon="fas fa-leaf" iconPos="left" routerLink="about-beneficiary" routerLinkActive="active-btn"></button>
                    <button class="top-bar-button" pButton label="{{translate.instant('top-bar.donor')}}" style="margin-left:0;" icon="fas fa-umbrella" iconPos="left" routerLink="about-donor" routerLinkActive="active-btn"></button>
                    <button class="top-bar-button-green" pButton label="{{translate.instant('top-bar.donate')}}" style="margin-left:0; width:230px;" icon="fas fa-heart" iconPos="left" (click)="makePayment()"></button>
                </div>

                <!-- The Right Side of the Top Bar -->
                <div class="topbar-end" style="margin-right: 10px;">
                    <ul class="topbar-menu">
                        <li class="topbar-item">
                            <span class="mr-3 username">{{authService.loggedUserName}}</span>
                            <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple class="cursor-pointer">
                                <i class="fas fa-user" style="margin-top:0 !important; font-size: 2rem; color:var(--gray-900);"></i>
                            </a>
                            <ul [class]="'topbar-menu active-topbar-menu pt-4 w-18rem z-5 ng-hidden'">
                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                                    <a [routerLink]="'/auth/register/donor/step1'" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-cog mr-2"></i>
                                        <span>{{'login.SignUpDonor' | translate}}</span>
                                    </a>
                                </li>
                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                                    <a [routerLink]="'/auth/register/beneficiary/step1'" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-cog mr-2"></i>
                                        <span>{{'login.SignUpBeneficiary' | translate}}</span>
                                    </a>
                                </li>
                                <hr *ngIf="!authService.isAuthenticated"/>
                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                                    <a href="/auth/login" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-lock mr-2"></i>
                                        <span>{{'login.LogIn' | translate}}</span>
                                    </a>
                                </li>
                                <li role="menuitem" class="m-0 mb-3" *ngIf="authService.isAuthenticated">
                                    <a [routerLink]="['/']" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-user mr-2"></i>
                                        <span>Profile</span>
                                    </a>
                                </li>
                                <li role="menuitem" class="m-0" *ngIf="authService.isAuthenticated">
                                    <a href="" (click)="authService.logout()" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-sign-out mr-2"></i>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="!isDesktop">
            <div class="layout-topbar h-6rem"
                 style="padding:0; margin:0;
                 overflow: visible;
                 display: flex;
                 align-items:flex-start;">
                <!-- Left Side of Top Bar -->
                <div class="topbar-start"
                     style="
                     width:100%;
                     overflow: visible;
                     display:flex;
                     margin-top:0;
                     align-items: flex-start;">

                    <button type="button"
                            class="topbar-menubutton p-link p-trigger transition-duration-300" (click)="onMenuButtonClick()">
                        <i class="pi pi-bars" style="margin-left:10px !important; margin-top: 20px !important; font-size: 1.5rem !important; color:black !important;"></i>
                    </button>

                    <div class="language-dropdown ml-3 mt-3">
                        <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="label" (onChange)="changeLanguage($event.value.code)" appendTo="body">
                            <ng-template let-language pTemplate="selectedItem">{{language.label}}</ng-template>
                            <ng-template let-language pTemplate="item">{{language.label}}</ng-template>
                        </p-dropdown>
                    </div>
                    <ul class="topbar-menu"
                        style="
                        background-color: white;
                        width:600px;
                        margin-right: 10px;">
                        <li class="topbar-item">
                            <span class="mr-3 username">{{authService.loggedUserName}}</span>
                            <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple class="cursor-pointer">
                                <i class="fas fa-user"
                                   style="font-size: 1.5rem;
                                   margin-top:15px;
                                   margin-left:10px;
                                   color:black;"></i>
                            </a>
                            <ul [class]="'topbar-menu active-topbar-menu pt-4 w-18rem z-5 ng-hidden'">
                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                                    <a [routerLink]="'/auth/register/donor/step1'" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-cog mr-2"></i>
                                        <span>{{'login.SignUpDonor' | translate}}</span>
                                    </a>
                                </li>
                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                                    <a [routerLink]="'/auth/register/beneficiary/step1'" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-cog mr-2"></i>
                                        <span>{{'login.SignUpBeneficiary' | translate}}</span>
                                    </a>
                                </li>
                                <hr *ngIf="!authService.isAuthenticated"/>
                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">
                                    <a href="/auth/login" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-lock mr-2"></i>
                                        <span>{{'login.LogIn' | translate}}</span>
                                    </a>
                                </li>
                                <li role="menuitem" class="m-0 mb-3" *ngIf="authService.isAuthenticated">
                                    <a href="#" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-user mr-2"></i>
                                        <span>Profile</span>
                                    </a>
                                </li>
                                <li role="menuitem" class="m-0" *ngIf="authService.isAuthenticated">
                                    <a href="" (click)="authService.logout()" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                                        <i class="pi pi-fw pi-sign-out mr-2"></i>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <!-- The Right Side of the Top Bar -->
                <div class="topbar-end" style="width:100px; display:flex; align-items: flex-end;">
<!--                    <ul class="topbar-menu">-->
<!--                        <li class="topbar-item">-->
<!--                            <span class="mr-3 username">{{authService.loggedUserName}}</span>-->
<!--                            <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple class="cursor-pointer">-->
<!--                                <i class="fas fa-user" style="font-size: 1.5rem; color:black;"></i>-->
<!--                            </a>-->
<!--                            <ul [class]="'topbar-menu active-topbar-menu pt-4 w-18rem z-5 ng-hidden'">-->
<!--                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">-->
<!--                                    <a [routerLink]="'/auth/register/donor/step1'" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">-->
<!--                                        <i class="pi pi-fw pi-cog mr-2"></i>-->
<!--                                        <span>{{'login.SignUpDonor' | translate}}</span>-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">-->
<!--                                    <a [routerLink]="'/auth/register/beneficiary/step1'" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">-->
<!--                                        <i class="pi pi-fw pi-cog mr-2"></i>-->
<!--                                        <span>{{'login.SignUpBeneficiary' | translate}}</span>-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <hr *ngIf="!authService.isAuthenticated"/>-->
<!--                                <li role="menuitem" class="m-0 mb-3" *ngIf="!authService.isAuthenticated">-->
<!--                                    <a href="/auth/login" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">-->
<!--                                        <i class="pi pi-fw pi-lock mr-2"></i>-->
<!--                                        <span>{{'login.LogIn' | translate}}</span>-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li role="menuitem" class="m-0 mb-3" *ngIf="authService.isAuthenticated">-->
<!--                                    <a href="#" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">-->
<!--                                        <i class="pi pi-fw pi-user mr-2"></i>-->
<!--                                        <span>Profile</span>-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                                <li role="menuitem" class="m-0" *ngIf="authService.isAuthenticated">-->
<!--                                    <a href="" (click)="authService.logout()" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">-->
<!--                                        <i class="pi pi-fw pi-sign-out mr-2"></i>-->
<!--                                        <span>Logout</span>-->
<!--                                    </a>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </li>-->
<!--                    </ul>-->
                </div>


            </div>
        </div>
    </div>
</div>

<div *ngIf="showPaymentComponent" class="full-screen-modal">
    <div class="payment-container">
        <app-make-payment [data]="preparedData" (close)="onCloseChild()"></app-make-payment>
    </div>
</div>
