import {Component} from '@angular/core';
import {environment} from "../../environments/environment";
import {ActivatedRoute} from "@angular/router";

@Component({
    template: `<img [src]="'/' + apiRef + '/documents/' + paramsId + '/image'" alt="Image"/>`,
    selector: 'app-image-display'
})
export class ImageDisplayComponent {
    paramsId: string;

    constructor(private activatedRoute: ActivatedRoute) {
        this.paramsId = this.activatedRoute.snapshot.params.imgId;
    }

    get apiRef() {
        return environment.API_REF;
    }
}
