<div class="card">
    <p-messages severity="info" *ngIf="!authService.isAuthenticated">
        <ng-template pTemplate>
            <div class="ml-2 text-xl">{{'donation.haveDonorProfile'|translate}}</div>

            <div class="ml-2 text-xl">
                {{'donation.please'|translate}}<a routerLink="/auth/login" (click)="dialogRef.close()">
                {{'donation.login'|translate}}</a>
                {{'donation.or'|translate}}<a routerLink="/auth/register/donor/step1" (click)="dialogRef.close()">
                {{'donation.register'|translate}}</a>{{'donation.a-profile'|translate}}
            </div>
        </ng-template>
    </p-messages>

    <p-messages *ngIf="showVirmanMessage">
        <ng-template pTemplate>
            <div class="ml-2 text-xl">{{'donation.needRegForVirman'|translate}}</div>
            <div class="ml-2 text-xl">{{'donation.please'|translate}}<a routerLink="/auth/login" (click)="dialogRef.close()">
                {{'donation.login'|translate}}</a>
                {{'donation.or'|translate}}<a routerLink="/auth/register/donor/step1" (click)="dialogRef.close()">
                    {{'donation.register'|translate}}</a>{{'donation.a-profile'|translate}}
            </div>
        </ng-template>
    </p-messages>

    <form #donationForm [formGroup]="form">
        <h4 class="text-gray-600" *ngIf="!authService.isAuthenticated">
            {{'donation.donateWithoutProfile'|translate}}</h4>
        <!-- This is drawn if user not authenticated -->
        <ng-container *ngIf="!authService.isAuthenticated">
            <div class="field">
                <label for="firstName" class="text-lg">{{'entity.person.personFirstName' | translate}}</label>
                <input id="firstName" class="w-full" pInputText type="text" formControlName="firstName">
            </div>

            <div class="field">
                <label for="lastName" class="text-lg">{{'entity.person.personLastName' | translate}}</label>
                <input id="lastName" class="w-full" pInputText type="text" formControlName="lastName">
            </div>

            <div class="field">
                <label for="email" class="text-lg">{{'entity.person.personEmail' | translate}}</label>
                <input id="email" class="w-full" pInputText type="email" formControlName="email">
            </div>
        </ng-container>

        <!-- This is always drawn for everyone -->
        <div class="field p-fluid">
            <label class="text-lg">{{'donation.donationAmount'|translate}}</label>

            <div class="flex">
                <p-dropdown [options]="paymentAmountValueOptions" optionLabel="name"
                            appendTo="body" styleClass="w-18rem"
                            optionValue="value" (onChange)="onSelectAmount($event)">
                    <ng-template pTemplate="selectedItem">
                        {{form.value.amount && !customValue ? form.value.amount + ' MKD' : 'Other'}}
                    </ng-template>
                    <ng-template let-item pTemplate>
                        <ng-container *ngIf="item.value !== null">
                            <i class="pi pi-money-bill mr-2"></i>
                            {{item.value | number}} MKD
                        </ng-container>
                        <ng-container *ngIf="item.value === null">
                            {{'donation.other'|translate}}
                        </ng-container>
                    </ng-template>
                </p-dropdown>
                <div class="p-inputgroup w-full opop-field-height" *ngIf="customValue">
                    <p-inputNumber class="custom-inputNumber" formControlName="amount" [min]="1"
                                   placeholder="{{'donation.otherAmount'|translate}}"
                                   (onFocus)="$event.target.select()" styleClass="ml-1">
                    </p-inputNumber>
                    <span class="p-inputgroup-addon">MKD</span>
                </div>

            </div>

            <div class="mt-3" *ngIf="!authService.isAuthenticated">
                <h6>{{'donation.agreeTerms'|translate}}
                    <u class="cursor-pointer" (click)="showTerms()">
                        {{'donation.termsOfUse'|translate}}</u>
                </h6>
            </div>

            <div class="mt-3 button-row">
<!--                CC Card Button-->
                <button type="button" class="mt-2 mb-2 mr-2 p-button-outlined"
                        [ngClass]="{'p-button-raised': selectedButton === 'Card'}"
                        pButton [icon]="selectedButton === 'Card' ? 'pi pi-check' : ''"
                        (click)="selectButton('Card')" label="Card"
                        [disabled]="form.invalid || submitClicked || disableOneTime">
                </button>
<!--                Virman Button-->
                <button
                    type="button"
                    class="mt-2 mb-2 mr-2 p-button-outlined d-flex flex-column justify-content-center align-items-center"
                    [ngClass]="{'p-button-raised': selectedButton === 'Virman'}"
                    pButton
                    [icon]="selectedButton === 'Virman' ? 'pi pi-check' : ''"
                    (click)="selectButton('Virman')"
                    [disabled]="isDonorLoggedIn">

                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <span><Strong>{{'donation.bankTransfer'|translate}}</Strong></span>

                        <small *ngIf="isDonorLoggedIn" class="text-center text-sm">
                            <div class="ml-2 text-sm">
                                <a routerLink="/auth/login" (click)="dialogRef.close()">{{'donation.login'|translate}}</a>
                                {{'donation.or'|translate}} <a routerLink="/auth/register/donor/step1" (click)="dialogRef.close()">
                                {{'donation.aDonorProfileForVirman'|translate}}</a>
                            </div>
                        </small>
                    </div>

                </button>
<!--                Pledge Button-->
                <button type="button" class="mt-2 mb-2  p-button-outlined"
                        [ngClass]="{'p-button-raised': selectedButton === 'Pledge'}"
                        pButton [icon]="selectedButton === 'Pledge' ? 'pi pi-check' : ''"
                        (click)="selectButton('Pledge')" label="Pledge"
                        [disabled]="form.invalid || submitClicked || disableOneTime">
                </button>
            </div>

            <button type="button" class="mt-1 p-button-outlined" pButton
                    (click)="submit()" label="Submit"
                    [disabled]="form.invalid || submitClicked || disableOneTime">
            </button>
        </div>
    </form>

    <form #formCasys method="post"
          action="https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/en-US/.loginToPay/.simple/">
        <app-cpay [cpay]="cpay"></app-cpay>
    </form>
</div>
