import { Component } from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Input} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute } from '@angular/router';
import {EmailService} from "../services/email.service";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-virman',
  templateUrl: './virman.component.html'
})
export class VirmanComponent {
    @Input() DonorName: string;
    @Input() DonorAddress: string;
    @Input() DonorBankAccNr: string;
    @Input() DonorBankName: string;
    @Input() DonorID: string;
    @Input() DonorBankAccountName: string;

    @Input() BeneficiaryID: string;
    @Input() BeneficiaryName: string;
    @Input() BeneficiaryAddress: string;
    @Input() BeneficiaryBankName: string;
    @Input() BeneficiaryBankAccNr: string;
    @Input() BeneficiaryBankAccountName: string;

    @Input() DirectProjectId: string;
    @Input() ProjectName: string;
    @Input() Amount: string;
    @Input() TargetType: string;
    @Input() PurposeText: string;


    public vimanForm: FormGroup;
    donationPurpose: string;


  constructor(private emailService: EmailService,
              private translate: TranslateService,
              private router: Router, private route: ActivatedRoute, private location: Location) {
    this.route.paramMap.subscribe(params => {
      this.vimanForm = new FormGroup({
          DonorName: new UntypedFormControl(null, Validators.required),
          DonorAddress: new UntypedFormControl(null, Validators.required),
          DonorBankAccNr: new UntypedFormControl(null, Validators.required),
          DonorBankName: new UntypedFormControl(null, Validators.required),
          DonorID: new UntypedFormControl(null, Validators.required),
          DonorBankAccountName: new UntypedFormControl(null, Validators.required),
          BeneficiaryID: new UntypedFormControl(null, Validators.required),

          BeneficiaryName: new UntypedFormControl(null, Validators.required),
          BeneficiaryAddress: new UntypedFormControl(null, Validators.required),
          BeneficiaryBankName: new UntypedFormControl(null, Validators.required),
          BeneficiaryBankAccNr: new UntypedFormControl(null, Validators.required),
          BeneficiaryBankAccountName: new UntypedFormControl(null, Validators.required),

          fixedReceiverName:new UntypedFormControl('Zdruzenie Konent Skopje'),
          fixedReceiverAddress:new UntypedFormControl('Risto Shishkov 12/1-4'),
          fixedReceiverBankName:new UntypedFormControl('Komercijalna Banka Skopje'),
          fixedReceiverAccNr:new UntypedFormControl('000-111111-222'),

          DirectProjectId: new UntypedFormControl(null, Validators.required),
          Amount: new UntypedFormControl(null, Validators.required),
          TargetType: new UntypedFormControl(null, Validators.required),
          ProjectName: new UntypedFormControl(null, Validators.required),
          PurposeText: new UntypedFormControl(null, Validators.required)
      });
      const prefillDonorName = params.get('DonorName');
      const prefillDonorAddress = params.get('DonorAddress');
      const prefillDonorBankAccNr = params.get('DonorBankAccNr');
      const prefillDonorBankName = params.get('DonorBankName');
      const prefillDonorID = params.get('DonorID');
      const prefillDonorBankAccountName = params.get('DonorBankAccountName');
      const prefillBeneficiaryID = params.get('BeneficiaryID');
      const prefillBeneficiaryName = params.get('BeneficiaryName');
      const prefillBeneficiaryAddress = params.get('BeneficiaryAddress');
      const prefillBeneficiaryBankName = params.get('BeneficiaryBankName');
      const prefillBeneficiaryBankAccNr = params.get('BeneficiaryBankAccNr');
      const prefillBeneficiaryBankAccountName = params.get('BeneficiaryBankAccountName');
      const prefillDirectProjectId = params.get('DirectProjectId');
      const prefillAmount = params.get('Amount');
      const prefillPurposeText = params.get('PurposeText');
      const prefillTargetType = params.get('TargetType');
      const prefillTargetTIdentifier = params.get('TargetTIdentifier');
      const prefillProjectName = params.get('ProjectName');
      const donorID= this.translate.instant('donation.donorID');
      const beneficiaryID= this.translate.instant('donation.beneficiaryID');
      const projectID= this.translate.instant('donation.projectID');
      const projectName= this.translate.instant('donation.projectName');
      const beneficiaryName= this.translate.instant('donation.beneficiaryName');


      if (prefillPurposeText) { this.vimanForm.get('PurposeText').setValue(prefillPurposeText);}
      if (prefillDonorName) { this.vimanForm.get('DonorName').setValue(prefillDonorName);}
      if (prefillDonorAddress) { this.vimanForm.get('DonorAddress').setValue(prefillDonorAddress); }
      if (prefillDonorBankAccNr) { this.vimanForm.get('DonorBankAccNr').setValue(prefillDonorBankAccNr);}
      if (prefillDonorBankName) { this.vimanForm.get('DonorBankName').setValue(prefillDonorBankName);}
      if (prefillDonorID) { this.vimanForm.get('DonorID').setValue(donorID+prefillDonorID); }
      if (prefillDonorBankAccountName) { this.vimanForm.get('DonorBankAccountName').setValue(prefillDonorBankAccountName);}

      if (prefillBeneficiaryID) { this.vimanForm.get('BeneficiaryID').setValue(beneficiaryID+prefillBeneficiaryID); }

      if (prefillBeneficiaryName) { this.vimanForm.get('BeneficiaryName').setValue(prefillBeneficiaryName); }
      if (prefillBeneficiaryAddress) { this.vimanForm.get('BeneficiaryAddress').setValue(prefillBeneficiaryAddress); }
      if (prefillBeneficiaryBankName) {  this.vimanForm.get('BeneficiaryBankName').setValue(prefillBeneficiaryBankName); }
      if (prefillBeneficiaryBankAccNr) { this.vimanForm.get('BeneficiaryBankAccNr').setValue(prefillBeneficiaryBankAccNr); }
      if (prefillBeneficiaryBankAccountName) { this.vimanForm.get('BeneficiaryBankAccountName').setValue(prefillBeneficiaryBankAccountName); }
      if (prefillDirectProjectId) { this.vimanForm.get('DirectProjectId').setValue(beneficiaryName+prefillDirectProjectId); }

      let mkdAmount = 'MKD' + ' ' + prefillAmount;
      if (prefillAmount) { this.vimanForm.get('Amount').setValue(mkdAmount); }
      if (prefillTargetType) { this.vimanForm.get('TargetType').setValue(prefillTargetType); }
      if (prefillTargetTIdentifier) { this.vimanForm.get('TargetTIdentifier').setValue(prefillTargetTIdentifier);}
      if (prefillProjectName) { this.vimanForm.get('ProjectName').setValue(prefillProjectName);}

        let target = this.vimanForm.get('TargetType').value;
        if (target === 'DonationToProject') {
            let useString = projectName + this.vimanForm.get('ProjectName').value;
            this.vimanForm.get('PurposeText').setValue(useString);
        }

        else if (target === 'DonationToBeneficiary') {
            let useString = beneficiaryName + this.vimanForm.get('BeneficiaryName').value;
            this.vimanForm.get('PurposeText').setValue(useString);
        }

        else {
            let useString = this.getDonationTargetText() + ' ' + this.vimanForm.get('BeneficiaryName').value;
            this.vimanForm.get('PurposeText').setValue(useString);
        }

    });
      this.donationPurpose = this.getDonationTargetText();
  }
  close() {
    this.location.back();
  }

    getDonationTargetText(): string {
      let target = this.vimanForm.get('TargetType').value;
        if (target === 'DonationToProject') {
            return this.translate.instant('donation.donationToProject');

        } else if (target === 'DonationToBeneficiary') {
            return this.translate.instant('donation.donationToBeneficiary')
        } else {
            return this.translate.instant('donation.donationToPlatform')
        }
    }
  makePDF() {
    html2canvas(document.getElementById('virmanId')).then(canvas => {
      const pdf = new jsPDF('p', 'mm', 'a5');  // Initialize jsPDF with orientation ('p' or 'l'), unit ('mm', 'cm', 'in'), and page size ('a4', 'letter', etc.)

      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 297/2;  // width of A4 in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('Virman.pdf');
    });
  }
    makePDF_VECTOR() {
        const docDefinition = {
            content: [
                { text: 'This is a header', style: 'header' },
                'No styling here, this is a standard paragraph',
                { text: 'Another text', bold: true },
                // Include more text, styling, and other content as needed
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true
                }
                // Define more styles as needed
            }
        };

        pdfMake.createPdf(docDefinition).download('output.pdf');
    }

}
