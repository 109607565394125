<div class="surface-0 h-screen" style="top:0; left:0; width:100%; height:100%; background-color:#f2f2f2; ">

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="surface-0 pt-4 pl-4 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-0">
                <img [src]="'assets/opop/logo.png'" alt='' class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div class="surface-0" style="margin: 0 5% 0 5%;">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>


        <div class="flex align-items-center pt-4 justify-content-between flex-column">
<!--            I disabled this untill the SMS Gateway from Telekom starts working... -->
<!--            <div class="form-container text-center mt-6" style="width: 420px;">-->
<!--                <div class="border-1 surface-border p-2 z-index border-round">-->
<!--                    <div style="margin:1rem 2rem 2rem 2rem;">-->
<!--                        <span class="text-l">{{'otp.verifySms' | translate}}</span>-->
<!--                    </div>-->
<!--                    <div class="formgroup-inline mt-3 justify-content-center">-->
<!--                        <div class="field">-->
<!--                            <div class="p-inputgroup p-fluid">-->
<!--                                <span class="p-inputgroup-addon"><i class="pi pi-comment"></i>-->
<!--                                    <span class="ml-1 text-l">{{'otp.sms' | translate}}</span>-->
<!--                                </span>-->
<!--                                <p-inputMask mask="9 9 9 9"-->
<!--                                             [style]="{'width':'120px', 'border-radius': '0'}"-->
<!--                                             formControlName="phonePin" styleClass="text-l p-3" placeholder="PIN"-->
<!--                                             [keepBuffer]="true"></p-inputMask>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <p-button label="{{'otp.requestNewOtpSms' | translate}}" (onClick)="requestNewOtpSms()"-->
<!--                              styleClass="text-l text-center  p-button-link"-->
<!--                              [disabled]="disableSmsOtp"></p-button>-->
<!--                    <div style="display: flex; justify-content: center;">-->
<!--                        <app-timer #timerSmsOtp></app-timer>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <div class="form-container text-center mt-3" style="width: 420px;">
                <div class="border-1 surface-border p-2 z-index border-round">
                    <div style="margin:1rem 2rem 2rem 2rem;">
                        <span class="text-l">{{'otp.verifyEmail' | translate}}</span>
                    </div>
                    <div class="formgroup-inline mt-3 justify-content-center">
                        <div class="field">
                            <div class="p-inputgroup p-fluid">
                                <span class="p-inputgroup-addon"><i class="pi pi-at"></i>
                                    <span class="ml-1 text-l">{{'otp.email' | translate}}</span>
                                </span>
                                <p-inputMask mask="9 9 9 9"
                                             [style]="{'width':'120px', 'border-radius': '0'}"
                                             formControlName="emailPin" styleClass="text-l p-3" placeholder="PIN"
                                             [keepBuffer]="true"></p-inputMask>
                            </div>
                        </div>
                    </div>
                    <p-button label="{{'otp.requestNewOtpEmail' | translate}}" (onClick)="requestNewOtpEmail()"
                              styleClass="text-l text-center p-button-link"
                              [disabled]="disableEmailOtp"></p-button>

                    <div style="display: flex; justify-content: center;">
                        <app-timer #timerEmailOtp></app-timer>
                    </div>

                </div>
            </div>

            <div class="button-container mt-4 text-left " style="width: 420px;">
                <div class="buttons flex align-items-center gap-3">
                    <button type="submit" icon="pi pi-arrow-right" pButton label="{{'btn.next' | translate}}" class="pt-3 pb-3 block"
                            style="min-width: 420px; max-width: 420px; margin-bottom: 32px;"
                            [disabled]="form.invalid || disableSubmit"></button>
                </div>
            </div>
        </div>

    </form>
</div>
