import {CampaignCategory} from "../enums/campaign-category";
import { TargetType } from "../enums/target-type";

export interface Campaign {
    id: string;
    title: string;
    description: string;
    validUntil: Date;
    daysLeft: number;
    background: string;
    campaignCategory: CampaignCategory;
    donorId: string;
    projectId: string;
    projectTitle: string;
    targetType: TargetType;
}

export interface CampaignCreate {
    title: string;
    description: string;
    validUntil: Date;
    daysLeft: number;
    background: string;
    campaignCategory: CampaignCategory;
    donorId: string;
    projectId: string;
    targetType: TargetType;
}

export interface CampaignUpdate extends CampaignCreate {
    id: string;
}

export function toCampaignCreate(campaign: Campaign) {
    return {
        title: campaign.title,
        description: campaign.description,
        validUntil: campaign.validUntil,
        daysLeft: campaign.daysLeft,
        background: campaign.background,
        campaignCategory: campaign.campaignCategory,
        donorId: campaign.donorId,
        projectId: campaign.projectId,
        targetType: campaign.targetType
    }
}

export function toCampaignUpdate(campaign: Campaign) {
    let campaignUpdate = toCampaignCreate(campaign) as CampaignUpdate;
    campaignUpdate.id = campaign.id;
    return campaignUpdate;
}

