<div class="dialog-content" style="margin-bottom: 350px;">
    <div class="horizontal-line-thick-margins-indigo"></div>
    <span [innerHTML]="'footer.privacyPolicy' | translate"></span>

    <p-footer class="no-margins-footer">
        <div class="terms-button-container" >
            <div class="terms-right-buttons">
                <button type="button"
                        class="mt-1  ml-2 mailer-buttons"
                        pButton
                        (click)="onAccept()"
                        style="border-radius: 4px !important;"
                        label="{{'footer.termsClose'|translate}}">
                    <i class="fas fa-times" style="color:white; font-size:1.4rem; padding:0; margin-right:10px;"></i>
                </button>
            </div>
        </div>
    </p-footer>
</div>
