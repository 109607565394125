import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericApiService } from '../components/generics/generic-api.service';
import {
    Beneficiary,
    toBeneficiaryCreate,
    toBeneficiaryUpdate,
} from '../model/beneficiary';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Otp, OtpResponse } from '../model/otp';
import { environment } from '../../environments/environment';

@Injectable()
export class BeneficiaryService extends GenericApiService<Beneficiary> {
    override serviceUrl = `/${environment.API_REF}/beneficiaries`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public create(beneficiary: Beneficiary): Observable<Beneficiary> {
        return this.http
            .post<Beneficiary>(
                `${this.serviceUrl}/create`,
                toBeneficiaryCreate(beneficiary)
            )
            .pipe(map((res) => this.parse(res)));
    }

    public update(beneficiary: Beneficiary): Observable<Beneficiary> {
        if (
            !(
                beneficiary.company?.contactPerson?.firstName &&
                beneficiary.company?.contactPerson?.lastName
            )
        ) {
            beneficiary.company.contactPerson = null;
        }

        if (
            !(
                beneficiary.company?.signaturePerson?.firstName &&
                beneficiary.company?.signaturePerson?.lastName
            )
        ) {
            beneficiary.company.signaturePerson = null;
        }

        if (
            !(beneficiary.director?.firstName && beneficiary.director?.lastName)
        ) {
            beneficiary.director = null;
        }

        return this.http
            .put<Beneficiary>(
                `${this.serviceUrl}/`,
                toBeneficiaryUpdate(beneficiary)
            )
            .pipe(map((res) => this.parse(res)));
    }

    public updateStatus(beneficiaryStatusUpdate: any): Observable<void> {
        return this.http
            .put<Beneficiary>(
                `${this.serviceUrl}/update_status`,
                beneficiaryStatusUpdate
            )
            .pipe(map((res) => this.parse(res)));
    }

    public verifyOtp(otpConfirmation: any): Observable<OtpResponse> {
        return this.http
            .post<string>(`${this.serviceUrl}/otp`, otpConfirmation)
            .pipe(map((res) => this.parse(res)));
    }

    public regenerateOtpEmail(beneficiaryId: string): Observable<Otp> {
        return this.http
            .put<string>(
                `${this.serviceUrl}/${beneficiaryId}/regenerate-otp-email`,
                {}
            )
            .pipe(map((res) => this.parse(res)));
    }

    public regenerateOtpPhone(beneficiaryId: string): Observable<Otp> {
        return this.http
            .put<string>(
                `${this.serviceUrl}/${beneficiaryId}/regenerate-otp-phone`,
                {}
            )
            .pipe(map((res) => this.parse(res)));
    }

    public getAllBeneficiaries(): Observable<Beneficiary[]> {
        return this.http
            .get<Beneficiary[]>(`${this.serviceUrl}`)
            .pipe(map((res) => this.parse(res)));
    }

    public getById(id: string): Observable<Beneficiary> {
        return this.http
            .get<Beneficiary>(`${this.serviceUrl}/${id}`)
            .pipe(map((res) => this.parse(res)));
    }

    public payout(projectId: string): Observable<any> {
        return this.http
            .put<any>(
                `${this.serviceUrl}/${projectId}/pay_out`,
                {} // Empty body as per new API spec
            )
            .pipe(map((res) => this.parse(res)));
    }
}
