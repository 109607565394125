
import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {OpopDocumentUploadComponent} from "../../../../components/generics/opop-document-upload.component";

import {donorOrganizationSteps} from "./donor-organization-steps";
import {Donor} from "../../../../model/donor";
import {DonorType} from "../../../../enums/donor-type";
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";

import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {DocumentService} from "../../../../services/document.service";
import {DocumentType} from "../../../../enums/document-type";
import {HttpClient} from '@angular/common/http';
import {FileUpload, FileUploadModule} from "primeng/fileupload/fileupload";
import {environment} from "../../../../../environments/environment";
import {errorHandler} from "../../../../support/functions";
import {UserService} from "../../../../services/user.service";
import {firstValueFrom} from "rxjs";
import {AuthService} from "../../../../support/auth.service";
import {StorageService} from "../../../../support/storage.service";



@Component({
    templateUrl: '/donor-register-step4.component.html',
    styles: [`body {
        background: var(--surface-ground);
    }`, `
      .p-fileupload-choose {
        display: none !important;
      }

      .p-fileupload .p-fileupload-buttonbar {
        border: 0;
      }

      .p-fileupload .p-fileupload-content {
        -moz-border-radius: 8px;
        -webkit-border-radius: 8px;
        padding: 0
      }
    `],
    encapsulation: ViewEncapsulation.None
})
export class DonorRegisterStep4Component implements OnInit {
    public form: UntypedFormGroup;
    protected readonly DocumentType = DocumentType;
    public readonly donor: Donor;
    items: MenuItem[];
    @ViewChild('fileUploader') fileUpload: FileUpload;
    @ViewChild(OpopDocumentUploadComponent) documentUploadComponent: OpopDocumentUploadComponent;
    generatedDocument: Blob;
    params: any;


    constructor(public layoutService: LayoutService, private router: Router, private translate: TranslateService,
                private confirmationService: ConfirmationService,private userService: UserService,
                private http: HttpClient,private msgService: MessageService,private authService: AuthService,
                public documentService: DocumentService, private storageService: StorageService ) {
        this.items = donorOrganizationSteps(this.translate);
        this.donor = this.router.getCurrentNavigation().extras?.state?.donor;

        this.form = new UntypedFormGroup({
            documentIds: new UntypedFormControl([]),
            documents: new UntypedFormControl([])
        });
    }

    onDocumentGenerated(document: Blob) {
        //this.generatedDocument = new File([document], 'DonorAgreement_v1.docx', { type: document.type });

        this.generatedDocument = new File([document], 'DonorAgreement_v1.docx', { type: 'DocumentType.CONTRACT' });

        // this.generatedDocument = generatedDocument2;
        this.uploadDonorDocument(this.generatedDocument);
    }

    uploadDonorDocument(document: Blob) {
        const event = {
            files: [document],
            formData: new FormData()
        };
        event.formData.append('files', document, 'DonorAgreement_v1.docx');
        this.documentUploadComponent.handleFileUpload(event);
    }

    ngOnInit(): void {
        this.translate.get('btn.downloadContract').subscribe((translatedTitle: string) => {
            this.params = {
                templateDocumentPath: 'assets/wordTemplates/template2.docx',
                replacements: [{ find: 'YYYY', replaceWith: 'ReplacementText'}],
                outputDocumentName: 'ProjectAgreement_vA.docx',
                title: translatedTitle,
                emailTo: 'dino.gurcilov@gmail.com'
            };
        });
        if (!this.donor) {
            this.router.navigate(['/'])
        }
    }

    submit() {
    }
    closeTheWindow() {
        this.router.navigate(['/']);
        // if (this.form.controls.documents.value.length === 0) {
        //     this.confirmationService.confirm({
        //         message: this.translate.instant('msg.contractNotDownloaded'),
        //         rejectVisible: false,
        //         closeOnEscape: false,
        //         acceptLabel: 'OK',
        //         icon: 'pi pi-info-circle',
        //           accept: () => this.router.navigate(['/']),
        //         // reject: () => this.router.navigate(['/'])
        //     });
        // }
        // else if (this.form.controls.documents.value.length >= 1) {
        //     this.confirmationService.confirm({
        //         message: this.translate.instant('msg.contractCreatedAndDownloaded'),
        //         rejectVisible: false,
        //         closeOnEscape: false,
        //         acceptLabel: 'OK',
        //         icon: 'pi pi-info-circle',
        //          accept: () => this.router.navigate(['/']),
        //         // reject: () => this.router.navigate(['/'])
        //     });
        // }
    }
}
