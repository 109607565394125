import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BeneficiaryService} from "../../../../services/beneficiary.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, MessageService} from "primeng/api";
import {beneficiarySteps} from "./beneficiary-steps";
import {Beneficiary} from "../../../../model/beneficiary";
import {firstValueFrom} from "rxjs";
import {UserService} from "../../../../services/user.service";

@Component({
    templateUrl: './beneficiary-register-step2a.component.html'
})

export class BeneficiaryRegisterStep2aComponent {
    public contactPersonForm: UntypedFormGroup;
    public signaturePersonForm: UntypedFormGroup;
    items: MenuItem[];
    private readonly beneficiary: Beneficiary;
    loading: boolean;
    emailExists: boolean;

    constructor(private beneficiaryService: BeneficiaryService, private router: Router, private translate: TranslateService,
                private msgService: MessageService, private userService: UserService) {
        this.items = beneficiarySteps(this.translate);
        this.beneficiary = this.router.getCurrentNavigation().extras?.state?.beneficiary;

        this.contactPersonForm = new UntypedFormGroup({
            firstName: new UntypedFormControl(null, Validators.required),
            lastName: new UntypedFormControl(null, Validators.required),
            phone: new UntypedFormControl('389', Validators.required),
            email: new UntypedFormControl(null, Validators.required),
            inactive: new UntypedFormControl(false, Validators.required)
        });

        this.signaturePersonForm= new UntypedFormGroup({
            firstName: new UntypedFormControl(null, Validators.required),
            lastName: new UntypedFormControl(null, Validators.required),
            phone: new UntypedFormControl('389', Validators.required),
            email: new UntypedFormControl(null, Validators.required),
            inactive: new UntypedFormControl(false, Validators.required)
        });
    }


    submit() {
        this.loading = true;

        this.beneficiary.company.contactPerson = this.contactPersonForm.getRawValue();
        this.beneficiary.company.signaturePerson = this.signaturePersonForm.getRawValue();

        this.beneficiary.trusted = 0;
        // this crashes
        firstValueFrom(this.beneficiaryService.create(this.beneficiary))
            .then(result => {
                    this.router.navigateByUrl(`auth/register/beneficiary/step3`, {state: {beneficiary: result}});
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this.msgService.add({
                        severity: 'error', life: 6000,
                        summary: 'Error',
                        detail: this.translate.instant(error.message),
                    });
                }
            );
    }

    onEmailInput() {
        if (!this.contactPersonForm.controls.email.invalid) {
            firstValueFrom(this.userService.checkEmail(this.contactPersonForm.controls.email.value))
                .then(exists => this.emailExists = exists);
        } else {
            this.emailExists = false;
        }
    }
}
