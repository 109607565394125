<p-dialog [header]="'footer.cookieTitle' | translate" [(visible)]="showCookieDialog" >

    <div class="my-custom-dialog">
        <br>
        <span [innerHTML]="'footer.cookieText' | translate"></span>
        <br>
        <div class="horizontal-line-thick-margins"></div>
        <table class="opop-table-cookie"
               style="margin-top:20px; margin-left:0px; margin-right:0px; padding-bottom: 200px;">
            <thead>
                <tr >
                    <th class="tdKey" >{{'footer.cookieName' | translate}}</th>
                    <th class="tdVal">{{'footer.cookieDescription' | translate}}</th>
<!--                    <th> </th>-->
                </tr>
            </thead>

            <tbody>
<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName1' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue1' | translate}}</td>-->
<!--                <td class="tdCheck"><input type="checkbox"></td>-->
<!--            </tr>-->

<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName2' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue2' | translate}}</td>-->
<!--            </tr>-->

<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName3' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue3' | translate}}</td>-->
<!--            </tr>-->

<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName4' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue4' | translate}}</td>-->
<!--            </tr>-->

<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName5' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue5' | translate}}</td>-->
<!--            </tr>-->

<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName6' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue6' | translate}}</td>-->
<!--            </tr>-->

<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName7' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue7' | translate}}</td>-->
<!--            </tr>-->
            <tr>
                <td class="tdKey">{{'footer.cookieName8' | translate}}</td>
                <td class="tdVal">{{'footer.cookieValue8' | translate}}</td>
            </tr>
            <tr>
                <td class="tdKey">{{'footer.cookieName9' | translate}}</td>
                <td class="tdVal">{{'footer.cookieValue9' | translate}}</td>
            </tr>
<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName10' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue10' | translate}}</td>-->
<!--            </tr>-->
            <tr>
                <td class="tdKey">{{'footer.cookieName11' | translate}}</td>
                <td class="tdVal">{{'footer.cookieValue11' | translate}}</td>
            </tr>
<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName12' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue12' | translate}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName13' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue13' | translate}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--                <td class="tdKey">{{'footer.cookieName14' | translate}}</td>-->
<!--                <td class="tdVal">{{'footer.cookieValue14' | translate}}</td>-->
<!--            </tr>-->
            </tbody>
        </table>
        <br>
        <br>  <br> <br>


        <p-footer class="dialog-footer">
            <p-button *ngIf="isDesktop" (onClick)="downloadPrivacyForm()"
                      label="{{'footer.privacyDownload' | translate}}"
                      icon="fa fa-download fa-lg"
                      styleClass="button-download"></p-button>

            <p-button (onClick)="disallowCookies()"
                      label="{{'footer.cookieDisallow' | translate}}"
                      icon="fa fa-stop fa-lg"
                      styleClass="button-disallow"></p-button>

            <p-button (onClick)="allowCookies()"
                      label="{{'footer.cookieAllow' | translate}}"
                      icon="fa fa-check fa-lg"
                      styleClass="button-allow"></p-button>

        </p-footer>
    </div>
</p-dialog>
