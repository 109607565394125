import { Component, ViewChild } from '@angular/core';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Project } from '../../model/project';
import {
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { AuthService } from '../../support/auth.service';
import { DonorService } from '../../services/donor.service';
import { firstValueFrom } from 'rxjs';
import { errorHandler } from '../../support/functions';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TransactionService } from 'src/app/services/transaction.service';
import { Cpay } from '../../model/cpay';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { Transaction } from '../../model/transaction';
import { Role } from '../../enums/role';
import { TermsAndConditionsComponent } from '../../footer/terms-conditions.component';

@Component({
    selector: 'app-donate-project',
    templateUrl: 'donate-project.component.html',
})
export class DonateProjectComponent {
    selectedButton: string = '';
    submitClicked = false;
    public showVirmanMessage: boolean = false;
    project: Project;
    form: FormGroup;
    cpay: Cpay;

    @ViewChild('formCasys') formCasys;
    paymentAmountValueOptions: any[];
    customValue = true;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public authService: AuthService,
        public donorService: DonorService,
        public msgService: MessageService,
        public translate: TranslateService,
        public router: Router,
        public dialogRef: DynamicDialogRef,
        private dialogService: DialogService,
        public transactionService: TransactionService
    ) {
        this.project = this.config.data;

        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(
                this.authService.userDetails?.donor?.wallet?.id
            ),
            firstName: new UntypedFormControl(), // Onetime donation
            lastName: new UntypedFormControl(), // Onetime donation
            email: new UntypedFormControl(), // Onetime donation
            amount: new UntypedFormControl(null, [
                Validators.required,
                Validators.min(1),
            ]),
            projectId: new UntypedFormControl(
                this.project.id,
                Validators.required
            ),
        });
        this.cpay = this.makeCpay();

        this.paymentAmountValueOptions = [
            { name: '200', value: 200 },
            { name: '500', value: 500 },
            { name: '1000', value: 1000 },
            { name: '3000', value: 3000 },
            { name: 'Other', value: null },
        ];
    }

    selectButton(label: string) {
        this.selectedButton = label;
    }

    get isDonorLoggedIn(): boolean {
        //         console.log ("-> isDonorLoggedIn" );
        //         console.log (this.authService.userDetails?.donor);

        return (
            !this.authService.isAuthenticated || this.authService.userDetails.role === Role.DONOR
        );

        // return (this.authService.isAuthenticated &&
        //     this.authService.userDetails.role === Role.DONOR &&
        //     !this.authService.userDetails.donor);
    }
    protected validate(): boolean {
        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails.role === Role.DONOR &&
            !this.authService.userDetails.donor
        ) {
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Unable to find the donor!',
                detail: '',
            });
            return false;
        }

        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails.role === Role.DONOR &&
            !this.authService.userDetails.donor.wallet
        ) {
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Missing wallet for the donor!',
                detail: '',
            });
            return false;
        }

        return true;
    }

    async submit() {
        if (!this.validate()) {
            return;
        }

        if (this.selectedButton === 'Virman') {
            this.ref.close();
            this.router.navigate([
                '/virman',
                {
                    DonorName: 'ThisIsDonorName',
                    Amount: this.form.controls.amount.value,
                    ProjectName: 'Dinos Project',
                },
            ]);
        }

        const transaction =
            this.authService.isAuthenticated &&
            this.authService.userDetails.role === Role.DONOR
                ? await this.donateInternal()
                : await this.donateOneTimeInternal();

        // Pay transaction in Casys
        if (transaction != null) {
            this.submitClicked = true;

            if (!environment.production) {
                this.donateLocally(transaction.transactionId);
            } else {
                // Amount to pay multiplied by 100 - without decimal and separators, i.e. amount 1,00 will be value 100
                this.cpay.AmountToPay = this.form.controls.amount.value * 100;
                this.setCpayTransactionDetailsAndChecksum(
                    transaction.transactionId
                );
                setTimeout(() => this.formCasys.nativeElement.submit(), 500);
            }
        } else {
        }
    }

    donateInternal(): Promise<Transaction> {
        return firstValueFrom(
            this.donorService.donate(this.form.getRawValue())
        ).then(
            (transaction) => {
                return transaction;
            },
            (error) => {
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                return null;
            }
        );
    }

    donateOneTimeInternal(): Promise<Transaction> {
        return firstValueFrom(
            this.donorService.donateOneTime(this.form.getRawValue())
        ).then(
            (transaction) => {
                return transaction;
            },
            (error) => {
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                return null;
            }
        );
    }

    makeCpay() {
        return {
            AmountToPay: 0,
            AmountCurrency: 'MKD',
            Details1: `Donacija za: ${this.project.title}`,
            Details2: '',
            PayToMerchant: environment.PAY_TO_MERCHANT,
            MerchantName: environment.MERCHANT_NAME,
            PaymentOKURL: '',
            PaymentFailURL: '',
            CheckSumHeader: '',
            CheckSum: '',
        } as Cpay;
    }

    setCpayTransactionDetailsAndChecksum(transactionId) {
        this.cpay.Details2 = transactionId;
        this.cpay.PaymentOKURL = `${environment.DOMAIN}/${environment.API_REF}/transactions/cpay_success`;
        this.cpay.PaymentFailURL = `${environment.DOMAIN}/${environment.API_REF}/transactions/cpay_fail`;
        this.cpay.CheckSum = this.generateChecksum();
        this.cpay.CheckSumHeader = this.generateChecksumHeader();
    }

    generateChecksum(): string {
        let value =
            '08AmountToPay,PayToMerchant,MerchantName,AmountCurrency,Details1,Details2,PaymentOKURL,PaymentFailURL,';
        value = `${value}${this.countChecksum()}${this.cpay.AmountToPay}${
            this.cpay.PayToMerchant
        }${this.cpay.MerchantName}`;
        value = `${value}${this.cpay.AmountCurrency}${this.cpay.Details1}${this.cpay.Details2}${this.cpay.PaymentOKURL}`;
        value = `${value}${this.cpay.PaymentFailURL}UWhATwcjPHRMrB4DFU89wqTBDVmbX2KA`;
        return CryptoJS.MD5(value).toString().toUpperCase();
    }
    x;
    generateChecksumHeader() {
        return `08AmountToPay,PayToMerchant,MerchantName,AmountCurrency,Details1,Details2,PaymentOKURL,PaymentFailURL,${this.countChecksum()}`;
    }

    countChecksum() {
        const lengthPaymentOKURL = this.cpay.PaymentOKURL.length
            .toString()
            .padStart(3, '0');
        const lengthPaymentFailURL = this.cpay.PaymentFailURL.length
            .toString()
            .padStart(3, '0');
        const lengthAmountToPay = this.cpay.AmountToPay.toString()
            .length.toString()
            .padStart(3, '0');
        const lengthAmountCurrency = this.cpay.AmountCurrency.length
            .toString()
            .padStart(3, '0');
        const lengthPayToMerchant = this.cpay.PayToMerchant.length
            .toString()
            .padStart(3, '0');
        const lengthDetails1 = this.cpay.Details1.length
            .toString()
            .padStart(3, '0');
        const lengthDetails2 = this.cpay.Details2.length
            .toString()
            .padStart(3, '0');
        const lengthMerchantName = this.cpay.MerchantName.length
            .toString()
            .padStart(3, '0');
        return `${lengthAmountToPay}${lengthPayToMerchant}${lengthMerchantName}${lengthAmountCurrency}${lengthDetails1}${lengthDetails2}${lengthPaymentOKURL}${lengthPaymentFailURL}`;
    }

    onSelectAmount(option: any) {
        if (option) {
            this.customValue = option.value === null;
            this.form.controls.amount.setValue(option.value);
        }
    }

    get disableOneTime() {
        return (
            !this.authService.isAuthenticated &&
            (!this.form.controls.firstName.value ||
                !this.form.controls.lastName.value ||
                !this.form.controls.email.value)
        );
    }

    showTerms() {
        const translatedHeader = this.translate.instant(
            'footer.termsConditions'
        );
        this.dialogService.open(TermsAndConditionsComponent, {
            header: translatedHeader,
            width: '700px',
        });
    }

    donateLocally(transactionId: any) {
        return firstValueFrom(
            this.transactionService.donateLocally(transactionId)
        ).then(
            () => {
                return Promise.resolve(true);
            },
            (error) => {
                return Promise.resolve(false);
            }
        );
    }
}
