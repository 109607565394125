<p-fileUpload #fileUploader name="file[]" (onUpload)="handleFileUpload($event)" [hidden]="true"
              [customUpload]="true" [accept]="'image/*'"
              (onSelect)="handleFileUpload($event)" [showUploadButton]="false"
              [showCancelButton]="false" [auto]="true" class="w-full">
</p-fileUpload>

<p-panel styleClass="mt-0 mb-0 {{hasDocs}} noPanelPadding">
    <ng-template pTemplate="header">
        <div style="width:22rem; cursor:copy;"
             (click)="fileUploader.advancedFileInput.nativeElement.click()">
            <i class="pi pi-upload text-xl" [style.color]="hasImages ? 'white' : 'black'"></i>
            <span class="text-lg ml-3">{{'msg.addImages'|translate}}</span>
        </div>
    </ng-template>

    <div class="text-center" *ngIf="form.controls[imagesControlName].value?.length === 0">
        <div class="flex align-items-center justify-content-center">
            <img [src]="'/assets/opop/NoLogo_1.png'" [alt]="" style="max-width: 100%;"/>
        </div>
    </div>

    <a [routerLink]="'/img-view/' + form.controls.imageId.value" target="_blank">
        <div class="opop-image-container cursor-pointer" *ngIf="form.controls[imagesControlName].value?.length > 0">
            <img style="height:auto"
                 [src]="'/' + apiRef + '/documents/' + form.controls.imageId.value + '/image'" alt="Image"/>
        </div>
    </a>


    <div class="h-6rem">
        <p-table [value]="form.controls[imagesControlName].value" [scrollable]="true" [rows]="3"
                 scrollHeight="90px"
                 styleClass="p-datatable-sm h-4rem">
            <ng-template pTemplate="body" let-doc>
                <tr>
                    <td class="w-5rem">
                        <i class="pi pi-bookmark-fill" *ngIf="doc.id === form.controls.imageId.value"></i>
                        <i class="pi pi-bookmark" *ngIf="doc.id !== form.controls.imageId.value"
                           (click)="makeMainImage(doc)"></i>
                    </td>
                    <td>{{ doc.fileName }}</td>
                    <td class="text-right" style="min-width: 80px">
                        <i class="icon-hover pi pi-trash text-red-500 mr-2" (click)="confirmDelete(doc)"></i>
                        <i class="icon-hover pi pi-download text-green-500"
                           (click)="downloadDocument(doc.id, doc.fileName)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">{{'entity.project.elementTitle.projectNoImagesFound' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-panel>


