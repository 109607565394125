import { Component,Output, EventEmitter, OnInit, OnDestroy,HostListener} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import * as FileSaver from 'file-saver';
import { LanguageService } from '../components/generics/language.service';
import { Subscription } from 'rxjs';
import { Language } from '../components/generics/language.service';


@Component({
    templateUrl:'privacy-policy.component.html'
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
    isDesktop: boolean = true;
    language: Language;
    private langSubscription: Subscription;

    @Output() accept = new EventEmitter<void>();
    @Output() decline = new EventEmitter<void>();
    constructor(private http: HttpClient,
                private dialogRef: DynamicDialogRef,
                private languageService: LanguageService) {
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    ngOnInit(): void {
        this.langSubscription = this.languageService.getSelectedLanguage().subscribe(lang => {
            this.language = lang;
        });
    }

    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }
    onAccept() {
        this.accept.emit();
        this.dialogRef.close('accept');
    }

    onDecline() {
        this.decline.emit();
        this.dialogRef.close('decline');
    }
}

