// import {Component} from '@angular/core';
//
// @Component({
//     templateUrl:'terms-faq.component.html'
// })
// export class TermsFAQComponent {
//
// }


import {Component,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { LanguageService } from '../components/generics/language.service';
import { Language } from '../components/generics/language.service';


@Component({
    templateUrl:'terms-faq.component.html'
})

export class TermsFAQComponent implements OnInit, OnDestroy {
    language: Language;
    private langSubscription: Subscription;

    @Output() accept = new EventEmitter<void>();
    @Output() decline = new EventEmitter<void>();
    constructor(private http: HttpClient,
                private dialogRef: DynamicDialogRef,
                private languageService: LanguageService) {}

    downloadTermsAndConditions() {
        const languageCode = this.language.code.toUpperCase();
        const url = `assets/wordTemplates/FAQ_${languageCode}.pdf`;
        const outName = `FAQ_${languageCode}.pdf`;

        this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
            FileSaver.saveAs(blob, outName);
        });
    }
    ngOnInit(): void {
        this.langSubscription = this.languageService.getSelectedLanguage().subscribe(lang => {
            this.language = lang;
        });
    }

    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }
    onAccept() {
        this.accept.emit();
        this.dialogRef.close('accept');
    }

    onDecline() {
        this.decline.emit();
        this.dialogRef.close('decline');
    }
}

