import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Image} from "../../model/image";

@Component({
    template: `
        <form (keydown.enter)="$event.preventDefault()"  [formGroup]="form" *ngIf="form">
            <div id="div-container">
                <p-galleria [value]="images" [responsiveOptions]="responsiveOptions" containerClass="galleryBorder"
                            [containerStyle]="{'padding': imagePadding}"
                            [showThumbnails]="false" [autoPlay]="true"
                            [numVisible]="3" [circular]="true" [showIndicators]="true" [showItemNavigators]="true">
                    <ng-template pTemplate="item" let-item>
                        <img id="img-class" alt="" [src]="getImage(item)" style="max-height: 300px; max-width:100%;"/>
                    </ng-template>
                </p-galleria>
            </div>
        </form>
    `,
    selector: 'app-image-gallery',
    styles: [
        `.galleryBorder {
          border: 1px solid var(--gray-600);
          -moz-border-radius: 8px;
          -webkit-border-radius: 8px;
          height: 420px;
          align-content: center !important;
            vert-align: middle;
        }`],
    encapsulation: ViewEncapsulation.None
})
export class ImageGalleryComponent implements OnInit {
    @Input()
    form: FormGroup
    responsiveOptions: any;

    constructor() {
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
    }

    ngOnInit(): void {
    }

    get images() {
        return this.form.controls.images?.value?.length > 0 ? this.form.controls.images.value
            : [{
                imageUrl: 'assets/opop/NoLogo_1.png',
                thumbUrl: 'assets/opop/no-img-thumbnail.png',
                caption: 'No image available'
            } as Image]
    }

    getImage(item: Image) {
        return (item.caption === 'No image available' ? '' : '/api') + item?.imageUrl;
    }

    getThumbnail(item: Image) {
        return (item.caption === 'No image available' ? '' : '/api') + item?.thumbUrl;
    }

    get imagePadding(){
        return this.form?.controls.images?.value?.length > 0 ? '40px' : '110px';
    }
}
