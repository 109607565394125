
<div style="text-align: center;">
    <i *ngIf="justIcon"
      class="pi pi-download"
       style="cursor: pointer; margin-top: 4px;
       font-size: 14px; color: var(--gray-600); border:0;"
       (click)="!isUploadDisabled && processDocument()"
       title="Download"></i>

    <button *ngIf="!justIcon" pButton
            class="p-5 w-full"
            style="margin:0; margin-bottom: 10px;  min-width:48%;!important; max-width:500px !important; font-size: 15px; border-radius: 4px;
            font-family: 'Roboto Condensed', sans-serif;"
            (click)="!isUploadDisabled && processDocument()"
            [disabled]="isUploadDisabled"
            [ngStyle]="{'color': isUploadDisabled?'black':'white','height':'26px'}"
            [label]="title">
        <i class="fas fa-download" style="margin: 0 8px 0 0; font-size: 15px;"></i>
    </button>
</div>
