import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ChatService} from "./chat.service";
import {AuthService} from "../../support/auth.service";
import {Role} from "../../enums/role";
import {ChatMessage, ChatType} from "./chat-message";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
    template: `
        <div class="mb-1" *ngIf="!authService.hasAnyAuthority(authService.chatAdminRoles)">
            <p-tag *ngIf="chatService.isConnected$ | async" severity="success" value="Connected"></p-tag>
            <p-tag *ngIf="!(chatService.isConnected$ | async)" severity="danger" value="Disconnected"></p-tag>
        </div>
        <div class="flex flex-column md:flex-row gap-5" style="min-height: 81vh">
            <div class="md:w-25rem card p-0" *ngIf="authService.hasAnyAuthority(authService.chatAdminRoles)">
                <div class="flex flex-column align-items-center border-bottom-1 surface-border p-6">
                    <img src="assets/opop/user-avatar.png" class="w-6rem h-6rem border-circle shadow-4" alt="avatar"/>
                    <span class="text-900 text-xl font-semibold mt-4">{{authService.userDetails?.name}}</span>

                    <div class="mt-2">
                        <p-tag *ngIf="chatService.isConnected$ | async" severity="success" value="Connected"></p-tag>
                        <p-tag *ngIf="!(chatService.isConnected$ | async)" severity="danger" value="Disconnected"></p-tag>
                    </div>
                </div>
                <div class="w-full flex row-gap-4 flex-column surface-border p-4">
                    <div class="flex flex-row gap-4 md:flex-column overflow-auto">

                        <div *ngIf="(Object.keys((chatService.availableTopics$ | async) ?? [])).length === 0">
                            No message(s)
                        </div>

                        <!--                User card-->
                        <div *ngFor="let topic of (chatService.availableTopics$ | async)"
                             class="flex flex-nowrap justify-content-between align-items-center border-1 surface-border border-round p-3 cursor-pointer select-none hover:surface-hover transition-colors transition-duration-150"
                             (keydown.enter)="changeView(topic)" (click)="changeView(topic)" tabindex="0">
                            <div class="flex align-items-center">
                                <div class="relative md:mr-3">
                                    <img src="assets/opop/user-avatar.png" alt="user"
                                         class="w-3rem h-3rem border-circle shadow-4"/>
                                </div>
                                <div class="flex-column hidden md:flex">
                                    <span class="text-900 font-semibold block">{{ topic }}</span>
                                    <!--                    <span-->
                                    <!--                        class="block text-600 text-overflow-ellipsis overflow-hidden white-space-nowrap w-10rem text-sm">todo last message</span>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 card p-0" *ngIf="topic">
                <app-chat-box [topic]="topic"></app-chat-box>
            </div>
        </div>

    `
})
export class ChatAppComponent implements OnDestroy, OnInit {
    protected readonly Role = Role;
    protected readonly Object = Object;
    topic: string;
    topics$: Observable<any[]>;

    constructor(public chatService: ChatService, public authService: AuthService, private msgService: MessageService,
                private translate: TranslateService) {
    }

    ngOnDestroy() {
        this.chatService.disconnect().then(() => {
            this.msgService.add({
                severity: 'info', life: 7000,
                summary: this.translate.instant('info'),
                detail: this.translate.instant('msg.chatSessionClosed')
            });
        });
    }

    async ngOnInit(): Promise<void> {
        await this.chatService.connect();

        // Create topic if it is not administrator
        if (!this.authService.hasAnyAuthority(this.authService.chatAdminRoles)) {
            this.topic = `${this.chatService.username}`;
            const chatMessage = {
                topic: this.topic,
                sender: this.chatService.username,
                content: ChatType.JOIN,
                timestamp: new Date(),
                type: ChatType.JOIN
            } as ChatMessage;
            this.chatService.addUser(chatMessage);
            this.chatService.getHistory(this.topic)
            this.chatService.subscribeToTopic(this.topic)
        }
    }

    changeView(topic: any) {
        this.topic = topic;
        const chatMessage = {
            topic: this.topic,
            sender: this.chatService.username,
            content: ChatType.JOIN,
            timestamp: new Date(),
            type: ChatType.JOIN
        } as ChatMessage;
        this.chatService.addUser(chatMessage);
        this.chatService.subscribeToTopic(topic);
        this.chatService.getHistory(topic);
    }
}
