<div class="layout-sidebar"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.sidebar-active]="layoutService.state.sidebarActive"
>
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo cursor-pointer">
            <img [src]="'assets/opop/logo.png'" class="login-logo" style="width: 80px;"/>
        </a>
        <button class="layout-sidebar-anchor p-link"
                type="button"
                (click)="anchor()">
        </button>
    </div>

    <div #menuContainer class="layout-menu-container">
        <app-menu (menuItemClicked)="onMenuItemClick()"></app-menu>
    </div>
</div>

