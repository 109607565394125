import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericApiService} from "../components/generics/generic-api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Project, toCreateFields, toUpdateFields} from "../model/project";
import {environment} from "../../environments/environment";

@Injectable()
export class ProjectService extends GenericApiService<Project> {
    override serviceUrl = `/${environment.API_REF}/projects`;

    constructor(protected override http: HttpClient) {
        super(http);
    }


    public create(item: Project): Observable<Project> {
        return this.http.post<Project>(`${this.serviceUrl}/create`, toCreateFields(item))
            .pipe(map(res => this.parse(res)));
    }


    public update(item: Project): Observable<Project> {
        return this.http.put<Project>(`${this.serviceUrl}/`, toUpdateFields(item))
            .pipe(map(res => this.parse(res)));
    }

    public updateStatus(projectStatusUpdate: any): Observable<void> {
        return this.http.put<Project>(`${this.serviceUrl}/update_status`, projectStatusUpdate)
            .pipe(map(res => this.parse(res)));
    }

    public getProjectsByOwner(ownerId: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.serviceUrl}/${ownerId}/by_owner`)
            .pipe(map(res => this.parse(res)))
    }

    public getById(id: string): Observable<Project> {
        return this.http.get<Project>(`${this.serviceUrl}/${id}`)
            .pipe(map(res => this.parse(res)))
    }

    public getAllProjects(): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.serviceUrl}/`)
            .pipe(map(res => this.parse(res)))
    }

    public markAsPaidOut(projectId: string): Observable<any> {
        return this.http.put<any>(
            `${this.serviceUrl}/${projectId}/paid_out`,
            {} // Empty body as per new API spec
        ).pipe(map(res => this.parse(res)));
    }

    public markAsCanceled(projectId: string): Observable<any> {
        return this.http.put<any>(
            `${this.serviceUrl}/${projectId}/cancel`,
            {} // Empty body as per new API spec
        ).pipe(map(res => this.parse(res)));
    }
}
