import {Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { MenuService } from './app.menu.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { LayoutService } from './service/app.layout.service';
import { LocalStorageService} from '../services/local-storage.service';

@Component({
    selector: 'app-layout',
    templateUrl: './app.layout.component.html'
})
export class AppLayoutComponent implements OnInit, OnDestroy   {
    showCookieDialog = true;
    overlayMenuOpenSubscription: Subscription;
    menuOutsideClickListener: any;
    menuScrollListener: any;
    isDesktop: boolean = true;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    @ViewChild(AppTopbarComponent) appTopbar!: AppTopbarComponent;

    constructor(private menuService: MenuService,
                private localStorageService: LocalStorageService,
                public layoutService: LayoutService,
                public renderer: Renderer2,
                public router: Router) {
        this.onResize();
        this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
            if (!this.menuOutsideClickListener) {

                this.menuOutsideClickListener = this.renderer.listen('document', 'click', (event) => {

                    const isOutsideClicked = !(
                         this.appTopbar?.el?.nativeElement.isSameNode(event.target)
                         ||
                         this.appTopbar?.el?.nativeElement.contains(event.target)
                         ||
                         this.appTopbar?.menuButton?.nativeElement.isSameNode(event.target)
                         ||
                         this.appTopbar?.menuButton?.nativeElement.contains(event.target)
                    );

                    if (isOutsideClicked) {
                        this.hideMenu();
                    }
                });
            }

            if ((this.layoutService.isHorizontal() ||
                this.layoutService.isSlim()|| this.layoutService.isSlimPlus()) && !this.menuScrollListener) {
                this.menuScrollListener = this.renderer.listen(this.appTopbar.appSidebar.menuContainer.nativeElement, 'scroll', event => {
                    if (this.layoutService.isDesktop()) {
                        this.hideMenu();
                    }
                });
            }


            if (this.layoutService.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            }
        });

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.hideMenu();
        });
    }

    getStyles() {
        if (this.isDesktop) {
            return {
                'margin-left': '0rem',
                'margin-right': '0rem',
                'margin-top': '0px',
                'padding': '0rem',
                'padding-left': '5rem',
                'padding-right': '5rem',
                'background': 'white'
            };
        } else {
            return {
                'margin-left': '0rem',
                'margin-right': '0rem',
                'margin-top': '0px',
                'padding': '0',
                'background': 'white'
            };
        }
    }

    getLogoStyle() {
        if (this.isDesktop) {
          return {
            position: 'absolute',
            top:  '28rem',
            left: '6rem',
            width: '24px',
            height: 'auto',
            textAlign: 'center',
            display: 'inline-block',
            cursor: 'pointer',
            zIndex: 3
          };
        } else {
          return {
            position: 'absolute',
            top:  '28rem',
            left: '2rem',
            width: '24px', // Adjust width for small logo
            height: 'auto',
            textAlign: 'center',
            display: 'inline-block',
            cursor: 'pointer',
            zIndex: 3
          };
        }
      }


    isRootRoute() {
        return this.router.url === '/';
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 768;
    }
    ngOnInit() {
        const consent = this.localStorageService.getConsent();
        this.showCookieDialog = consent === null;

    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    hideMenu() {
        this.layoutService.state.overlayMenuActive = false;
        this.layoutService.state.staticMenuMobileActive = false;
        this.layoutService.state.menuHoverActive = false;
        this.menuService.reset();
        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
            this.menuOutsideClickListener = null;
        }
        if (this.menuScrollListener) {
            this.menuScrollListener();
            this.menuScrollListener = null;
        }
        this.unblockBodyScroll();
    }

    get containerClass() {
        return {
            'layout-light': this.layoutService.config.colorScheme === 'light',
            'layout-dark': this.layoutService.config.colorScheme === 'dark',
            'layout-light-menu': this.layoutService.config.menuTheme === 'light',
            'layout-dark-menu': this.layoutService.config.menuTheme === 'dark',
            'layout-light-topbar': this.layoutService.config.topbarTheme === 'light',
            'layout-dark-topbar': this.layoutService.config.topbarTheme === 'dark',
            'layout-transparent-topbar': this.layoutService.config.topbarTheme === 'transparent',
            'layout-overlay': this.layoutService.config.menuMode === 'overlay',
            'layout-static': this.layoutService.config.menuMode === 'static',
            'layout-slim': this.layoutService.config.menuMode === 'slim',
            'layout-slim-plus': this.layoutService.config.menuMode === 'slim-plus',
            'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
            'layout-reveal': this.layoutService.config.menuMode === 'reveal',
            'layout-drawer': this.layoutService.config.menuMode === 'drawer',
            'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
            'layout-overlay-active': this.layoutService.state.overlayMenuActive,
            'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
            'p-ripple-disabled': !this.layoutService.config.ripple,
            'layout-sidebar-active': this.layoutService.state.sidebarActive,
            'layout-sidebar-anchored': this.layoutService.state.anchored
        };
    }

    ngOnDestroy() {
        // if (this.overlayMenuOpenSubscription) {
        //     this.overlayMenuOpenSubscription.unsubscribe();
        // }
        //
        // if (this.menuOutsideClickListener) {
        //     this.menuOutsideClickListener();
        // }
    }
}
