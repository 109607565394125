import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegisterRoutingModule} from './register-routing.module';
import {DonorRegisterIndividualComponent} from './donor/donor-register-individual.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {AppConfigModule} from 'src/app/layout/config/app.config.module';
import {PasswordModule} from 'primeng/password';
import {DonorRegisterStep2Component} from "./donor/donor-register-step2.component";
import {TimerComponent} from "./timer.component";
import {InputMaskModule} from "primeng/inputmask";
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {DonorRegisterStep3Component} from "./donor/donor-register-step3.component";
import {DonorRegisterStep4Component} from "./donor/donor-register-step4.component";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SharedModule} from "../../../components/generics/shared.module";
import {ConfirmationService} from "primeng/api";
import {MunicipalityService} from "../../../services/municipality.service";
import {UserService} from "../../../services/user.service";
import {BeneficiaryRegisterComponent} from "./beneficiary/beneficiary-register.component";
import {BeneficiaryRegisterStep2Component} from "./beneficiary/beneficiary-register-step2.component";
import {BeneficiaryRegisterStep3Component} from "./beneficiary/beneficiary-register-step3.component";
import {BeneficiaryRegisterStep4Component} from "./beneficiary/beneficiary-register-step4.component";
import {AdministratorRegisterComponent} from "./administrator/administrator-register.component";
import {BeneficiaryRegisterStep5Component} from "./beneficiary/beneficiary-register-step5.component";
import {BeneficiaryRegisterStep6Component} from "./beneficiary/beneficiary-register-step6.component";
import {DonorService} from "../../../services/donor.service";
import {SelectButtonModule} from "primeng/selectbutton";
import {CalendarModule} from "primeng/calendar";
import {BeneficiaryService} from "../../../services/beneficiary.service";
import {FileUploadModule} from "primeng/fileupload";
import {DocumentService} from "../../../services/document.service";
import {StepsModule} from "primeng/steps";
import {BankService} from "../../../services/bank.service";
import {AutoCompleteModule} from "primeng/autocomplete";
import {CompanyCategoryService} from "../../../services/company-category.service";
import {DonorRegisterOrganizationComponent} from "./donor/donor-register-organization.component";
import {DonorRegisterStep1Component} from "./donor/donor-register-step1.component";
import {DonorRegisterOrganizationBankDetailsComponent} from "./donor/donor-organization-bank-details.component";
import {BeneficiaryRegisterStep2aComponent} from "./beneficiary/beneficiary-register-step2a.component";
import {DonorRegisterStep2aComponent} from "./donor/donor-register-step2a.component";
import {TermsAndConditionsComponent} from "../../../footer/terms-conditions.component";
import {PrivacyPolicyComponent} from "../../../footer/privacy-policy.component";
import {TermsFAQComponent} from "../../../footer/terms-faq.component";
import {DropdownModule} from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';


@NgModule({
    imports: [ DialogModule,ToastModule, DropdownModule, CommonModule, RegisterRoutingModule, FormsModule, ButtonModule, RippleModule, InputTextModule,
        CheckboxModule, AppConfigModule, PasswordModule, ReactiveFormsModule, InputMaskModule, TranslateModule, ConfirmDialogModule,
        SharedModule, SelectButtonModule, CalendarModule, FileUploadModule, StepsModule, AutoCompleteModule],

    declarations: [
        TermsAndConditionsComponent,
        DonorRegisterIndividualComponent, DonorRegisterStep1Component, DonorRegisterStep2Component, DonorRegisterStep2aComponent,
        DonorRegisterStep3Component, DonorRegisterStep4Component, TimerComponent, BeneficiaryRegisterComponent, BeneficiaryRegisterStep2Component,
        BeneficiaryRegisterStep3Component, BeneficiaryRegisterStep5Component,
        BeneficiaryRegisterStep6Component, BeneficiaryRegisterStep4Component,
        AdministratorRegisterComponent, DonorRegisterOrganizationComponent, DonorRegisterIndividualComponent,
        DonorRegisterOrganizationBankDetailsComponent, BeneficiaryRegisterStep2aComponent, TermsAndConditionsComponent, PrivacyPolicyComponent, TermsFAQComponent],
    providers: [UserService, DonorService, BeneficiaryService, MunicipalityService, ConfirmationService, DocumentService,
        BankService, MunicipalityService, CompanyCategoryService]
})
export class RegisterModule {
}
