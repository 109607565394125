<!--Donor Registrate Individual Component / HTML -->

<div class="surface-0" style="top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="surface-0 pt-4 pl-6 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-2">
                <img [src]="'assets/opop/logo.png'" class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div *ngIf="isDesktop">
            <div class="surface-0" style="margin-left: 15%; margin-right: 15%; margin-top:0%; margin-bottom:0%;">
                <p-steps [model]="items" [readonly]="true"></p-steps>
            </div>


            <div class="flex align-items-center py-8 justify-content-between flex-column h-screen">
                <div class="flex flex-column w-full lg:w-8 md:w-10 h-full px-0 align-items-center ">

                    <ng-container formGroupName="person">
                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="firstName">{{'entity.person.personFirstName' | translate}} </label>
                            </span>
                            <input id="firstName" formControlName="firstName" type="text" pInputText  autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="lastName">{{'entity.person.personLastName' | translate}}</label>
                            </span>
                            <input id="lastName" formControlName="lastName" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-phone mr-2"></i>
                                <label for="phone">{{'login.phone' | translate}}</label>
                            </span>
                            <input id="phone"
                                   formControlName="phone"
                                   type="text"
                                   pInputText
                                   autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-at mr-2"></i>
                                <label for="email">{{'login.email' | translate}}</label>
                            </span>
                            <input id="email" formControlName="email" type="text" pInputText autocomplete="off" (input)="onEmailInput()"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>
                        <div *ngIf="emailExists" class="flex text-red-700 mb-3 md:text-l">{{'msg.emailUsed' | translate}}</div>


                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 30px;" >
                                <i class="pi pi-shield mr-2"></i>
                                    <label for="companyCountry">{{'entity.person.country' | translate}}</label>
                            </span>
                            <p-autoComplete formControlName="country"
                                            id="companyCountry"
                                            [inputStyle]="{'border-radius':'0'}"
                                            [style]="{'width': '100%', 'height':'30px'}"
                                            field="name" class="p-fluid"
                                            [appendTo]="'body'" [suggestions]="countrySuggestions"
                                            (completeMethod)="completeCountry($event)"
                                            [dropdown]="true" [forceSelection]="true">
                            </p-autoComplete>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-map-marker mr-2"></i>
                                <label for="streetAddress">{{'entity.person.personAddress' | translate}}</label>
                            </span>
                            <input id="streetAddress" formControlName="streetAddress" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-map-marker mr-2"></i>
                                <label for="city">{{'entity.person.personCity' | translate}}</label>
                            </span>
                            <input id="city" formControlName="city" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start">
                                <i class="pi pi-map-marker mr-2"></i>
                                <label for="zip">{{'entity.person.personZip' | translate}}</label>
                            </span>
                            <input id="zip" formControlName="zip" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-0 mb-2" style="width: 60%">
                            <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 37px;" >
                                <i class="pi pi-user mr-2"></i>
                                    <label for="userAnonymous">{{'entity.person.anonymous' | translate}}</label>
                            </span>
                            <form [formGroup]="form">
                                <div style="display: flex; align-items: start;">
                                    <p-checkbox
                                        id="userAnonymous"
                                        [style]="{'width': '100%','margin-left': '10px', 'margin-top': '10px'}"
                                        formControlName="anonymous"
                                        binary="true">
                                    </p-checkbox>
                                    <label for="userAnonymous"
                                           style=" color:darkgrey;margin-left:10px; height:37px; margin-top: 4px;">
                                        {{'entity.person.anonymousDesc' | translate}}
                                    </label>
                                </div>
                            </form>
                        </div>
                    </ng-container>


                    <button type="submit" pButton pRipple label="{{'btn.next' | translate}}" class="mb-4 mt-5" icon="pi pi-arrow-right"
                            [disabled]="form.invalid || emailExists" [loading]="loading"
                            style="width: 60%"></button>
                </div>
            </div>
        </div>




        <div *ngIf="!isDesktop">
            <div class="flex align-items-center py-3 justify-content-between flex-column h-screen">
                <div class="flex flex-column w-full lg:w-8 md:w-10 h-full px-0 align-items-center ">
                    <ng-container formGroupName="person">

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:1px;">
                            <label for="firstName2">{{'entity.person.personFirstName' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-user"></i>
                                <input pInputText id="firstName2" formControlName="firstName" class="p-inputtext full-width"/>
                            </span>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:1px;">
                            <label for="lastName1">{{'entity.person.personLastName' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-user"></i>
                                <input pInputText id="lastName1" formControlName="lastName" class="p-inputtext full-width"/>
                            </span>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:1px;">
                            <label for="phone1">{{'login.phone' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-phone"></i>
                                <input pInputText id="phone1" formControlName="phone" class="p-inputtext full-width"/>
                            </span>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:5px;">
                            <label for="email1">{{'login.email' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-at"></i>
                                <input pInputText id="email1" formControlName="email" class="p-inputtext full-width" (input)="onEmailInput()"/>
                            </span>
                        </div>
                        <small *ngIf="emailExists"  id="email1-help">{{'msg.emailUsed' | translate}}</small>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:5px;">
                            <label>{{'entity.person.country' | translate}}</label>
                            <p-autoComplete formControlName="country"
                                            class="country-autoComplete p-fluid full-width"
                                            [dropdown]="true"
                                            [suggestions]="countrySuggestions"
                                            (completeMethod)="completeCountry($event)"
                                            field="name">
                            </p-autoComplete>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:5px;">
                            <label for="streetAddress1">{{'entity.person.personAddress' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-map-marker"></i>
                                <input pInputText id="streetAddress1" formControlName="streetAddress" class="p-inputtext full-width"/>
                            </span>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:5px;">
                            <label for="city1">{{'entity.person.personCity' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-map-marker"></i>
                                <input pInputText id="city1" formControlName="city" class="p-inputtext full-width"/>
                            </span>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:5px;">
                            <label for="zip1">{{'entity.person.personZip' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="pi pi-map-marker"></i>
                                <input pInputText id="zip1" formControlName="zip" class="p-inputtext full-width"/>
                            </span>
                        </div>

                        <div class="flex flex-column gap-1" style="width:70%; margin:10px; margin-bottom:5px;">
                            <span class="p-inputgroup-addon w-16rem justify-content-start " style="height: 37px;" >
                                <i class="pi pi-user mr-2"></i>
                                    <label for="userAnonymous">Anonymous</label>
                            </span>
                            <form [formGroup]="form">
                                <p-checkbox
                                    id="anonymous"
                                    [style]="{'width': '80%','margin-left': '10px', 'margin-top': '10px'}"
                                    formControlName="anonymous"
                                    binary="false">
                                </p-checkbox>

                            </form>
                        </div>
                    </ng-container>


                    <button type="submit" pButton pRipple label="{{'btn.next' | translate}}" class="mb-4 mt-5" icon="pi pi-arrow-right"
                            [disabled]="form.invalid || emailExists" [loading]="loading"
                            style="width: 60%"></button>
                </div>
            </div>
        </div>
    </form>
    <app-config [minimal]="true"></app-config>
</div>
