import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";
import {BeneficiaryCategory} from "../model/beneficiary-category";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})

export class BeneficiaryCategoryService extends GenericApiService<BeneficiaryCategory> {
    override serviceUrl = `/${environment.API_REF}/beneficiary_categories`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public filter(query: string): Observable<BeneficiaryCategory[]> {
        return this.http.get<BeneficiaryCategory[]>(`${this.serviceUrl}/autocomplete?name=${query}`)
            .pipe(map(res => this.parse(res)));
    }
}
