import {Injectable} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';


const dateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?(([+\-])\d{2}:\d{2})?$/;
const dateFormat = /^\d{4}-\d{2}-\d{2}$/;


@Injectable()
export class GenericApiService<T> {
    public serviceUrl: string;

    constructor(protected http: HttpClient) {
    }

    // tslint:disable-next-line:no-shadowed-variable
    public parse<T>(object: any): T {
        return JSON.parse(JSON.stringify(object), this.reviver) as T;
    }

    // https://mariusschulz.com/blog/deserializing-json-strings-as-javascript-date-objects
    public reviver(key, value) {
        if (typeof value === 'string' && (dateFormat.test(value) || dateTimeFormat.test(value))) {
            return new Date(value);
        }
        return value;
    }

    protected getHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        // You can add more headers if needed.
        headers = headers.set('Accept-Language', 'mk');  // Here 'mk' is hardcoded. You'll probably want this dynamic based on user selection.

        return headers;
    }
}
