<form #emailFormID [formGroup]="vimanForm">

    <div class="virman-main-container">
        <div class="virman-container"  style="background-color: var(--green-100);">
            <div class="grid" id="virmanId">
                <div class="col-12 md:col-6">
                    <div class="flex flex-column gap-1 text-lg" style="width:100%; margin:10px; margin-bottom:20px;font-weight: bold;">
                        {{'pledge.pledgeTitle' | translate}}
                    </div>
                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:0;">
                        <label for="donorName">{{'pledge.donorName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="donorName" formControlName="DonorName" class="p-inputtext full-width"
                                   style="border-radius: 6px 6px 0 0;"/>
                        </span>
                    </div>
                    <div class="flex flex-column gap-1 text-sm"
                         style="width:100%; margin:10px; margin-top:-2px; margin-bottom:20px;">
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-map"></i>
                            <input pInputText id="donorAddress" formControlName="DonorAddress" class="p-inputtext full-width"
                                   style="border-radius: 0 0 6px 6px;  border-top:1px dashed grey;  "/>
                        </span>
                    </div>

                    <!--                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:10px;">-->
                    <!--                        <label for="donorAddress">{{'pledge.donorAddress' | translate}}</label>-->
                    <!--                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">-->
                    <!--                            <i class="pi pi-tag"></i>-->
                    <!--                            <input pInputText id="donorAddress" formControlName="DonorAddress" class="p-inputtext full-width style="border-radius: 0"/>-->
                    <!--                        </span>-->
                    <!--                    </div>-->

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="DonorBankAccNr">{{'pledge.donorAccountNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="DonorBankAccNr" formControlName="DonorBankAccNr" class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="DonorBankName1">{{'pledge.donorBankName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="DonorBankName1" formControlName="DonorBankName" class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="PurposeText">{{'pledge.purposeOfDonation' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                             <input pInputText id="PurposeText" formControlName="PurposeText" class="p-inputtext full-width" style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="PurposeText">{{'pledge.donorReferenceNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                             <input pInputText id="DonorID" formControlName="DonorID" class="p-inputtext full-width" style="border-radius:6px"/>
                        </span>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="flex flex-column gap-1 text-lg"
                         style="width:100%; margin:10px; margin-bottom:20px; font-weight: bold;">
                        {{'pledge.pledgeSubTitle' | translate}}
                    </div>
                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:0;">
                        <label for="beneficiaryName">{{'pledge.beneficiaryName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="beneficiaryName" formControlName="BeneficiaryName" class="p-inputtext full-width"
                                   style="border-radius:6px 6px 0 0 "/>
                        </span>
                    </div>
                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-top:-2px; margin-bottom:20px;">
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="beneficiaryAddress" formControlName="BeneficiaryAddress" class="p-inputtext full-width"
                                   style="border-radius:0 0 6px 6px; border-top:1px dashed grey;"/>
                        </span>
                    </div>
                    <!--                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:10px;">-->
                    <!--                        <label for="beneficiaryAddress">{{'pledge.beneficiaryAddress' | translate}}</label>-->
                    <!--                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">-->
                    <!--                            <i class="pi pi-tag"></i>-->
                    <!--                            <input pInputText id="beneficiaryAddress" formControlName="BeneficiaryAddress" class="p-inputtext full-width" style="border-radius: 0"/>-->
                    <!--                        </span>-->
                    <!--                    </div>-->

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="beneficiaryBankAccNr">{{'pledge.beneficiaryAccountNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="BeneficiaryBankAccNr" formControlName="BeneficiaryBankAccNr" class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="BeneficiaryBankName">{{'pledge.beneficiaryBankName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="BeneficiaryBankName" formControlName="BeneficiaryBankName" class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="Amount">{{'pledge.amount' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input pInputText id="Amount" formControlName="Amount" class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="BeneficiaryRefNr">{{'pledge.beneficiaryReferenceNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                             <input pInputText id="BeneficiaryRefNr" formControlName="BeneficiaryID" class="p-inputtext full-width" style="border-radius: 0"/>
                        </span>
                    </div>

                </div>

                <div class="col-12">
                    <!--                    <div class="horizontal-line" style="margin-bottom:10px; margin-top:10px;"></div>-->
                    <div class="button-container">
                        <button type="button" class="mt-1 p-button" pButton icon="pi pi-times-circle" style="max-width: 120px; border-radius:6px;"
                                (click)="close()" label="Close">
                        </button>
                        <button type="button" class="mt-1 p-button" pButton icon="pi pi-download" style="max-width: 120px; border-radius:6px;"
                                (click)="makePDF()" label="Make PDF">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

