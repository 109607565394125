import {Component, HostListener} from '@angular/core';
import {LocalStorageService } from '../services/local-storage.service';
import {Language, LanguageService} from '../components/generics/language.service';
import {HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html'
})
export class CookieConsentComponent {
    showCookieDialog: boolean;
    isDesktop: boolean = true;
    language: Language;
    private langSubscription: Subscription;
    items = [
        { checked: false, someProperty: 'Value 1' },
        { checked: true, someProperty: 'Value 2' },
        { checked: true, someProperty: 'Value 3' },
        { checked: false, someProperty: 'Value 4' },
        { checked: false, someProperty: 'Value 5' },
        { checked: false, someProperty: 'Value 6' },
    ];
    constructor(private localStorageService: LocalStorageService,
                private http: HttpClient,
                private languageService: LanguageService) {
        this.initializeDialogVisibility();
        this.onResize();
    }

    downloadPrivacyForm() {
        const languageCode = this.language.code.toUpperCase();
        const url = `assets/wordTemplates/CookieForm_v1.0_${languageCode}.pdf`;
        const outName = `CookiePolicy_v1.0_${languageCode}.pdf`;

        this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
            FileSaver.saveAs(blob, outName);
        });
    }

    ngOnInit(): void {
        this.langSubscription = this.languageService.getSelectedLanguage().subscribe(lang => {
            this.language = lang;
        });
    }

    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }
    initializeDialogVisibility() {
        const userResponse = this.localStorageService.getConsent();
        this.showCookieDialog = userResponse === null; // Show dialog only if no response was recorded
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    allowCookies() {
        this.localStorageService.setConsent('allowed');
        this.showCookieDialog = false;
    }

    disallowCookies() {
        this.localStorageService.setConsent('disallowed');
        this.showCookieDialog = false;
    }

    closeDialog() {
        this.showCookieDialog = false;
    }
}
