import {TranslateService} from "@ngx-translate/core";
import {Injector} from "@angular/core";
import {LOCATION_INITIALIZED} from "@angular/common";
import {StorageService} from "./storage.service";
import {firstValueFrom} from "rxjs";
import {AuthService} from "./auth.service";
import {LayoutService} from "../layout/service/app.layout.service";
import {environment} from "../../environments/environment";

export function appInitializerFactory(translate: TranslateService, injector: Injector, authService: AuthService,
                                      storageService: StorageService, layoutService: LayoutService) {
    return () => new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                    const langToSet = localStorage.getItem('language') ?? 'en';
                    translate.setDefaultLang(langToSet);

                    translate.use(langToSet).subscribe({
                        next: () => console.log(`Successfully initialized '${langToSet}' language.'`),
                        error: () => {
                            console.error(`Problem with '${langToSet}' language initialization.'`);
                            resolve(null);
                        },
                        complete: () => resolve(null)
                    });

                    // Initialize logged user data
                    if (localStorage.getItem(environment.LOCAL_STORAGE_TOKEN)) {
                        firstValueFrom(authService.fetchUserDetails())
                            .then(userDetails => {
                                    storageService.setValue(environment.USER_DETAILS, userDetails);
                                },
                                () => localStorage.removeItem(environment.LOCAL_STORAGE_TOKEN)
                            );
                    }

                    layoutService.config.scale = 13;
                    document.documentElement.style.fontSize = layoutService.config.scale + 'px';
                }
            );
        }
    );
}
