// language.interceptor.ts
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Here, you can get the selected language from a service, local storage, etc.
        const selectedLanguage = localStorage.getItem('language') || 'en'; // This is just a placeholder. Replace with the real method to get the selected language.

        const langRequest = request.clone({
            headers: request.headers.set('Accept-Language', selectedLanguage),
        });

        return next.handle(langRequest);
    }
}
