import {TranslateService} from "@ngx-translate/core";

export function beneficiarySteps(translate: TranslateService) {
    return [
        {
            label: translate.instant('entity.beneficiary.steps.companyInformation'),
            routerLink: '/auth/register/beneficiary/step1'
        },
        {
            label: translate.instant('entity.beneficiary.steps.bankDetails'),
            routerLink: '/auth/register/beneficiary/step2'
        },
        {
            label: translate.instant('entity.beneficiary.steps.contactPerson'),
            routerLink: '/auth/register/beneficiary/step2a'
        },
        {
            label: translate.instant('entity.beneficiary.steps.otpConfirmation'),
            routerLink: '/auth/register/beneficiary/step3'
        },
        {
            label: translate.instant('entity.beneficiary.steps.credentials'),
            routerLink: '/auth/register/beneficiary/step4'
        },
        {
            label: translate.instant('entity.beneficiary.steps.uploadStatus'),
            routerLink: '/auth/register/beneficiary/step5'
        },
        {
            label: translate.instant('entity.beneficiary.steps.uploadContract'),
            routerLink: '/auth/register/beneficiary/step6'
        }
    ];
}
