import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, MessageService} from "primeng/api";
import {beneficiarySteps} from "./beneficiary-steps";
import {MunicipalityService} from "../../../../services/municipality.service";
import {Municipality} from "../../../../model/municipality";
import {errorHandler} from "../../../../support/functions";
import {BeneficiaryCategory} from "../../../../model/beneficiary-category";
import {BeneficiaryCategoryService} from "../../../../services/beneficiary-category.service";
import {companyRegisterForm} from "../company-form";
import {DialogService} from "primeng/dynamicdialog";
import {TermsAndConditionsComponent} from "../../../../footer/terms-conditions.component";
import { beneficiaryStatus_items, BeneficiaryStatus } from '../../../../enums/beneficiary-status'; // adjust the import path as needed


import {CompanyCategory} from "../../../../model/company-category";
import {CompanySubcategory} from "../../../../model/company-subcategory";
import {CompanySubcategoryService} from "../../../../services/company-subcategory.service";
import {CompanyCategoryService} from "../../../../services/company-category.service";

// import { SelectItem } from 'primeng/api';
// import {Country} from "../../../../model/country";
@Component({
    templateUrl: './beneficiary-register.component.html'
})
export class BeneficiaryRegisterComponent implements OnInit {
    public form: UntypedFormGroup;
    public beneficiaryCategorySuggestions: BeneficiaryCategory[];
    public selectedBeneficiaryCategory: BeneficiaryCategory;
    items: MenuItem[];
    municipalitySuggestions: Municipality[] = [];
    // companyCategorySuggestions: CompanyCategory[] = [];
    // companySubcategorySuggestions: CompanySubcategory[] = [];
    // selectedCategoryId: number;

    val: any;

    constructor(private router: Router, private translate: TranslateService, private msgService: MessageService,
                public municipalityService: MunicipalityService,
                public companyCategoryService: CompanyCategoryService,
                private companySubcategoryService: CompanySubcategoryService,
                private beneficiaryCategoryService:BeneficiaryCategoryService,
                private dialogService: DialogService) {
        this.items = beneficiarySteps(this.translate);

        this.form = new UntypedFormGroup({
            company: companyRegisterForm(),
            beneficiaryCategory: new UntypedFormControl(null),
            beneficiaryStatus: new UntypedFormControl(BeneficiaryStatus.NEW),
            termsAgreed: new UntypedFormControl(null, Validators.required)
        });

    }

    ngOnInit() {
        // this.form.get('company').get('companyCategory').valueChanges.subscribe(value => {
        //     if (value && value.id) {
        //         this.onCategorySelected(value.id);
        //     }
        // });
    }
    submit() {
        const beneficiaryCategoryValue = this.form.get('beneficiaryCategory').value;
        this.router.navigateByUrl(`auth/register/beneficiary/step2`,
            {state: {beneficiary: this.form.getRawValue()}})
    }

    completeMunicipality(event: any) {
        firstValueFrom(this.municipalityService.filter(event.query)).then(results => {
            this.municipalitySuggestions = results;
        },
            error => errorHandler('Error during auto-complete', error, this.msgService, this.translate, this.router)
        );
    }

    completeBeneficiaryCategory(event: any) {
        firstValueFrom(this.beneficiaryCategoryService.filter(event.query)).then(results =>
                this.beneficiaryCategorySuggestions = results,
            error => errorHandler('Error during auto-complete',
                error, this.msgService, this.translate, this.router)
        );
    }
    onBeneficiaryCategorySelect(event: any) {
        this.form.get('beneficiaryCategory').setValue(event);
    }

    // onCategorySelected(id: number) {
    //     this.form.get('company').get('companySubcategory').reset();
    //     this.selectedCategoryId = id;
    //     this.companySubcategoryService.getSubcategoriesByCategory(id).subscribe(
    //         (results: CompanySubcategory[]) => {
    //             this.companySubcategorySuggestions = [...results];
    //         },
    //         (error) => errorHandler('Error fetching subcategories', error, this.msgService, this.translate, this.router)
    //     );
    // }
    //
    // completeCompanyCategory(event: any) {
    //     firstValueFrom(this.companyCategoryService.filter(event.query)).then(results => {
    //             this.companyCategorySuggestions = results;
    //         },
    //         error => errorHandler('Error during auto-complete', error, this.msgService, this.translate, this.router)
    //     );
    // }
    //
    // completeSubcategoryMethod(event) {
    //     this.companySubcategoryService.filterSubcategories(event.query, this.selectedCategoryId).subscribe(
    //         (results: CompanySubcategory[]) => {
    //             this.companySubcategorySuggestions = results;
    //         },
    //         (error) => errorHandler('Error fetching subcategories', error, this.msgService, this.translate, this.router)
    //     );
    // }

    showTerms() {
        const ref =this.dialogService.open(TermsAndConditionsComponent, {
            header: 'Terms & Conditions',
            width: '700px'})

        ref.onClose.subscribe((action: any) => {
            if (action === 'accept') {
                this.form.controls.termsAgreed.setValue(true);
            } else if (action === 'decline') {
                this.router.navigate(['/']);
            }
        });
    }
}
