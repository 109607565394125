import {
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FileUpload} from "primeng/fileupload";
import {FileService} from "../../support/file.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl:'upload.component.html',
    selector: 'app-upload',
    styles: [`
        .p-fileupload-choose {
            display: none !important;
        }

        .p-fileupload .p-fileupload-buttonbar{
            border: 0;
            background: transparent;
        }

        .p-fileupload .p-fileupload-content{
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            padding: 0
        }
    `],
    encapsulation: ViewEncapsulation.None
})
export class UploadComponent implements OnInit {
    @Input() form: FormGroup
    @ViewChild(FileUpload) fileUpload: FileUpload;
    @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
    uploadedFiles: any[] = [];

    constructor(private fileService: FileService, private msgService: MessageService, private translate: TranslateService) {
    }

    ngOnInit(): void {
    }

    onUpload(files: any) {
        const formData = new FormData();
        for (let file of files) {
            formData.append('files', file, file.name);
        }

        this.fileService.uploadArticleImage(formData).subscribe({
            next: response => {
                if(response.body){
                    response.body.images.forEach(img => this.form.controls.images.value.push(img));
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('msg.upload-success')
                    });
                    this.fileUpload.clear();
                }
            }, error: error => {
                this.msgService.add({
                    severity: 'error',
                    summary: this.translate.instant('error.header'),
                    detail: error.message
                });
            }
        });
    }

    onImageMouseOver(file: any) {
        this.buttonEl.toArray().forEach(el => {
            el.nativeElement.id === file.name ? el.nativeElement.style.display = 'flex' : null;
        })
    }

    onImageMouseLeave(file: any) {
        this.buttonEl.toArray().forEach(el => {
            el.nativeElement.id === file.name ? el.nativeElement.style.display = 'none' : null;
        })
    }

    removeImage(event: Event, file: any) {
        event.stopPropagation();
        this.uploadedFiles = this.uploadedFiles.filter(i => i !== file);
    }
}
