
<div class="mail-box">
<!--    src="logoSrc"-->
<!--    src="assets/opop/Logo_Konekt_kirilica.jpg"-->
    <div class="mail-header">
        <img [src]="logoSrc" alt="Organization Logo" class="logo">
        <div class="mail-header-text">
            <div>{{'mailForm.headerText1'|translate}}</div>
            <div>{{'mailForm.headerText2'|translate}}</div>
            <div>{{'mailForm.headerText3'|translate}}</div>
        </div>
    </div>

    <div class="horizontal-line-thick-margins-white" style="margin-bottom:30px; margin-top:30px"></div>

    <form #emailFormID [formGroup]="emailForm">
        <div class="field" style="color:black; max-width:85%;">
            <label for="replyTo">{{'mailForm.writerMail'|translate}}</label>
            <input id="replyTo" class="w-full" pInputText type="text" formControlName="replyTo">
        </div>

        <div class="field"style="color:black; max-width:85%;">
            <label for="subject" class="text-lg">
                {{'mailForm.subject'|translate}}</label>
            <textarea id="subject" class="w-full" rows="2" pInputText formControlName="subject" style="overflow-y: auto; resize: vertical;"></textarea>
        </div>

        <div class="field" style="color:black; max-width:85%;">
            <label for="message" class="text-lg">
                {{'mailForm.message'|translate}}
            </label>
            <textarea id="message" class="w-full" rows="8" pInputText formControlName="message" style="overflow-y: auto; resize: vertical;"></textarea>
        </div>

        <div class="button-container-mail">
            <button type="button"
                    class="mt-1 mr-2  mailer-buttons"
                    pButton
                    (click)="close()"
                    label="{{'mailForm.close'|translate}}">
                <i class="fas fa-times-circle" style="color:white; font-size:1.1rem; margin-right:10px;"></i>
            </button>

            <button type="button"
                    class="mt-1  ml-2 mailer-buttons"
                    pButton
                    (click)="submit()"
                    label="{{'mailForm.send'|translate}}">
                <i class="fas fa-paper-plane" style="color:white; font-size:1.1rem; margin-right:10px;"></i>
            </button>
        </div>

    </form>
</div>

