import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { Beneficiary } from '../model/beneficiary';
import { Donor } from '../model/donor';
import { Project } from '../model/project';
import { AuthService } from '../support/auth.service';
import { DonorService } from '../services/donor.service';
import { BeneficiaryService } from '../services/beneficiary.service';
import { ProjectService } from '../services/project.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { errorHandler } from '../support/functions';
import {
    ConfirmationService,
    LazyLoadEvent,
    MessageService,
} from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TransactionService } from '../services/transaction.service';
import { Cpay } from '../model/cpay';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { Transaction, TransactionFilter } from '../model/transaction';
import { Role } from '../enums/role';
import { TermsAndConditionsComponent } from '../footer/terms-conditions.component';
import { DonationTarget } from '../enums/paymentTrigers';


@Component({
    selector: 'app-make-payment',
    templateUrl: './make-payment.component.html',
})
export class MakePaymentComponent implements OnChanges, OnInit {
      showPaymentComponent=false;
//       paymentType: string;
//       targetId: string;
//       beneficiaryIdIn: string;
//       projectIdIn: string;
//       titleIn: string;
//       descriptionIn: string;

    @Input() data: any;
    @Output() close = new EventEmitter<void>();
    termsVisible: boolean = false;

    isDesktop: boolean;
    message: string = '';
    selectedButton: string = '';
    otherAmount: number = null;
    selectedAmount: number = 200;
    inputAmount: number = 0;
    beneficiary: Beneficiary | null = null;
    donor: Donor;
    walletFunds = 0;
    totalAvailable = 0;
    sumAvailable = 0;
    transactionFilter: TransactionFilter = null;
    project: Project;
    form: FormGroup;
    inputForm: FormGroup;
    cpay: Cpay;

//     cpay: Cpay = {
//         AmountToPay: 0,
//         AmountCurrency: 'MKD',
//         Details1: `Donacija:`,
//         Details2: '',
//         PayToMerchant: environment.PAY_TO_MERCHANT,
//         MerchantName: environment.MERCHANT_NAME,
//         PaymentOKURL: '',
//         PaymentFailURL: '',
//         CheckSumHeader: '',
//         CheckSum: '',
//         };


    submitClicked: boolean;
    display = true;
    targetType = '';
    private isOrganisation: boolean;

    testFirstName = '';
    testBeneficiaryId = '';
    testProjectId = '';
    testCampaignId = '';
    testId = '';
    testAmount = 0;

    @ViewChild('formCasys') formCasys;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        public location: LocationStrategy,
        public authService: AuthService,
        public donorService: DonorService,
        public beneficiaryService: BeneficiaryService,
        public projectService: ProjectService,
        public msgService: MessageService,
        public translate: TranslateService,
        private dialogService: DialogService,
        public transactionService: TransactionService
    ) {
        this.inputForm = new UntypedFormGroup({
            paymentType: new UntypedFormControl(),
            targetType: new UntypedFormControl(),
            targetId: new UntypedFormControl(),
            title: new UntypedFormControl(),
            description: new UntypedFormControl(),
        });

        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(
                this.authService.userDetails?.donor?.wallet?.id
            ),
            firstName: new UntypedFormControl('', Validators.required),
            lastName: new UntypedFormControl('', Validators.required),
            email: new UntypedFormControl('', Validators.required),
            amount: new UntypedFormControl(null, [
                Validators.required,
                Validators.min(20),
            ]),
            projectId: new UntypedFormControl(),
            beneficiaryId: new UntypedFormControl(),
            campaignId: new UntypedFormControl(),
        });
        this.onResize();
    }

    ngOnInit() {
//         this.activatedRoute.queryParams.subscribe(params => {
//                  this.inputForm.patchValue({
//                    paymentType: params['paymentType'],
//                    targetType: params['targetType'],
//                    targetId: params['targetId'],
//                    title: params['title'],
//                    description: params['description'],
//                  });
//                  this.data = {
//                          paymentType: params['paymentType'],
//                          targetType: params['targetType'],
//                          targetId: params['targetId'],
//                          title: params['title'],
//                          description: params['description'],
//                        };
//
//                        // Call updateForm with the updated data object
//                        this.updateForm(this.data);
//               });
//
//         console.log ('--->>> queryParams:', this.inputForm);
        window.scrollTo(0, 0);
        this.checkWallet();

    }
    ngOnChanges(changes: SimpleChanges) {
        console.log ('ngOnChanges', changes);
        if (changes['data']) {
            this.updateForm(changes['data'].currentValue);
            this.testFirstName = this.form.get('firstName').value;
            this.testBeneficiaryId = this.form.get('beneficiaryId').value;
            this.testProjectId = this.form.get('projectId').value;
            this.testCampaignId = this.form.get('campaignId').value;
            this.testId = this.form.get('id').value;
            this.testAmount = this.form.get('amount').value;
        }
    }

    onCloseChild() {
        this.showPaymentComponent = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024; // or whatever breakpoint you need
    }

    updateForm(data: any) {
        console.log('1. --->> updateForm: ', data);
        // this.checkWallet();
        this.inputForm.patchValue({
            paymentType: data.paymentType,
            targetType: data.targetType,
            targetId: data.targetId,
            title: data.title,
            description: data.description,
        });

        this.targetType = data.targetType;

        this.form.patchValue({
            projectId: this.data.targetId,
        });
        this.cpay = this.makeCpay();
    }
    clearTheForm() {
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('email');
        this.form.removeControl('beneficiaryId');
        this.form.removeControl('projectId');
        this.form.removeControl('campaignId');
    }

    async runAllTests() {
        this.clearTheForm();
        console.log('Running tests');

        //10 TopUp
        //  let amountPlusVal =1000;
        //  this.form.addControl('amount', new UntypedFormControl(amountPlusVal));
        //  await delay(10000);
        //  for (let i = 0; i < 10; i++) {
        //      await this.donorMakeTopUp();
        //      await delay(2000);
        //  }

        //10 Donations
        // this.clearTheForm();
        await delay(10000);
        let amountMinusVal = 1000;
        // this.form.addControl('amount', new UntypedFormControl(amountMinusVal));
        //this.form.addControl('projectId', new UntypedFormControl(this.testProjectId));
        //this.form.addControl('id', new UntypedFormControl(this.testId));

        // console.log ('Doante To Project',this.testProjectId );
        await delay(10000);
        let transaction;
        for (let j = 0; j < 10; j++) {
            console.log('donate1', this.form.getRawValue());
            try {
                transaction = await this.donateFromWallet();
            } catch (oneTimeError) {
                console.error('Error: Wallet->PROJECT', oneTimeError);
            }
            await delay(2000);
        }
    }
    checkWallet() {
        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails.role === Role.DONOR &&
            this.authService.userDetails.donor.wallet
        ) {
            const theWallet = this.authService.userDetails.donor.wallet;
            const theWalletId = theWallet.id;
            this.walletFunds = theWallet.balance;

            firstValueFrom(this.donorService.getDonorWallet()).then(
                (res) => {
                    this.totalAvailable = res.totalTopupAmount;
                    this.sumAvailable = res.balance;
                },
                (error) => {
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                }
            );
        } else {
            console.log('6A Error. --->> No Wallet: ');
        }
    }
    donateLocally(transactionId) {
        console.log('------>>> Donate Locally', this.form.getRawValue());
        return firstValueFrom(
            this.transactionService.donateLocally(transactionId)
        ).then(
            () => {
                return Promise.resolve(true);
            },
            (error) => {
                console.log(error);
                return Promise.resolve(false);
            }
        );
    }

    donateFromWalletToSystem(): Promise<Transaction> {
        this.form.patchValue({
            firstName: null,
            lastName: null,
            email: null,
            campaignId: null,
        });
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('email');
        this.form.removeControl('beneficiaryId');
        this.form.removeControl('projectId');
        this.form.removeControl('campaignId');
        this.form.removeControl('id');
        return firstValueFrom(
            this.donorService.donateFromWalletToSystem(this.form.getRawValue())
        ).then(
            (transaction) => {
                return transaction;
            },
            (error) => {
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                throw new Error('donate FromW allet Failed');
            }
        );
    }

    donateFromWallet(): Promise<Transaction> {
        // have to check here if we are donating to system or not
        console.log(
            '++++++++>>> donate From Wallet: ',
            this.form.getRawValue()
        );
        this.form.patchValue({
            firstName: null,
            lastName: null,
            email: null,
            campaignId: null,
        });
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('email');
        this.form.removeControl('beneficiaryId');
        this.form.removeControl('email');
        this.form.removeControl('campaignId');

        return firstValueFrom(
            this.donorService.donateFromWallet(this.form.getRawValue())
        ).then(
            (transaction) => {
                return transaction;
            },
            (error) => {
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                throw new Error('donate FromW allet Failed');
            }
        );
    }

    ccDonationDonor_ToProject(): Promise<Transaction> {
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('email');
        this.form.removeControl('beneficiaryId');
        this.form.removeControl('id');
        const donationData = this.form.getRawValue();

        return firstValueFrom(
            this.donorService.ccDonationDonor(donationData)
        ).then(
            (transaction) => {
                return transaction;
            },
            (error) => {
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                return null;
            }
        );
    }

    ccDonationDonor_ToOpOp(): Promise<Transaction> {
        const donationData = this.form.getRawValue();
        console.log('--> ccDonationDonor_ToOpOp', donationData);
        return firstValueFrom(
            this.donorService.donateSystemOneTime(donationData)
        ).then(
            (transaction) => {
                console.log(
                    '11. --->> transaction: ',
                    JSON.stringify(transaction)
                );
                return transaction;
            },
            (error) => {
                console.log('12. Error--->> donate One TimeInternal: ', error);
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                return null;
            }
        );
    }

    donateOneTimeInternal(): Promise<Transaction> {
        const donationData = this.form.getRawValue();
        console.log("===>>> 4. donateOneTimeInternal", donationData);
        if (this.targetType.trim() === 'DonationToOpOp') {
            return firstValueFrom(
                this.donorService.donateSystemOneTime(donationData)
            ).then(
                (transaction) => {
                    console.log( '11. --->> transaction: ', JSON.stringify(transaction));
                    return transaction;
                },
                (error) => {
                    console.log(
                        '12. Error--->> donate One TimeInternal: ',
                        error
                    );
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                    return null;
                }
            );
        }
        else {
            return firstValueFrom(
                this.donorService.donateOneTime(donationData)
            ).then(
                (transaction) => {
                    console.log('16. --->> transaction: ', JSON.stringify(transaction));
                    return transaction;
                },
                (error) => {
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                    return null;
                }
            );
        }
    }

    get disableOneTime() {
        return (
            !this.authService.isAuthenticated &&
            (!this.form.controls.firstName.value ||
                !this.form.controls.lastName.value ||
                !this.form.controls.email.value)
        );
    }
    checkDonationType(): DonationTarget {
        switch (this.data.targetType.trim()) {
            case 'DonationToOpOp':
                console.log('--> DonationToOpOp');
                return DonationTarget.DonationToOpOp;

            case 'DonationToProject':
                console.log('--> DonationToProject');
                return DonationTarget.DonationToProject;

            case 'DonationToBeneficiary':
                console.log('--> DonationToBeneficiary');
                return DonationTarget.DonationToBeneficiary;

            default:
                console.log('Unknown donation type');
                return DonationTarget.InvalidPaymentType;
        }
    }
    checkIfDonorIsValid() {
        console.log(
            '-> 1. Chek if Donor Is Valid: ',
            this.authService.isAuthenticated
        );
        console.log('-> 2. userDetails: ', this.authService?.userDetails);
        console.log('-> 3. role: ', this.authService?.userDetails?.role);
        console.log('-> 4. donor: ', this.authService?.userDetails?.donor);
        console.log(
            '-> 5. wallet: ',
            this.authService?.userDetails?.donor?.wallet
        );
        // Check if the logged in user is Donor and that the donor object is Ok.
        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails.role === Role.DONOR &&
            !this.authService.userDetails.donor
        ) {
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Unable to find the donor!',
                detail: '',
            });
            return false;
        }

        // Check if Donor has a wallet
        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails.role === Role.DONOR &&
            !this.authService.userDetails.donor.wallet
        ) {
            console.log();
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Missing wallet for the donor!',
                detail: '',
            });
            return false;
        }
        return true;
    }

    async donorMakeTopUp() {
        if (!this.authService.isAuthenticated) {
            this.router.navigate(['/auth/login']);
            return;
        }

        if (!this.checkIfDonorIsValid()) {
            console.log('DBG3', 'Invalid Donor');
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Donor Not Valid!',
                detail: '',
            });
            return;
        }
        console.log('-> Donor Ok');

        if (!this.validate()) {
            return;
        }

        const transaction = await this.topupInternal();

        // Pay transaction in Casys
        if (transaction != null) {
            this.submitClicked = true;
            if (!environment.production) {

                this.donateLocally(transaction.transactionId);
            } else {
                // Amount to pay multiplied by 100 - without decimal and separators, i.e. amount 1,00 will be value 100
                this.cpay.AmountToPay = this.form.controls.amount.value * 100;
                this.setCpayTransactionDetailsAndChecksum(
                    transaction.transactionId
                );
                setTimeout(() => this.formCasys.nativeElement.submit(), 500);
            }
        }
    }

    async donorMakeWalletDonation() {
        console.log('DBG', 'donorMakeWalletDonation');
        if (!this.checkIfDonorIsValid()) {
            console.log('DBG3', 'Invalid Donor');
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Donor Not Valid!',
                detail: '',
            });
            return;
        }
        console.log('-> Donor Ok');
        let transaction;
        switch (this.checkDonationType()) {
            case DonationTarget.DonationToBeneficiary:
            case DonationTarget.DonationToProject:
                try {
                    transaction = await this.donateFromWallet();
                } catch (oneTimeError) {
                    console.error('Error: Wallet->PROJECT', oneTimeError);
                }
                break;

            case DonationTarget.DonationToOpOp:
                try {
                    transaction = await this.donateFromWalletToSystem();
                } catch (oneTimeError) {
                    console.log('Error: Wallet->SYSTEM', oneTimeError);
                }
                break;
        }
        // let transaction;
        //     try {  transaction =  await this.donateFromWallet(); }
        //     catch (oneTimeError) { console.error('Error: Wallet->PROJECT',oneTimeError);}
    }

    async donorMakeCCDonation() {
        // Registered AND Logged in Donor wants to make a CC Donation to
        // Project, Beneficiary or Platform
        // It will create:
        //  - One TopUp Transaction with status (AVAILABLE) in the Donors Wallet.
        //  - One Transaction with status (RESERVED) to the receiver (Project,Beneficiary, Platform )

        let transaction;
        // Go Back If we dont have logged in user, Not a Donor or No Wallet
        if (!this.checkIfDonorIsValid()) {
            console.log('DBG3', 'Invalid Donor');
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Donor Not Valid!',
                detail: '',
            });
            return;
        }
        console.log('-> Donor Ok');

        // Check donation type: ( one of the PROJECT, BENEFICIARY, PLATFORM )
        switch (this.checkDonationType()) {
            case DonationTarget.DonationToProject:
            case DonationTarget.DonationToBeneficiary:
                console.log('-> Donor -> CC Donation -> PROJECT/BENEFICIARY');
                // try {  transaction = await this.ccDonationDonor_ToProject(); }
                // catch (oneTimeError) { console.error('Error Donor->CC->PROJECT',oneTimeError);}
                try {
                    await this.donorMakeTopUp();
                    await this.donateFromWallet();
                } catch (error) {
                    console.error('An error occurred:', error);
                }
                break;

            case DonationTarget.DonationToOpOp:
                console.log('-> Donor -> Wallet -> PLATFORM');
                try {
                    await this.donorMakeTopUp();
                    await this.donateFromWalletToSystem();
                } catch (error) {
                    console.error('An error occurred:', error);
                }
                break;
        }

        // Pay transaction in Casys
        if (transaction != null) {
            console.log('-> SendingToCasys ', transaction);
            this.submitClicked = true;
            if (!environment.production) {
                this.donateLocally(transaction.transactionId);
            } else {
                this.cpay.AmountToPay = this.form.controls.amount.value * 100;
                this.setCpayTransactionDetailsAndChecksum(
                    transaction.transactionId
                );
                setTimeout(() => this.formCasys.nativeElement.submit(), 500);
            }
        }
    }

    async submit() {
        console.log("===>>> 1. MakePayment Submit");
        if (!this.validate()) {
            console.log("===>>> Validate Failed");
            return;
        }

        let transaction;
        let donateToSystem = this.data.targetType === 'DonationToOpOp';
        let didDonateFromWallet = false;

        if ( this.authService.isAuthenticated && this.authService.userDetails.role == Role.DONOR) {
            console.log("===>>> 2. LoggedInDonor");
            if (donateToSystem) {
                transaction = await this.donateToPlatform(this.selectedButton);
            } else {
                transaction = await this.donateToProject(this.selectedButton);
            }
        } else {
            console.log("===>>> 3. Not Registered Donor");
            try {
                transaction = await this.donateOneTimeInternal();
            } catch (oneTimeError) {
                console.error( 'Error in Donate One Time Internal:', oneTimeError );
            }
        }

        // Pay transaction in Casys
        if (transaction != null && !didDonateFromWallet) {
            console.log('20. --->> YES TRANSACTION: ', transaction);
            this.submitClicked = true;

            if (!environment.production) {
                console.log('===>>> NOT PRODUCTION ENVIRONMET: ',this.form.getRawValue());
                this.donateLocally(transaction.transactionId);
            }
            else {
                // Amount to pay multiplied by 100 - without decimal and separators, i.e. amount 1,00 will be value 100
                console.log ("Ok. Go To Casys..");
                console.log('===>>> YES! PRODUCTION ENVIRONMET: ',this.form.getRawValue());
                this.cpay.AmountToPay = this.form.controls.amount.value * 100;
                // DINO!!!  Ovde povikuva so transactionID no vo modelot ima samo id - probuvam so id.
                 this.setCpayTransactionDetailsAndChecksum( transaction.transactionId );
                // DINO!!! Ova pravi problem so prepoznavanje na transakcii koga se vrakja od Casys.
                // this.setCpayTransactionDetailsAndChecksum( transaction.id );


                // DINO!!!  Ova e submit do casys
                setTimeout(() => this.formCasys.nativeElement.submit(), 500);
            }
        }
    }

    donateToPlatform(type: string): Promise<Transaction> {
        const body = {
            amount: this.form.getRawValue().amount,
            projectId: null,
        };
        if (type === 'Card') {
            return firstValueFrom(
                this.donorService.ccPlatformDonation(body)
            ).then(
                (transaction) => {
                    return transaction;
                },
                (error) => {
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                    throw new Error('donate From Card Failed');
                }
            );
        } else {
            return firstValueFrom(
                this.donorService.walletPlatformDonation(body)
            ).then(
                (transaction) => {
                    return transaction;
                },
                (error) => {
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                    throw new Error('donate From Wallet Failed');
                }
            );
        }
    }

    donateToProject(type: string): Promise<Transaction> {
        this.form.removeControl('firstName');
        this.form.removeControl('lastName');
        this.form.removeControl('email');
        this.form.removeControl('beneficiaryId');
        this.form.removeControl('id');
        const donationData = this.form.getRawValue();
        console.log(JSON.stringify(donationData));
        if (type === 'Card') {
            return firstValueFrom(
                this.donorService.ccDonation(donationData)
            ).then(
                (transaction) => {
                    return transaction;
                },
                (error) => {
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                    throw new Error('donate From Card Failed');
                }
            );
        } else {
            return firstValueFrom(
                this.donorService.walletDonation(donationData)
            ).then(
                (transaction) => {
                    return transaction;
                },
                (error) => {
                    errorHandler(
                        'Error',
                        error,
                        this.msgService,
                        this.translate,
                        this.router
                    );
                    throw new Error('donate From Wallet Failed');
                }
            );
        }
    }

    topupInternal(): Promise<Transaction> {
        console.log('topupInternal', this.form.getRawValue());
        return firstValueFrom(
            this.donorService.topup(this.form.getRawValue())
        ).then(
            (transaction) => {
                return transaction;
            },
            (error) => {
                errorHandler(
                    'Error',
                    error,
                    this.msgService,
                    this.translate,
                    this.router
                );
                return null;
            }
        );
    }

    goBack() {
        this.location.back();
    }

    protected validate(): boolean {
        // Proveri dali e Korsinikot logiran DONOR i deka donor objekt postoi
        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails?.role === Role.DONOR &&
            !this.authService.userDetails?.donor
        ) {
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Unable to find the donor!',
                detail: '',
            });
            return false;
        }

        // Proveri dali e logiraniot donor ima wallet
        if (
            this.authService.isAuthenticated &&
            this.authService.userDetails?.role === Role.DONOR &&
            !this.authService.userDetails?.donor?.wallet
        ) {
            this.msgService.add({
                severity: 'warn',
                life: 6000,
                summary: 'Missing wallet for the donor!',
                detail: '',
            });
            return false;
        }
        return true;
    }

    makeCpay() {
        const cleanTitle = this.inputForm.get('title').value;
        return {
            AmountToPay: 0,
            AmountCurrency: 'MKD',
            Details1: `Donacija za: ${cleanTitle}`,
            Details2: '',
            PayToMerchant: environment.PAY_TO_MERCHANT,
            MerchantName: environment.MERCHANT_NAME,
            PaymentOKURL: '',
            PaymentFailURL: '',
            CheckSumHeader: '',
            CheckSum: '',
        } as Cpay;
    }

    setCpayTransactionDetailsAndChecksum(transactionId) {
        console.log ("====>>>> setCpayTransactionDetailsAndChecksum", transactionId);
        this.cpay.Details2 = transactionId;

        // DINO -> this will only work from the production server..
        this.cpay.PaymentOKURL   = `${environment.DOMAIN}/${environment.API_REF}/transactions/cpay_success`;
        this.cpay.PaymentFailURL = `${environment.DOMAIN}/${environment.API_REF}/transactions/cpay_fail`;

        // this.cpay.PaymentOKURL = 'https://opop.mk/opop-api/transactions/cpay_success';
        // this.cpay.PaymentFailURL = 'https://opop.mk/opop-api/transactions/cpay_fail';

        this.cpay.CheckSum = this.generateChecksum();
        this.cpay.CheckSumHeader = this.generateChecksumHeader();

        console.log("++++++>>>>> the CPAY: ", this.cpay);
    }

    generateChecksum(): string {
        console.log ("===>>> 1. generateChecksum");
        let value = '08AmountToPay,PayToMerchant,MerchantName,AmountCurrency,Details1,Details2,PaymentOKURL,PaymentFailURL,';
        console.log ("===>>> 2. value",value);
        value = `${value}${this.countChecksum()}${this.cpay.AmountToPay}${
            this.cpay.PayToMerchant
        }${this.cpay.MerchantName}`;
        console.log ("===>>> 3. value",value);
        console.log ("===>>> 4. cpay",this.cpay);
        value = `${value}${this.cpay.AmountCurrency}${this.cpay.Details1}${this.cpay.Details2}${this.cpay.PaymentOKURL}`;
        console.log ("===>>> 5. value",value);
        value = `${value}${this.cpay.PaymentFailURL}UWhATwcjPHRMrB4DFU89wqTBDVmbX2KA`;
        console.log ("===>>> 6. value",value);
        return CryptoJS.MD5(value).toString().toUpperCase();
    }

    generateChecksumHeader() {
        return `08AmountToPay,PayToMerchant,MerchantName,AmountCurrency,Details1,Details2,PaymentOKURL,PaymentFailURL,${this.countChecksum()}`;
    }

    countChecksum() {
        const lengthPaymentOKURL = this.cpay.PaymentOKURL.length
            .toString()
            .padStart(3, '0');
        const lengthPaymentFailURL = this.cpay.PaymentFailURL.length
            .toString()
            .padStart(3, '0');
        const lengthAmountToPay = this.cpay.AmountToPay.toString()
            .length.toString()
            .padStart(3, '0');
        const lengthAmountCurrency = this.cpay.AmountCurrency.length
            .toString()
            .padStart(3, '0');
        const lengthPayToMerchant = this.cpay.PayToMerchant.length
            .toString()
            .padStart(3, '0');
        const lengthDetails1 = this.cpay.Details1.length
            .toString()
            .padStart(3, '0');
        const lengthDetails2 = this.cpay.Details2.length
            .toString()
            .padStart(3, '0');
        const lengthMerchantName = this.cpay.MerchantName.length
            .toString()
            .padStart(3, '0');
        return `${lengthAmountToPay}${lengthPayToMerchant}${lengthMerchantName}${lengthAmountCurrency}${lengthDetails1}${lengthDetails2}${lengthPaymentOKURL}${lengthPaymentFailURL}`;
    }

    selectButton(label) {
        this.selectedButton = label;
        this.submit();
    }

  /*   closeComponent() {
    console.log ('-->closeComponent')
        this.close.emit();
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }
 */

 closeComponent() {
     console.log('-->closeComponent');
     this.close.emit();
     this.router.navigate(['/']); // Navigate to the desired route, e.g., home
     if (document.activeElement instanceof HTMLElement) {
       document.activeElement.blur();
     }
   }
    selectAmount(amount: number): void {
        this.selectedAmount = amount;
        this.otherAmount = null; // Erase the value of the input field
        this.form.controls.amount.setValue(amount);
    }

    gotFocus(): void {
        this.selectedAmount = null; // Deselect all money buttons when typing in the input field
    }

    showTerms() {
        console.log ('===> showTerms');
        this.dialogService.open(TermsAndConditionsComponent, {
            header: 'Terms & Conditions',
            width: '700px',
        });
    }

    get isVirmanDisabled(): boolean {
        return (
            !this.authService.isAuthenticated ||
            this.authService.userDetails.role !== Role.DONOR
        );
    }

    get isDonateButtonDisabled(): boolean {
        return false;
//         return this.form.get('firstName').invalid || this.form.get('lastName').invalid || this.form.get('email').invalid;
//         return this.form.get('firstName').invalid ;
    }

    makeVirman() {
        const theTargetType = this.data.targetType;
        let theTargetID = this.data.targetId;
        const beneficiaryId = this.data.targetType;
        // const beneficiaryId = this.data.beneficiaryId;
        let theProjectID = this.data.targetId;
        const theDonorId = this.authService.userDetails?.donor?.id;

        if (theTargetID === beneficiaryId) {
            this.confirmationService.confirm({
                header: 'You cant make donations to self.',
                message:
                    'Use TopUp to add funds to your Wallet in the OpOp platform.<br>At present, TopUp Can only be made with a Credit Card',
                icon: 'pi pi-info-circle',
                acceptLabel: 'Close', // Text for the close button
                rejectVisible: false, // Hide the reject/cancel button
                accept: () => {},
            });
            return;
        }

        if (
            this.data.targetType === 'DonationToBeneficiary' ||
            this.data.targetType === 'DonationToOpOp'
        ) {
            console.log('A1 +++>> DonationToBeneficiary');
            forkJoin([
                this.beneficiaryService.getById(theTargetID),
                this.donorService.getById?.(theDonorId),
            ]).subscribe(
                ([receiverData, donorData]) => {
                    this.handleDonationData(
                        receiverData,
                        donorData,
                        theTargetType
                    );
                },
                (error) => {
                    console.error(
                        'Error fetching Beneficiary Or Donor:',
                        error
                    );
                    this.confirmationService.confirm({
                        message: 'An error occurred: ' + error.message, // Customize this message
                        header: 'Error', // Customize header if needed
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            // Handle the action when the user accepts
                        },
                        reject: () => {
                            // Handle the action when the user rejects (if needed)
                        },
                    });
                }
            );
        } else if (this.data.targetType === 'DonationToProject') {
            forkJoin([
                this.projectService.getById(theProjectID),
                this.donorService.getById?.(theDonorId),
            ]).subscribe(
                ([receiverData, donorData]) => {
                    this.handleDonationData(
                        receiverData,
                        donorData,
                        theTargetType
                    );
                },
                (error) => {
                    console.error('Error fetching Project Or Donor:', error);
                }
            );
        } else {
            console.error('Unknown TargetType: ', theTargetType);
        }
    }
    private handleDonationData(receiverData, donorData, theTargetType) {
        this.donor = donorData;
        let receiverId = '0';
        console.log ('handleDonationData', theTargetType);

        if (theTargetType === 'DonationToBeneficiary') {
            this.beneficiary = receiverData;
            receiverId = this.beneficiary.directProjectId;
        } else if (theTargetType === 'DonationToProject') {
            this.project = receiverData;
            receiverId = this.project.uniqueId;
        }

        let donorName = 'DonorName';
        let donorAddress = 'DonorAddress';
        let donorID = 'DonorID';
        let donorBankName = 'DonorBankName';
        let donorBankAccountName = 'DonorBankAccountName';
        let donorBankAccNr = '00000000';

        let beneficiaryName = 'BeneficiaryName';
        let beneficiaryAddress = 'BeneficiaryAddress';
        let beneficiaryID = 'BeneficiaryID';
        let beneficiaryBankName = 'BeneficiaryBankName';
        let beneficiaryBankAccountName = 'BeneficiaryBankAccountName';
        let beneficiaryBankAccNr = '00000000';

        let directProjectId = '00000';

        let amount = 0;
        let targetType = 'Target: Project, Beneficiary or OpOp';
        let projectName = 'ProjectName';

        this.isOrganisation = this.donor.donorType === 'ORGANIZATION';
        if (this.isOrganisation) {
            donorID = this.donor.uniqueId;
            donorName = this.donor.company.companyName;
            donorAddress =
                this.donor.company.companyStreetAddress +
                ' ' +
                this.donor.company.companyCity +
                ' ' +
                this.donor.company.companyMunicipality.name +
                ' ' +
                this.donor.company.companyZip;

            donorBankName = this.donor.company.bank.bankName;
            donorBankAccountName = this.donor.company.bank.accountName;
            donorBankAccNr = this.donor.company.bank.accountNumber;
        } else {
            donorID = this.donor.uniqueId;
            donorName =
                this.donor?.person?.firstName +
                ' ' +
                this.donor?.person?.lastName;
            donorAddress =
                this.donor?.person.streetAddress +
                ' ' +
                this.donor?.person?.city +
                ' ' +
                this.donor?.person?.municipality?.name +
                ' ' +
                this.donor?.person?.zip;

            donorBankName = 'Enter Your Bank name';
            donorBankAccNr = 'Enter Your Account number';
            donorBankAccountName = 'Enter Name of your Account';
        }

        if (theTargetType === 'DonationToBeneficiary') {
            beneficiaryID = this.beneficiary.uniqueId;
            beneficiaryName = this.beneficiary.company.companyName;
            donorAddress =
                this.beneficiary.company.companyStreetAddress +
                ' ' +
                this.beneficiary.company.companyCity +
                ' ' +
                this.beneficiary.company.companyMunicipality.name +
                ' ' +
                this.beneficiary.company.companyZip;

            beneficiaryBankName = this.beneficiary.company.bank.bankName;
            beneficiaryBankAccountName =
                this.beneficiary.company.bank.accountName;
            beneficiaryBankAccNr = this.beneficiary.company.bank.accountNumber;
            directProjectId = this.beneficiary.directProjectId;
        } else if (theTargetType === 'DonationToProject') {
            beneficiaryID = this.project.owner.uniqueId;
            beneficiaryName = this.project.owner.company.companyName;
            beneficiaryAddress =
                this.project.owner.company.companyStreetAddress +
                ' ' +
                this.project.owner.company.companyCity +
                ' ' +
                this.project.owner.company.companyMunicipality.name +
                ' ' +
                this.project.owner.company.companyZip;

            beneficiaryBankName = this.project.owner.company.bank.bankName;
            beneficiaryBankAccountName =
                this.project.owner.company.bank.accountName;
            beneficiaryBankAccNr =
                this.project.owner.company.bank.accountNumber;

            directProjectId = this.project.uniqueId;
            projectName = this.project.title;
        }
        amount = this.form.controls['amount'].value;
        const params = {
            DonorID: donorID,
            DonorName: donorName,
            DonorAddress: donorAddress,
            DonorBankName: donorBankName,
            DonorBankAccNr: donorBankAccNr,
            DonorBankAccountName: donorBankAccountName,

            BeneficiaryID: beneficiaryID,
            BeneficiaryName: beneficiaryName,
            BeneficiaryAddress: beneficiaryAddress,
            BeneficiaryBankName: beneficiaryBankName,
            BeneficiaryBankAccNr: beneficiaryBankAccNr,
            BeneficiaryBankAccountName: beneficiaryBankAccountName,

            Amount: amount,
            TargetType: theTargetType,
            ProjectName: projectName,
            DirectProjectId: directProjectId,
        };

        this.router.navigate(['/virman', params]);
    }
    makePledge() {
        const params = {
            DonorID: '10001111',
            DonorName: 'This DonorName',
            DonorAddress: 'This Donor Address',
            DonorBankName: 'This Donor Bank Name',
            DonorBankAccNr: 'This Donor Bank AccNr.',
        };
        this.router.navigate(['/pledge', params]);
    }
}
function sanitizeString(input: string): string {
    return input.replace(/[^a-zA-Z0-9-_. ]/g, ''); // Adjust the regex to include any safe characters
}
async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
