


<p-fileUpload   #fileUploader name="file[]"
                (onUpload)="handleFileUpload($event)" [hidden]="true"
                [customUpload]="true" [accept]="accept"
                (onSelect)="handleFileUpload($event)"
                [showUploadButton]="false"
                [showCancelButton]="false" [auto]="true" class="col-3">
</p-fileUpload>

<p-panel class="col-2 mt-0 mb-0" styleClass="{{hasDocs}}">
    <ng-template pTemplate="header">
        <div style="
            min-width:22rem;
            cursor:{{ !isUploadDisabled ? 'copy' : 'default'}};"
             [class.disabled]="isUploadDisabled"
             (click)="!isUploadDisabled && fileUploader.advancedFileInput.nativeElement.click()">

<!--            <i class="pi pi-upload text-lg"-->
<!--               [class.text-white]="!isUploadDisabled"-->
<!--               [class.text-gray]="isUploadDisabled">-->
<!--            </i>-->

            <span [ngClass]="{
            'text-black': isUploadDisabled || hasDocs,
            'text-gray': !isUploadDisabled && !hasDocs}"
                  class="text-md ml-0"
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%;">
                <i class="fas fa-download" style="margin-right: 5px;"></i>
                {{'enum.DocumentType.' + documentType | translate}}
            </span>
        </div>

<!--        <div *ngIf="readonly" class="text-white text-lg ml-3">-->
<!--            {{'enum.DocumentType.' + documentType | translate}}</div>-->

    </ng-template>

    <div class="h-5rem">
        <p-table [value]="documentsByType" [scrollable]="true" [rows]="2" scrollHeight="60px"
                 styleClass="p-datatable-sm h-3rem">
            <ng-template pTemplate="body" let-doc>
                <tr>
                    <td style="max-width: 400px;">
                        <div class="document-name">{{ doc.fileName }}</div>
                    </td>
                    <td class="text-right" style="width:50px">
                        <i class="icon-hover pi pi-trash text-red-500 mr-2" *ngIf="!readonly"
                           (click)="confirmDelete(doc)"></i>
                        <i class="icon-hover pi pi-download text-green-500"
                           (click)="downloadDocument(doc.id, doc.fileName)"></i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">{{'msg.noDocumentsFound'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-panel>
