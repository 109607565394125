import { Component, HostListener, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { DonorType, donorTypeRegister_items } from "../../../../enums/donor-type";
import { donorIndividualSteps } from "./donor-individual-steps";
import { donorOrganizationSteps } from "./donor-organization-steps";
import { DialogService } from "primeng/dynamicdialog";
import { TermsAndConditionsComponent } from "../../../../footer/terms-conditions.component";

@Component({
    templateUrl: 'donor-register-step1.component.html'
})
export class DonorRegisterStep1Component implements OnInit {
    isDesktop: boolean = true;
    itemsIndividual: MenuItem[];
    itemsOrganization: MenuItem[];
    donorTypeOptions: any[];
    donorType: UntypedFormControl;
    termsAgreed: UntypedFormControl;

    constructor(public layoutService: LayoutService, private router: Router,
                private translate: TranslateService,
                private dialogService: DialogService) {
        this.donorType = new UntypedFormControl(DonorType.INDIVIDUAL, Validators.required);
        this.termsAgreed = new UntypedFormControl(null, Validators.required);
        this.donorTypeOptions = donorTypeRegister_items(this.translate);
        this.itemsIndividual = donorIndividualSteps(this.translate);
        this.itemsOrganization = donorOrganizationSteps(this.translate);
        this.onResize();
    }

    ngOnInit(): void {
        this.donorType.valueChanges.subscribe((value) => {
            if (value === null || value === '') {
                // Re-select the previously selected value if it is deselected
                this.donorType.setValue(DonorType.INDIVIDUAL, { emitEvent: false });
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    submit() {
        if (this.donorType.value === DonorType.INDIVIDUAL) {
            this.router.navigateByUrl(`auth/register/donor/individual`, { state: { donorType: this.donorType.value } });
            return;
        }

        if (this.donorType.value === DonorType.ORGANIZATION) {
            this.router.navigateByUrl(`auth/register/donor/organization`, { state: { donorType: this.donorType.value } });
            return;
        }
    }

    showTerms() {
        const translatedHeader = this.translate.instant('footer.termsConditions');

        const ref = this.dialogService.open(TermsAndConditionsComponent, {
            header: translatedHeader,
            width: '600px'
        });

        ref.onClose.subscribe(action => {
            if (action === 'accept') {
                this.termsAgreed.setValue(true);
            } else if (action === 'decline') {
                this.router.navigate(['/']);
            }
        });
    }
}
