import { OnInit, EventEmitter, OnDestroy, Output,ChangeDetectorRef } from '@angular/core';
import { Component } from '@angular/core';
import { HostListener } from '@angular/core';
import { AuthService } from '../support/auth.service';
import { Role } from '../enums/role';
import { LanguageService } from '../components/generics/language.service';
import { Subscription } from 'rxjs';
import { Language } from '../components/generics/language.service';
import { DialogService } from 'primeng/dynamicdialog';
import { TermsAndConditionsComponent } from '../footer/terms-conditions.component';
import { StorageService } from '../support/storage.service';
import { environment } from '../../environments/environment';
import { PrivacyPolicyComponent } from '../footer/privacy-policy.component';
import {TermsFAQComponent} from "../footer/terms-faq.component";
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
// import { environment } from '../../environments/environment';



@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit,OnDestroy {
    @Output() menuItemClicked = new EventEmitter<void>();
    userDetails: any;
    model: any[] = [];
    desktopModel: any[] = [];
    mobileModel: any[] = [];
    isDesktop: boolean = true;
    isUserLoggedIn: boolean = false;
    isUserDonor: boolean = false;
    isUserAdminDonor: boolean = false;
    isUserBeneficiary: boolean = false;
    isUserAdminBeneficiary: boolean = false;
    isUserAdmin: boolean = false;
    isUserAdminProject: boolean = false;
    language: Language;
    private langSubscription: Subscription;
    private routerSubscription: Subscription;
    constructor(
        public authService: AuthService,
        private languageService: LanguageService,
        private dialogService: DialogService,
        private storageService: StorageService,
        private translate: TranslateService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    openTermsAndConditions() {
        const translatedHeader = this.translate.instant('footer.termsConditions');
        const ref = this.dialogService.open(TermsAndConditionsComponent, {
            header: translatedHeader,
            width: '90%',
        });
    }

    showPrivacy() {
            const translatedHeader = this.translate.instant('footer.privacy');
            this.dialogService.open(PrivacyPolicyComponent, {
                header: translatedHeader,
                width:'90%'
            });
        }

    showFAQ() {
            const translatedHeader = this.translate.instant('footer.copyright_part8');
            this.dialogService.open(TermsFAQComponent, {
            header: translatedHeader,
            width: '90%'
            });
    }

    contactUs() {
            this.router.navigate(['/send-email', { email: 'info@opop.mk' }]);
    }

     techSupport() {
        this.router.navigate(['/send-email', { email: 'support@opop.mk' }]);
    }

    onMenuItemClick() {
            this.menuItemClicked.emit(); // Add this line
    }

    updateMenuState() {
        this.userDetails =
            this.storageService.getValue(environment.USER_DETAILS) ?? null;
        this.buildDesktopMenue();
        this.buildMobileMenue();
        this.cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isDesktop = window.innerWidth >= 1024;
    }

    ngOnInit() {
        this.userDetails =
            this.storageService.getValue(environment.USER_DETAILS) ?? null;
        this.isDesktop = window.innerWidth >= 1024;
        // this.checkUserCredentials();
        this.buildDesktopMenue();
        this.buildMobileMenue();
        this.langSubscription = this.languageService
            .getSelectedLanguage()
            .subscribe((lang) => {
                this.language = lang;
            });
        this.routerSubscription = this.router.events.subscribe((event) => {
                    if (event instanceof NavigationEnd) {
                        this.updateMenuState();
                    }
                });
    }
    ngOnDestroy() {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
         if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
    buildDesktopMenue() {
        this.desktopModel = [
            {
                label: 'Menu',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'menu.home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/'],
                        command: () => this.onMenuItemClick()
                    },
                    {
                        label: 'footer.privacy',
                        icon: 'pi pi-fw pi-shield',
                        command: () => {
                            this.onMenuItemClick();
                            this.showPrivacy();
                        }
                    },
                    {
                        label: 'footer.copyright_part4',
                        icon: 'pi pi-fw pi-file',
                        command: () => {
                            this.onMenuItemClick();
                            this.openTermsAndConditions();
                        }
                    },
                    {
                       label: 'footer.copyright_part8',
                       icon: 'pi pi-fw pi-question-circle',
                       command: () => {
                            this.onMenuItemClick();
                            this.showFAQ();
                        }
                    },
                    {
                        label: 'footer.copyright_part2',
                        icon: 'fas fa-paper-plane',
                        command: () => {
                            this.onMenuItemClick();
                            this.contactUs();
                        }
                    },
                     {
                       label: 'footer.copyright_part3',
                       icon: 'fas fa-wrench',
                       command: () => {
                           this.onMenuItemClick();
                            this.techSupport();
                       }
                   },
                ],
            },
        ];

        if (this.userDetails?.role === Role.ADMIN) {
            this.desktopModel[0].items.push({
                label: 'menu.admin-dashboard',
                icon: 'pi pi-fw pi-list',
                routerLink: ['db-admin/admin-dashboard'],
                command: () => this.onMenuItemClick()
            });
        }
        else if (this.authService.userDetails?.role === Role.DONOR) {
            console.log('-->>> AddingTheMenueItem for Donor.. ');
            this.desktopModel[0].items.push({
                label: 'menu.donor-dashboard',
                icon: 'pi pi-fw pi-list',
                routerLink: ['db-donor/donor-dashboard'],
                command: () => this.onMenuItemClick()
            });
        }
        else if (this.userDetails?.role === Role.BENEFICIARY) {
            this.desktopModel[0].items.push({
                label: 'menu.beneficiary-dashboard',
                icon: 'pi pi-fw pi-list',
                routerLink: ['db-beneficiary/beneficiary-dashboard'],
                command: () => this.onMenuItemClick()
            });
        }
    }
    buildMobileMenue() {
        this.mobileModel = [
            {
                label: 'Menu',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'menu.home',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/'],
                        command: () => this.onMenuItemClick()
                    },
                    {
                        label: 'top-bar.about_us',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['about-us'],
                        command: () => this.onMenuItemClick()
                    },
                    {
                        label: 'top-bar.beneficiary',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['about-beneficiary'],
                        command: () => this.onMenuItemClick()
                    },
                    {
                        label: 'top-bar.donor',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['about-donor'],
                        command: () => this.onMenuItemClick()
                    },
                    {
                        label: 'footer.privacy',
                        icon: 'pi pi-fw pi-shield',
                        command: () => {
                          this.onMenuItemClick();
                          this.showPrivacy();
                        }
                    },
                    {
                        label: 'footer.copyright_part4',
                        icon: 'pi pi-fw pi-file',
                        command: () => {
                          this.onMenuItemClick();
                          this.openTermsAndConditions();
                        }
                     },
                     {
                        label: 'footer.copyright_part8',
                        icon: 'pi pi-fw pi-question-circle',
                        command: () => {
                          this.onMenuItemClick();
                          this.showFAQ();
                        }
                       },
                       {
                           label: 'footer.copyright_part2',
                           icon: 'fas fa-paper-plane',  // Font Awesome icon
                           command: () => {
                             this.onMenuItemClick();
                             this.contactUs();
                           }
                       },
                        {
                           label: 'footer.copyright_part3',
                           icon: 'fas fa-wrench',  // Font Awesome icon
                           command: () => {
                            this.onMenuItemClick();
                            this.techSupport();
                          }
                       },
                        {
                            label: 'top-bar.donate',
                            icon: 'pi pi-fw pi-dollar',
                            routerLink: ['/make-payment'],
                            queryParams: {
                                paymentType: 'Donation',
                                targetType: 'DonationToOpOp',
                                targetId: 'e82327c4-fc37-4458-ae78-36360db2ed79',
                                beneficiaryId: 'e82327c4-fc37-4458-ae78-36360db2ed79',
                                projectId: '327330dc-cb47-4a6a-8fa0-201ad6bd2c1d',
                                title: '',  // this.translate.instant('donation.donationToPlatform'),
                                description:  '',  //this.translate.instant('donation.donationToPlatform')
                            }
                        },

                ],
            },
        ];
        if (this.authService.userDetails?.role === Role.DONOR) {
            console.log('-->>> AddingTheMenueItem for Donor.. ');
            this.mobileModel[0].items.push({
                label: 'menu.donor-dashboard',
                icon: 'pi pi-fw pi-list',
                routerLink: ['db-donor/donor-dashboard'],
                command: () => this.onMenuItemClick()
            });
        }
    }
}
