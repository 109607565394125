export const environment = {
    production: false,
    ENV: 'DEV',
    LOCAL_STORAGE_TOKEN: 'opop_user_token_dev',
    USER_DETAILS: 'opop_user_details_dev',
    PAY_TO_MERCHANT: '1000002125',
    MERCHANT_NAME: 'КОНЕКТ Скопје – ОПОП',
    DOMAIN: 'https://opop.vision2digital.com/',
    API_REF: 'opop-development-api',
    WS_REF: 'opop-dev-ws',
};
