<div class="surface-0" style="top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="contactPersonForm">
        <div class="surface-0 pt-4 pl-4 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-0">
                <img [src]="'assets/opop/logo.png'" alt='' class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div class="surface-0" style="margin-left: 15%; margin-right: 15%; margin-top:0%; margin-bottom:0%;">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>

        <div class="flex align-items-center flex-column">
            <div class="flex flex-column w-full md:w-7 sm:w-full h-full py-6 px-4 align-items-center  ">
                <div class="personGroupContainer">
                    <div class="personGroupTitle">{{'login.contactPersonGroup' | translate}}</div>
                    <div class="personGroupBox">

                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="firstName">{{'entity.person.personFirstName' | translate}}</label>
                            </span>
                            <input id="firstName" formControlName="firstName" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="lastName">{{'entity.person.personLastName' | translate}}</label>
                            </span>
                            <input id="lastName" formControlName="lastName" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-phone mr-2"></i>
                                <label for="phone">{{'entity.person.personPhone' | translate}}</label>
                            </span>
                            <input id="phone" formControlName="phone" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-2 mb-0">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-at mr-2"></i>
                                <label for="email">{{'entity.person.personEmail' | translate}}</label>
                            </span>
                            <input id="email" formControlName="email" type="text" pInputText autocomplete="off" (input)="onEmailInput()"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>
                        <div *ngIf="emailExists" class="flex text-red-700 mb-3 md:text-l">{{'msg.emailUsed' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </form>


    <form [formGroup]="signaturePersonForm">
        <div class="flex align-items-center flex-column">
            <div class="flex flex-column w-full md:w-7 sm:w-full h-full  px-4 align-items-center ">
            <div class="personGroupContainer">
                    <div class="personGroupTitle">{{'login.signingPersonGroup' | translate}}</div>
                    <div class="personGroupBox">
                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="firstName_signaturePerson">{{'entity.person.personFirstName' | translate}}</label>
                            </span>
                            <input id="firstName_signaturePerson" formControlName="firstName" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-tag mr-2"></i>
                                <label for="lastName_signaturePerson">{{'entity.person.personLastName' | translate}}</label>
                            </span>
                            <input id="lastName_signaturePerson" formControlName="lastName" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-phone mr-2"></i>
                                <label for="phone_signaturePerson">{{'entity.person.personPhone' | translate}}</label>
                            </span>
                            <input id="phone_signaturePerson" formControlName="phone" type="text" pInputText autocomplete="off"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>

                        <div class="p-inputgroup mt-2 mb-2">
                            <span class="p-inputgroup-addon w-10rem justify-content-start">
                                <i class="pi pi-at mr-2"></i>
                                <label for="email_signaturePerson">{{'entity.person.personEmail' | translate}}</label>
                            </span>
                            <input id="email_signaturePerson" formControlName="email" type="text" pInputText autocomplete="off"  (input)="onEmailInput()"/>
                            <span class="red-star-icon">&#9733;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="center-div">
        <button
            (click)="submit()"
            pButton pRipple
            label="{{'btn.submit' | translate}}"
            class="w-14rem mt-5 mb-4"
            icon="pi pi-arrow-right"
            [disabled]="contactPersonForm.invalid || signaturePersonForm.invalid || emailExists">
        </button>
    </div>

    <app-config [minimal]="true"></app-config>
</div>
