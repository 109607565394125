import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../../services/user.service";
import {Beneficiary} from "../../../../model/beneficiary";
import {DocumentService} from "../../../../services/document.service";
import {beneficiarySteps} from "./beneficiary-steps";
import {DocumentType} from "../../../../enums/document-type";
import {OpopDocumentUploadComponent} from "../../../../components/generics/opop-document-upload.component";
import {errorHandler} from "../../../../support/functions";

@Component({
    templateUrl: './beneficiary-register-step5.component.html',
    styles: [`body {
        background: var(--surface-ground);
    }`, `
      .p-fileupload-choose {
        display: none !important;
      }

      .p-fileupload .p-fileupload-buttonbar {
        border: 0;
      }

      .p-fileupload .p-fileupload-content {
        -moz-border-radius: 8px;
        -webkit-border-radius: 8px;
        padding: 0
      }
    `],
    encapsulation: ViewEncapsulation.None
})
export class BeneficiaryRegisterStep5Component implements OnInit {
    public form: UntypedFormGroup;
    protected readonly DocumentType = DocumentType;
    items: MenuItem[];
    public readonly beneficiary: Beneficiary;
    @ViewChild('tekovnaSmetka') tekovnaSmetka: OpopDocumentUploadComponent;


    constructor(public layoutService: LayoutService, private router: Router, private translate: TranslateService,
                private confirmationService: ConfirmationService, public documentService: DocumentService) {
        this.items = beneficiarySteps(this.translate);
        this.beneficiary = this.router.getCurrentNavigation().extras?.state?.beneficiary;

        this.form = new UntypedFormGroup({
            documentIds: new UntypedFormControl([]),
            documents: new UntypedFormControl([])
        });
    }

    ngOnInit(): void {
        if (!this.beneficiary) {
            this.router.navigate(['/'])
        }
    }

    submit() {
        if (this.beneficiary) {
            this.router.navigateByUrl(`auth/register/beneficiary/step6`,
                {state: {beneficiary: this.beneficiary}})
        }
        // this.confirmationService.confirm({
        //     message: this.translate.instant('msg.contractUploadSuccess'),
        //     rejectVisible: false,
        //     closeOnEscape: false,
        //     acceptLabel: 'OK',
        //     icon: 'pi pi-info-circle',
        //     accept: () => this.router.navigate(['/']),
        //     reject: () => this.router.navigate(['/'])
        // });
    }
}

