import {Component, EventEmitter, ElementRef, ViewChild,  Output} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {AppSidebarComponent} from './app.sidebar.component';
import {AuthService} from "../support/auth.service";
import {MenuItem} from "primeng/api";
import {environment} from "../../environments/environment";
import {Router} from '@angular/router';
import {DialogService,DynamicDialogRef} from "primeng/dynamicdialog";
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';
import {LanguageService} from 'src/app/components/generics/language.service';
import {DonateProjectComponent} from "../components/home/donate-project.component";
import { HostListener } from '@angular/core';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    activeItem!: number;
    menuItems: MenuItem[];
    public daysRemaining: number;
    ref: DynamicDialogRef | undefined;
    @Output()
    onSearch = new EventEmitter();

    isDesktop: boolean = true;

    preparedData: any;
    showPaymentComponent=false;

    items = [
        {label: 'Update', icon: 'pi pi-refresh', command: () => {
                this.call1();
            }},
        {label: 'Delete', icon: 'pi pi-times', command: () => {
                this.call2();
            }},
        {label: 'React', icon: 'pi pi-fw pi-external-link', command: () => {
                this.call3();
        }},
    ];

    constructor(public layoutService: LayoutService,
                public languageService: LanguageService,
                public el: ElementRef,
                private cdr: ChangeDetectorRef,
                public authService: AuthService,
                private dialogService: DialogService,
                public translate: TranslateService,
                private router: Router) {
        this.onResize();
        const savedLanguageCode = localStorage.getItem('language') || 'en';
        this.selectedLanguage = this.languages.find(lang => lang.code === savedLanguageCode) || this.languages[0];


        this.showPaymentComponent = false;
        this.menuItems = [ ];


        // if (this.authService.isAuthenticated) {
        //     this.menuItems.push(
        //         {
        //             label: 'Chat',
        //             icon: 'pi pi-pencil',
        //             command: () => this.dialogService.open(ChatAppComponent, {width: '1200px'})
        //         }
        //     );
        // }

    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }
    call1() {
        // Save action
    }
    call2() {
        // Save action
    }
    call3() {
        // Save action
    }

    languages = [
        {label: 'EN', code: 'en', icon: '/assets/opop/EN_SQUARE.png'},
        {label: 'MK', code: 'mk', icon: '/assets/opop/MK_SQUARE.png'},
        {label: 'AL', code: 'al', icon: '/assets/opop/AL_SQUARE.png'}
    ];
    selectedLanguage = this.languages[0];
    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    changeLanguage(language: string): void {
        this.translate.use(language);
        this.languageService.setSelectedLanguage(this.selectedLanguage);
        this.cdr.detectChanges();
    }
    get env() {
        return environment.ENV;
    }
    donate() {
        this.ref = this.dialogService.open(DonateProjectComponent, {
            width: '600px',
            data: 'ProvidetData1',
            header: `Donate To OpOP`
        });

        this.ref.onClose.subscribe(donated => {
            if (donated) {
                this.onSearch.emit();
            }
        });
    }

    // makePayment() {
    //     console.log("=====>>> makePayment");
    //     console.log ("===>BeneficiaryName", 'OpOp-KonektID',);
    //     console.log ("===>BeneficiaryID", 'Platform OpOp');
    //
    //     this.router.navigate(
    //         ['/make-payment',
    //             {
    //                 paymentType: 'donation',
    //                 targetType:  this.translate.instant('donation.donationToPlatform'),
    //                 targetId:    'c66c5800-9ef2-49b1-ab83-613a2b94034d',
    //                 title:       '',
    //                 description: 'Donation To OpOp'
    //             }
    //         ]
    //     );
    // }

    // makePaymentNEW() {
    //     console.log ("=========>>>>> makePayment");
    //     this.preparedData = {
    //         paymentType: 'Donation',
    //         targetType: this.translate.instant('donation.donationToPlatform'),
    //         targetId:'c66c5800-9ef2-49b1-ab83-613a2b94034d',
    //         title: ' ',//this.translate.instant('donation.donationToPlatform'),
    //         description: ''
    //     };
    //     this.showPaymentComponent = true;
    //     // this.router.navigate(['/make-payment-fp'], { state: { data: this.preparedData } });
    // }

    makePayment() {
        this.preparedData = {
            // check if loggedIn user is donor and if it is not then show error.

            // ************ PRODUCTION ********************
            // OpOp Production account is:
            // beneficiaryId:'179228e3-775f-4f3f-9a10-a30c13778067',
            // projectId:'746cddd9-e250-49d8-a770-1b3a04179871',

            // *********** STAGING ***********************
            // OpOp Staging account is:
            // beneficiaryId:'e82327c4-fc37-4458-ae78-36360db2ed79',
            // projectId:'327330dc-cb47-4a6a-8fa0-201ad6bd2c1d',

            paymentType: 'Donation',
            targetType:'DonationToOpOp',
            targetId:'e82327c4-fc37-4458-ae78-36360db2ed79',
            beneficiaryId:'e82327c4-fc37-4458-ae78-36360db2ed79',
            projectId:'327330dc-cb47-4a6a-8fa0-201ad6bd2c1d',
            title: '',  // this.translate.instant('donation.donationToPlatform'),
            description: this.translate.instant('donation.donationToPlatform')
        };
        this.showPaymentComponent = true;
    }

    // makePaymentWork() {
    //     this.preparedData = {
    //         paymentType: 'Donation',
    //         targetType: 'DonationToProject',
    //         targetId: this.project.id,
    //         title: this.project.title,
    //         description: this.translate.instant('donation.donationToProject')
    //     };
    //     this.showPaymentComponent = true;
    // }
    onCloseChild() {
        this.showPaymentComponent = false;
    }
}
