import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {ChatService} from "./chat.service";
import {AuthService} from "../../support/auth.service";
import {ChatMessage, ChatType} from "./chat-message";
import {Role} from "../../enums/role";
import {compareDatesWithoutTime} from "../../support/functions";

@Component({
    selector: 'app-chat-box',
    template: `

        <div class="flex flex-column h-full">
            <div class="flex align-items-center border-bottom-1 surface-border p-3 lg:p-6"
                 *ngIf="authService.hasAnyAuthority(authService.chatAdminRoles)">
                <div class="relative flex align-items-center mr-3">
                    <img src="assets/opop/user-avatar.png" alt="avatar"
                         class="w-4rem h-4rem border-circle shadow-4"/>
                </div>
                <div class="mr-2">
                    <span class="text-900 font-semibold block">{{ topic }}</span>
                </div>
            </div>
            <div class="p-3 md:px-4 lg:px-6 lg:py-4 mt-2 overflow-y-auto" style="max-height: 53vh" #chatWindow
                 [scrollTop]="chatWindow.scrollHeight">
                <div *ngFor="let message of (chatService.msgsMap$ | async).get(topic)">
                    <ng-container *ngIf="message?.type === 'CHAT'">
                        <div *ngIf="message.sender !== chatService.username" class="grid grid-nogutter mb-4">
                            <div class="mr-3 mt-1">
                                <img src="assets/opop/user-avatar.png" alt="user?.name"
                                     class="w-3rem h-3rem border-circle shadow-4"/>
                            </div>
                            <div class="col mt-3">
                                <p class="text-900 font-semibold mb-3">{{ message.isAdmin ? 'Administrator' : message.sender }}</p>
                                <span
                                    class="text-700 inline-block font-medium border-1 surface-border p-3 white-space-normal border-round"
                                    style="word-break: break-word; max-width: 80%">{{ message.content }}</span>
                                <p class="text-700 mt-3">{{ message.timestamp | date: getDatePipe(message) }}<i
                                    class="pi pi-check ml-2 text-green-400"></i></p>
                            </div>
                        </div>

                        <div *ngIf="message.sender === chatService.username" class="grid grid-nogutter mb-4">
                            <div class="col mt-3 text-right">
                                <p class="text-900 font-semibold mb-3">{{ message.isAdmin ? 'Administrator' : message.sender }}</p>

                                <span
                                    class="inline-block text-left font-medium border-1 surface-border bg-primary-100 text-primary-900 p-3 white-space-normal border-round"
                                    style="word-break: break-word; max-width: 80%">{{ message.content }}</span>
                                <p class="text-700 mt-3">{{ message.timestamp | date: getDatePipe(message) }} <i
                                    class="pi pi-check ml-2 text-green-400"></i></p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div
                class="p-3 md:p-4 lg:p-6 flex flex-column sm:flex-row align-items-center mt-auto border-top-1 surface-border gap-3">
                <input id="message" #inputMessage type="text" pInputText placeholder="Type a message" autofocus
                       class="flex-1 w-full sm:w-auto border-round" [(ngModel)]="textContent"
                       (keydown.enter)="sendMessage()" [disabled]="!(chatService.isConnected$ | async)"/>
                <button pButton pRipple label="Send" icon="pi pi-send" class="w-full sm:w-auto"
                        (click)="sendMessage()" [disabled]="!(chatService.isConnected$ | async)"></button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBoxComponent implements OnInit, AfterViewChecked, AfterViewInit {
    textContent: string = '';
    @Input() topic: string;
    protected readonly Role = Role;
    @ViewChild('chatWindow', {static: false}) chatWindow: ElementRef;
    @ViewChild('inputMessage', {static: false}) inputMessage: any;

    constructor(public chatService: ChatService, public authService: AuthService) {

    }

    ngOnInit(): void {
    }

    ngAfterViewChecked() {
        if (this.chatWindow.nativeElement) {
            this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
        }
    }

    ngAfterViewInit() {
        this.inputMessage.nativeElement.focus();
    }

    sendMessage() {
        if (!this.textContent || this.textContent.trim() == '') {
            return;
        }

        const chatMessage = {
            topic: this.topic,
            sender: this.chatService.username,
            content: this.textContent,
            timestamp: new Date(),
            type: ChatType.CHAT,
            isAdmin: this.authService.hasAnyAuthority(this.authService.chatAdminRoles)
        };

        this.chatService.sendMessage(chatMessage);
        this.textContent = '';
    }

    getDatePipe(message: ChatMessage) {
        return compareDatesWithoutTime(message.timestamp, new Date()) === 0 ? 'HH:mm' : 'dd.MM.yyyy HH:mm';
    }
}
