import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {SharedModule as PrimeSharedModule} from "primeng/api";
import {GenericCompleteComponent} from "./generic-auto-complete.component";
import {AutoCompleteModule} from "primeng/autocomplete";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ImageGalleryComponent} from "./image-gallery.component";
import {GalleriaModule} from "primeng/galleria";
import {UploadComponent} from "./upload.component";
import {FileUploadModule} from "primeng/fileupload";
import {RippleModule} from "primeng/ripple";
import {InputTextareaModule} from "primeng/inputtextarea";
import {GenericDetailComponent} from "./generic-detail.component";
import {GenericFormCompleteComponent} from "./generic-form-auto-complete.component";
import {AppMessageComponent} from "../../layout/message.component";
import {ToastModule} from "primeng/toast";
import {OpopDocumentUploadComponent} from "./opop-document-upload.component";
import {TableModule} from "primeng/table";
import {PanelModule} from "primeng/panel";
import {OpopImageUploadComponent} from "./opop-image-upload.component";
import {RouterLink} from "@angular/router";
import {CpayFieldsComponent} from '../home/cpay-fields.component';
import {DocxProcessorComponent} from "../../document-processor/document-processor.component";
import {MakePaymentComponent} from "../../make-payment/make-payment.component";
//import {MakePaymentFPComponent} from "../../make-payment/make-payment-FP.component";
import {TooltipModule } from 'primeng/tooltip';
import {DropdownModule } from 'primeng/dropdown';
import {InputNumberModule } from 'primeng/inputnumber';
import {DonorService} from "../../services/donor.service";
import {BeneficiaryService} from "../../services/beneficiary.service";
import {TransactionService} from "../../services/transaction.service";
import {ProjectService} from "../../services/project.service";
import {Routes} from '@angular/router';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {YoutubePlayerComponent} from "../../youtube/youtube-player"




const routes: Routes = [
    {path:'make-payment', component: MakePaymentComponent}
];

@NgModule({
    imports: [TooltipModule, DropdownModule, InputNumberModule, CommonModule, PrimeSharedModule, AutoCompleteModule, FormsModule, TranslateModule,
        GalleriaModule, ReactiveFormsModule, FileUploadModule, RippleModule, InputTextareaModule,
        ToastModule, TableModule, PanelModule, RouterLink, ConfirmDialogModule, ConfirmPopupModule],
    providers: [YoutubePlayerComponent,DocxProcessorComponent, DonorService, BeneficiaryService,ProjectService,TransactionService],
    declarations: [
        YoutubePlayerComponent,DocxProcessorComponent, MakePaymentComponent, CpayFieldsComponent,  GenericCompleteComponent, GenericFormCompleteComponent,
        GenericDetailComponent, ImageGalleryComponent,UploadComponent, AppMessageComponent,
        OpopDocumentUploadComponent, OpopImageUploadComponent
    ],
    exports: [YoutubePlayerComponent,DocxProcessorComponent, MakePaymentComponent, CpayFieldsComponent,  CommonModule, FormsModule,
        ReactiveFormsModule, TranslateModule, GenericCompleteComponent, ImageGalleryComponent,
        UploadComponent, GenericFormCompleteComponent, AppMessageComponent,OpopDocumentUploadComponent,
        OpopImageUploadComponent]
})
export class SharedModule {
}
