<div class="surface-0" style="top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="surface-0 p-4 m-0 ">
            <a [routerLink]="['/']" class="mb-2">
                <img [src]="'assets/opop/logo.png'" class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div class="surface-0" style="margin-left: 15%; margin-right: 15%; margin-top:0%; margin-bottom:0%;">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>

        <div class="surface-0">
            <div class="flex align-items-center justify-content-between flex-column">
                <div class="flex flex-column w-full md:w-5 sm:w-full h-full py-6 px-4 align-items-center justify-content-center ">

                    <div class="p-inputgroup mt-2 mb-2">
                        <span class="p-inputgroup-addon w-12rem justify-content-start">
                            <i class="pi pi-tag mr-2"></i>
                            <label for="accountName">{{'entity.company.bankDetails.accountName' | translate}}</label>
                        </span>
                        <input id="accountName" formControlName="accountName" type="text" pInputText autocomplete="off"/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mt-2 mb-2">
                        <span class="p-inputgroup-addon w-12rem justify-content-start">
                            <i class="pi pi-tag mr-2"></i>
                            <label for="bankName">{{'entity.company.bankDetails.bankName' | translate}}</label>
                        </span>
                        <input id="bankName" formControlName="bankName" type="text" pInputText autocomplete="off"/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <div class="p-inputgroup mt-2 mb-2">
                        <span class="p-inputgroup-addon w-12rem justify-content-start">
                            <i class="pi pi-tag mr-2"></i>
                            <label for="accountNumber">{{'entity.company.bankDetails.accountNumber' | translate}}</label>
                        </span>
                        <input id="accountNumber" formControlName="accountNumber" type="text" pInputText autocomplete="off"/>
                        <span class="red-star-icon">&#9733;</span>
                    </div>

                    <button type="submit" pButton pRipple label="{{'btn.next' | translate}}" class="w-full mt-2  mb-4" icon="pi pi-arrow-right" [disabled]="form.invalid" [loading]="loading"></button>

                </div>
            </div>
        </div>
    </form>
</div>
<app-config [minimal]="true"></app-config>
