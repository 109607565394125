<p-confirmDialog header="Info" icon="pi pi-info-circle" [closable]="false"></p-confirmDialog>

<div class="surface-0 h-screen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">
    <div class="surface-0 pt-4 pl-4 pb-0 m-0 ">
        <a [routerLink]="['/']" class="mb-0">
            <img [src]="'assets/opop/logo.png'" alt='' class="login-logo" style="width: 100px; margin-left:20px"/>
        </a>
    </div>

    <div class="surface-0" style="margin: 0 5% 0 5%; ">
        <p-steps [model]="items" [readonly]="true"></p-steps>
    </div>

    <div class="flex align-items-center mt-8 flex-column" >
        <div class="centered-content">
        <form [formGroup]="form" (submit)="submit()">
            <app-opop-document-upload #tekovnaSmetka
                                      [form]="form"
                                      [beneficiaryId]="beneficiary?.id"
                                      [documentType]="DocumentType.CURRENT_ACCOUNT"
                                      documentIdsControlName="documentIds"
                                      documentsControlName="documents">
            </app-opop-document-upload>

            <button type="submit" icon="pi pi-arrow-right"
                    pButton label="{{'btn.next' | translate}}" class="pt-3 mt-6 pb-3 block w-full"
                    [disabled]="form.controls.documents.value.length === 0"></button>
        </form>
        </div>
    </div>
</div>
