import {Person, PersonCreate, PersonUpdate, toPersonCreate, toPersonUpdate} from "./person";
import {Company, CompanyCreate, CompanyUpdate, toCompanyCreate, toCompanyUpdate} from "./company";
import {User} from "./user";
import {BeneficiaryCategory } from "./beneficiary-category";
import {Otp} from "./otp";
import {Project} from "./project";
import {BeneficiaryStatus} from "../enums/beneficiary-status";
import {Document} from "./document";
import {Transaction} from "./transaction";

export interface Beneficiary {
    id: string;
    beneficiaryStatus: BeneficiaryStatus;
    company: Company;
    director: Person;
    totalFunds: number;
    totalFundsUsed: number;
    totalFundsCanceled: number;
    totalFundsReserved: number;
    trusted:number;
    uniqueId:string;
    beneficiaryCategory: BeneficiaryCategory;
    documents: Document[];
    projects: Project[];
    founders: Person[];
    user: User;
    otp: Otp;
    createdAt: Date;
    lastUpdatedAt: Date;
    directProjectId:string;
    donations:Transaction[];
    sumReservedDonations:number;
}

export interface BeneficiaryCreate {
    company: CompanyCreate;
    director: PersonCreate;
    beneficiaryCategory: BeneficiaryCategory;
    trusted:number;
    documentIds: string[];
}

export interface BeneficiaryUpdate {
    id: string;
    company: CompanyUpdate;
    director: PersonUpdate;
    beneficiaryCategory: BeneficiaryCategory;
    trusted:number;
    documentIds: string[];
}

export function toBeneficiaryCreate(beneficiary: Beneficiary) {
    const companyCreate = toCompanyCreate(beneficiary?.company)
    const director = beneficiary?.director ? toPersonCreate(beneficiary?.director) : null;

    return {
        id: beneficiary.id,
        company: companyCreate,
        director: director,
        trusted: beneficiary.trusted,
        beneficiaryCategory: beneficiary?.beneficiaryCategory,
        documentIds: beneficiary.documents?.map(doc => doc.id)
    } as BeneficiaryCreate;

}

export function toBeneficiaryUpdate(beneficiary: Beneficiary) {
    const companyUpdate = beneficiary?.company ? toCompanyUpdate(beneficiary?.company): null
    const director = beneficiary?.director ? toPersonUpdate(beneficiary?.director) : null;

    return {
        id: beneficiary.id,
        company: companyUpdate,
        director: director,
        beneficiaryCategory: beneficiary?.beneficiaryCategory,
        trusted: beneficiary?.trusted
    } as BeneficiaryUpdate;

}
