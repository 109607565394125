

<p-confirmDialog header="Info" icon="pi pi-info-circle" [closable]="false"></p-confirmDialog>

<div class="surface-0" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">
    <div class="surface-0 pt-4 pl-4 pb-0 m-0 ">
        <a [routerLink]="['/']" class="mb-0">
            <img [src]="'assets/opop/logo.png'" alt='' class="login-logo" style="width: 100px; margin-left:20px"/>
        </a>
    </div>

    <div class="surface-0" style="margin-left: 15%; margin-right: 15%; margin-top:0%; margin-bottom:0%;">
        <p-steps [model]="items" [readonly]="true"></p-steps>
    </div>

    <div class="flex align-items-center justify-content-between flex-column" style="margin:80px">
        <form [formGroup]="form" (submit)="submit()">
            <app-docx-processor [params]="params" (documentGenerated)="onDocumentGenerated($event)">
            </app-docx-processor>

            <button pButton type="button"
                    class="pt-3 pb-3 block w-full"
                    style="margin-top: 20px;"
                    label="{{'btn.continueToOpOp' | translate}}"
                    (click)="closeTheWindow()">
            </button>


        </form>
    </div>
</div>
