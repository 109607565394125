import {DocumentService} from "../../services/document.service";
import {UntypedFormGroup} from "@angular/forms";
import {Component, Input, OnInit, ViewChild,OnChanges} from "@angular/core";
import {FileUpload} from "primeng/fileupload";
import {ConfirmationService, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {firstValueFrom} from "rxjs";
import {Document} from "../../model/document";
import {confirmAction, errorHandler} from "../../support/functions";
import {Router} from "@angular/router";
import {DocumentType} from "../../enums/document-type";

@Component({
    selector: 'app-opop-document-upload',
    styles: [],
    templateUrl:'opop-document-upload.component.html'
})

export class OpopDocumentUploadComponent implements OnInit, OnChanges {
    @Input() generatedDocument: Blob;
    @Input() documentType: DocumentType;
    @Input() form: UntypedFormGroup;
    @Input() documentsControlName: string;
    @Input() documentIdsControlName: string;
    @Input() beneficiaryId: string;
    @Input() donorId: string;
    @Input() projectId: string;
    @Input() imagesOnly: boolean;
    @Input() readonly: boolean;
    @Input() projectEndDate: Date;
    @ViewChild('fileUploader') fileUpload: FileUpload;
    accept: string;
    documentsByType: Document[];
    isUploadDisabled: boolean = true;

    constructor(private documentService: DocumentService,
                private msgService: MessageService,
                private translate: TranslateService,
                private router: Router,
                private confirmationService: ConfirmationService) {
        this.accept = !this.imagesOnly ? '.pdf' : '.jfif';

    }

    ngOnInit(): void {
        this.checkIfUploadShouldBeDisabled();
    }


    ngOnChanges(): void {
        this.checkIfUploadShouldBeDisabled();
        if (this.generatedDocument) {
        }
        else {
            // console.log("-> 3. NO document");
        }
    }

    private checkIfUploadShouldBeDisabled() {
        const currentDate = new Date();
        if (this.readonly ) {
            this.isUploadDisabled=true;
        }
        else {
            this.isUploadDisabled = currentDate < new Date(this.projectEndDate);
        }
    }

    handleFileUpload(event: any) {
        const formData = new FormData();
        for (let file of event.files) {
            formData.append('files', file, file.name);
        }


        this.documentService.uploadDocument(formData, this.documentType, this.beneficiaryId).subscribe({
            next: response => {
                if (response.body) {
                    this.msgService.add({

                        severity: 'info', life: 7000,
                        detail: this.translate.instant('msg.upload-success')
                    });
                    this.form.controls[this.documentsControlName].setValue([...this.form.controls[this.documentsControlName].value, ...response.body]);
                    this.form.controls[this.documentIdsControlName].value.push(response.body[0].id);
                    this.initDocumentsByDocumentType();
                    console.log (this.form);
                    console.log (this.beneficiaryId);
                    this.fileUpload.clear();
                }
            }, error: error => {
                this.msgService.add({
                    severity: 'error',
                    summary: this.translate.instant('error.header'),
                    detail: error.message
                });
            }
        });
    }

    handleDonorFileUpload(event: any) {
        const formData = new FormData();
        for (let file of event.files) {
            formData.append('files', file, file.name);
        }

        this.documentService.uploadDonorDocument(formData, this.documentType, this.donorId).subscribe({
            next: response => {
                if (response.body) {
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('msg.upload-success')
                    });
                    this.form.controls[this.documentsControlName].setValue([...this.form.controls[this.documentsControlName].value, ...response.body]);
                    this.form.controls[this.documentIdsControlName].value.push(response.body[0].id);
                    this.initDocumentsByDocumentType();
                    this.fileUpload.clear();
                }
            }, error: error => {
                this.msgService.add({
                    severity: 'error',
                    summary: this.translate.instant('error.header'),
                    detail: error.message
                });
            }
        });
    }

    deleteDocument(doc: Document) {
        firstValueFrom(this.documentService.deleteDocument(doc.id)).then(() => {
            const indexToDelete = this.form.controls[this.documentsControlName].value.indexOf(doc);
            this.form.controls[this.documentsControlName].value.splice(indexToDelete, 1);

            const relationIndexToDelete = this.form.controls[this.documentIdsControlName].value.indexOf(doc.id);
            this.form.controls[this.documentIdsControlName].value.splice(relationIndexToDelete, 1);
            this.initDocumentsByDocumentType();

            this.msgService.add({
                severity: 'info',
                summary: 'Info',
                detail: this.translate.instant('msg.fileDeleteSuccess')
            });
        }, error => errorHandler('Error', error, this.msgService, this.translate, this.router))
    }

    async confirmDelete(doc: Document) {
        const confirm = await confirmAction(this.confirmationService, this.translate);
        if (confirm) {
            this.deleteDocument(doc);
        }
    }

    downloadDocument(id: string, fileName: string) {
        firstValueFrom(this.documentService.downloadDocument(id)).then((blob: Blob) => {
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloadLink.remove();
        });
    }

    initDocumentsByDocumentType() {
        this.documentsByType = this.form.controls[this.documentsControlName].value.filter(doc => doc.documentType === this.documentType);
    }

    get hasDocs(): string{
        return this.documentsByType?.length > 0 ? 'opopUpload-docs' : 'opopUpload-noDocs';
    }
}
