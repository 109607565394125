import { Beneficiary } from './beneficiary';
import { ProjectStatus } from '../enums/project-status';
import { Municipality } from './municipality';
import {Donation} from "./donation";
import {Transaction} from "./transaction";
export interface Project {
    id: string;
    title: string;
    createdAt: Date;
    shortDescription: string;
    description: string;
    costsDescription: string;
    raisedAndCancelledDescription: string;
    donorBenefits: string;
    startDate: Date;
    endDate: Date;
    startRealizationDate: Date;
    endRealizationDate: Date;
    daysLeft: number;
    publishedDate: Date;
    startedOnDate: Date;
    minFunds: number;
    totalFunds: number;
    raisedFunds: number;
    paidFunds: number;
    remainingFunds: number;
    owner: Beneficiary;
    category: ProjectCategory;
    wholeCountry: boolean;
    municipalities: Municipality[];
    projectStatus: ProjectStatus;
    documentIds: string[];
    projectStatusChangedOn: Date;
    projectStatusHistory: ProjectStatusHistory[];
    lastUpdatedAt: Date;
    imageId: string;
    tags: string[];
    donations:Transaction[];
    uniqueId:string;
    videoUrl:string;
}

export interface ProjectCategory {
    id: number;
    name: string;
    description: string;
}

export interface ProjectStatusHistory {
    projectUuid: string;
    projectStatus: ProjectStatus;
    previousProjectStatus: ProjectStatus;
    createdAt: Date;
}

export interface ProjectUpdate {
    id: string;
    title: string;
    shortDescription: string;
    description: string;
    costsDescription: string;
    raisedAndCancelledDescription: string;
    donorBenefits: string;
    startDate: Date;
    endDate: Date;
    startRealizationDate: Date;
    endRealizationDate: Date;
    minFunds: number;
    totalFunds: number;
    category: ProjectCategory;
    municipalities: Municipality[];
    tags: string[];
}

export interface ProjectCreate {
    beneficiaryId: string;
    title: string;
    shortDescription: string;
    description: string;
    costsDescription: string;
    raisedAndCancelledDescription: string;
    donorBenefits: string;
    minFunds: number;
    totalFunds: number;
    startDate: Date;
    endDate: Date;
    startRealizationDate: Date;
    endRealizationDate: Date;
    publishedDate: Date;
    startedOnDate: Date;
    category: ProjectCategory;
    municipalities: Municipality[];
    documentIds: string[];
    imageId: string;
    videoUrl:string;
    tags: string[];
}

export function toUpdateFields(project: Project) {
    return {
        id: project.id,
        title: project.title,
        shortDescription: project.shortDescription,
        description: project.description,
        costsDescription: project.costsDescription,
        raisedAndCancelledDescription: project.raisedAndCancelledDescription,
        donorBenefits: project.donorBenefits,
        startDate: project.startDate,
        endDate: project.endDate,
        startRealizationDate: project.startRealizationDate,
        endRealizationDate: project.endRealizationDate,
        minFunds: project.minFunds,
        totalFunds: project.totalFunds,
        category: project.category,
        municipalities: project.municipalities,
        // municipality: project.municipality,
        documentIds: project.documentIds,
        imageId: project.imageId,
        videoUrl:project.videoUrl,
        tags: project.tags,
    } as ProjectUpdate;
}

export function toCreateFields(project: Project) {
    return {
        beneficiaryId: project.owner?.id,
        title: project.title,
        wholeCountry: project.wholeCountry,
        shortDescription: project.shortDescription,
        description: project.description,
        costsDescription: project.costsDescription,
        raisedAndCancelledDescription: project.raisedAndCancelledDescription,
        donorBenefits: project.donorBenefits,
        minFunds: project.minFunds,
        totalFunds: project.totalFunds,
        startDate: project.startDate,
        endDate: project.endDate,
        startRealizationDate: project.startRealizationDate,
        endRealizationDate: project.endRealizationDate,
        publishedDate: project.publishedDate,
        startedOnDate: project.startedOnDate,
        category: project.category,
        municipalities: project.municipalities,
        documentIds: project.documentIds,
        imageId: project.imageId,
        videoUrl:project.videoUrl,
        tags: project.tags,
    } as ProjectCreate;
}
