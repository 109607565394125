export const staticDonorCategories = [
    {name: 'Agriculture & Forestry', id: 1},
    {name: 'Automotive', id: 2},
    {name: 'Business Services', id: 3},
    {name: 'Construction & Real Estate', id: 4},
    {name: 'Consumer Goods & Services', id: 5},
    {name: 'Education', id: 6},
    {name: 'Energy & Utilities', id: 7},
    {name: 'Financial Services', id: 8},
    {name: 'Healthcare', id: 9},
    {name: 'Hospitality & Travel', id: 10},
    {name: 'Information Technology', id: 11},
    {name: 'Manufacturing & Engineering', id: 12},
    {name: 'Media & Entertainment', id: 13},
    {name: 'Non-profit & Governmental Organizations', id: 14},
    {name: 'Telecommunications', id: 15},
    {name: 'Transportation & Logistics', id: 16},
    {name: 'Wholesale & Distribution', id: 17}
];
