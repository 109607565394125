import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {elementSelectors} from "@angular/cdk/schematics";

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSidebarComponent {
    timeout: any = null;
    @ViewChild('menuContainer') menuContainer!: ElementRef;

    constructor(public layoutService: LayoutService, public el: ElementRef) {}

    onMouseEnter() {
        console.log('=====>>>>> MouseEnter');
        if (!this.layoutService.state.anchored) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.layoutService.state.sidebarActive = true;
        }
    }

    onMouseLeave() {
        console.log('=====>>>>> MouseLeave');
        if (!this.layoutService.state.anchored) {
            if (!this.timeout) {
                console.log('NO Timeout -> MouseLeave');
                this.layoutService.state.sidebarActive = false;
                this.timeout = setTimeout(() => {
                    this.layoutService.state.sidebarActive = false;
                }, 300);
            }
            else {
                console.log('YES Timeout -> MouseLeave');
            }
        }
    }

    anchor() {
        this.layoutService.state.anchored = !this.layoutService.state.anchored;
        if (this.layoutService.state.anchored) {
            this.layoutService.state.sidebarActive = true;
        }
    }

    onMenuItemClick() {
        console.log ('--->>> MenueItemClick');
        this.layoutService.state.sidebarActive = false;
        this.layoutService.closeSidebar();
    }
}
