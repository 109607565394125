import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {DonorService} from "../../../../services/donor.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, MessageService} from "primeng/api";
import {Donor} from "../../../../model/donor";
import {BankService} from "../../../../services/bank.service";
import {donorOrganizationSteps} from "./donor-organization-steps";
import {firstValueFrom} from "rxjs";

@Component({
    templateUrl: './donor-organization-bank-details.component.html'
})
export class DonorRegisterOrganizationBankDetailsComponent {
    public form: UntypedFormGroup;
    items: MenuItem[];
    private readonly donor: Donor;
    loading: boolean;

    constructor(private donorService: DonorService, private router: Router, private translate: TranslateService,
                private msgService: MessageService, private bankService: BankService) {
        this.items = donorOrganizationSteps(this.translate);
        this.donor = this.router.getCurrentNavigation().extras?.state?.donor;

        this.form = new UntypedFormGroup({
            accountName: new UntypedFormControl(null, Validators.required),
            bankName: new UntypedFormControl(null, Validators.required),
            address: new UntypedFormControl(null),
            zip: new UntypedFormControl(null),
            accountNumber: new UntypedFormControl(null, Validators.required),
            iban: new UntypedFormControl(null),
            swift: new UntypedFormControl(null),
            inactive: new UntypedFormControl(false)
        });
    }

    submit() {
        this.loading = true;
        this.donor.company.bank = this.form.getRawValue();

        firstValueFrom(this.donorService.create(this.donor))
            .then(result => {
                    this.router.navigateByUrl(`auth/register/donor/step2`, {state: {donor: result}});
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this.msgService.add({
                        severity: 'error', life: 6000,
                        summary: 'Error',
                        detail: this.translate.instant(error?.message),
                    });
                }
            );
    }
}
