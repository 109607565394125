<!--<iframe [src]="videoUrl"-->
<!--        width="560"-->
<!--        height="315"-->
<!--        frameborder="0"-->
<!--        allowfullscreen-->
<!--        class="video-frame">-->
<!--</iframe>-->
<div class="video-wrapper">
    <iframe [src]="videoUrl"
            frameborder="0"
            allowfullscreen
            class="video-frame">
    </iframe>
</div>
