import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CompanyType} from "../../../enums/company-type";
import {BeneficiaryCategory} from "../../../model/beneficiary-category";
import {CompanyCategory} from "../../../model/company-category"
import {CompanySubcategory} from "../../../model/company-subcategory";

const defaultCompanyCategory: CompanyCategory = {
        id: 2,
        name: 'ГРАЃАНСКО ОПШТЕСТВО'
};

const defaultCompanySubcategory: CompanySubcategory = {
        id: 1,
        name: 'Развој на граѓанско општество'
};

export function companyRegisterForm() {
    return new UntypedFormGroup(
        {
            companyName: new UntypedFormControl(null, Validators.required),
            companyShortName: new UntypedFormControl(null),
            companyVat: new UntypedFormControl(null, Validators.required),
            companyRegNr: new UntypedFormControl(null, Validators.required),
            companyStreetAddress: new UntypedFormControl(null, Validators.required),
            companyMunicipality: new UntypedFormControl(null, Validators.required),
            companyPhone: new UntypedFormControl('389', Validators.required),
            companyRegistrationDate: new UntypedFormControl(null),
                companyCategory: new UntypedFormControl(defaultCompanyCategory, Validators.required),
                companySubcategory: new UntypedFormControl(defaultCompanySubcategory, Validators.required),
            companyType: new UntypedFormControl(CompanyType.BENEFICIARY),
            companyInfoMail: new UntypedFormControl(null, [Validators.required, Validators.email]),
            companyWww: new UntypedFormControl(null),
            inactive: new UntypedFormControl(false),
            companyCountry: new UntypedFormControl(null),
            companyZip: new UntypedFormControl(null),
            companyCity: new UntypedFormControl(null),
        });
}
