<div class="dialog-content"
     style="margin-bottom: 350px;">
    <div class="horizontal-line-thick-margins-white"></div>
    <span [innerHTML]="'footer.termsAndConditions' | translate"></span>

    <p-footer class="no-margins-footer">
        <div class="terms-button-container" >
            <button *ngIf="isDesktop" type="button"
                    class="mt-1  ml-2 mailer-buttons"
                    pButton
                    (click)="downloadTermsAndConditions()"
                    style="border-radius: 4px !important; min-width:280px !important;"
                    label="{{'footer.termsDownload'|translate}}">
                <i class="fas fa-cloud-download-alt" style="color:white; font-size:1.4rem; padding:0;  margin-right:10px;"></i>
            </button>

            <div class="terms-right-buttons">
                <button type="button"
                        class="mt-1  ml-2 mailer-buttons"
                        pButton
                        (click)="onDecline()"
                        style="border-radius: 4px !important;"
                        label="{{'footer.termsDecline'|translate}}">
                        <i class="fas fa-thumbs-down" style="color:white; font-size:1.4rem; padding:0;  margin-right:10px;"></i>
                </button>
                <button type="button"
                        class="mt-1  ml-2 mailer-buttons"
                        pButton
                        (click)="onAccept()"
                        style="border-radius: 4px !important;"
                        label="{{'footer.termsAccept'|translate}}">
                    <i class="fas fa-check" style="color:white; font-size:1.4rem; padding:0; margin-right:10px;"></i>
                </button>
            </div>
        </div>
    </p-footer>
</div>
