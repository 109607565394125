import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApiService } from '../components/generics/generic-api.service';
import { DocumentType } from '../enums/document-type';
import { environment } from '../../environments/environment';
import { Document } from '../model/document';

@Injectable()
export class DocumentService extends GenericApiService<any> {
    override serviceUrl = `/${environment.API_REF}/documents`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public uploadContract(
        formData: FormData,
        beneficiaryId: string
    ): Observable<any> {
        return this.http
            .post<any>(
                `${this.serviceUrl}/upload?beneficiaryId=${beneficiaryId}&documentType=${DocumentType.CONTRACT}`,
                formData,
                {
                    reportProgress: true,
                    observe: 'events',
                }
            )
            .pipe(map((res) => this.parse(res)));
    }


    public uploadDocument(
        formData: FormData,
        documentType: DocumentType,
        beneficiaryId?: string,
        companyId?: string,
        articleId?: string
    ): Observable<any> {
        let params = `?documentType=${documentType}`;

        if (beneficiaryId) {
            params += `&beneficiaryId=${beneficiaryId}`;
        }

        if (companyId) {
            params += `&companyId=${companyId}`;
        }

        if (articleId) {
            params += `&articleId=${articleId}`;
        }

        return this.http
            .post<any>(`${this.serviceUrl}/upload${params}`, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(map((res) => this.parse(res)));
    }

    public uploadDonorDocument(
        formData: FormData,
        documentType: DocumentType,
        donorId?: string,
        companyId?: string
    ): Observable<any> {
        let params = `?documentType=${documentType}`;

        if (donorId) {
            params += `&donorId=${donorId}`;
        }

        if (companyId) {
            params += `&companyId=${companyId}`;
        }

        return this.http
            .post<any>(`${this.serviceUrl}/upload${params}`, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(map((res) => this.parse(res)));
    }

    public getProjectDocuments(projectId: string): Observable<Document[]> {
        return this.http
            .get<Document[]>(`${this.serviceUrl}/${projectId}/project`)
            .pipe(map((res) => this.parse(res)));
    }

    public getProjectImageDocuments(projectId: string): Observable<Document[]> {
        return this.http
            .get<Document[]>(
                `${this.serviceUrl}/${projectId}/project?imagesOnly=true`
            )
            .pipe(map((res) => this.parse(res)));
    }

    public getCompanyImageDocuments(companyId: string): Observable<Document[]> {
        return this.http
            .get<Document[]>(
                `${this.serviceUrl}/${companyId}/company?imagesOnly=true`
            )
            .pipe(map((res) => this.parse(res)));
    }

    public deleteDocument(documentId: string): Observable<void> {
        return this.http
            .delete<any>(`${this.serviceUrl}/${documentId}`)
            .pipe(map((res) => this.parse(res)));
    }

    public uploadCompanyLogo(
        companyId: string,
        formData: FormData
    ): Observable<any> {
        return this.http
            .post<any>(
                `${this.serviceUrl}/upload?companyId=${companyId}&documentType=${DocumentType.COMPANY_LOGO}`,
                formData,
                {
                    reportProgress: true,
                    observe: 'events',
                }
            )
            .pipe(map((res) => this.parse(res)));
    }

    public uploadCompanyImages(formData: FormData, companyId): Observable<any> {
        return this.http
            .post<any>(
                `${this.serviceUrl}/upload?documentType=${DocumentType.PORTRAIT_PHOTO}&companyId=${companyId}`,
                formData,
                {
                    reportProgress: true,
                    observe: 'events',
                }
            )
            .pipe(map((res) => this.parse(res)));
    }

    public uploadArticleImages(formData: FormData, articleId): Observable<any> {
        return this.http
            .post<any>(
                `${this.serviceUrl}/upload?documentType=${DocumentType.PORTRAIT_PHOTO}&articleId=${articleId}`,
                formData,
                {
                    reportProgress: true,
                    observe: 'events',
                }
            )
            .pipe(map((res) => this.parse(res)));
    }

    public getBeneficiaryDocuments(
        beneficiaryId: string
    ): Observable<Document[]> {
        return this.http
            .get<Document[]>(`${this.serviceUrl}/${beneficiaryId}/beneficiary`)
            .pipe(map((res) => this.parse(res)));
    }

    public getDonorDocuments(donorId: string): Observable<Document[]> {
        return this.http
            .get<Document[]>(`${this.serviceUrl}/${donorId}/donor`)
            .pipe(map((res) => this.parse(res)));
    }

    public downloadDocument(documentId: string): any {
        return this.http
            .get(`${this.serviceUrl}/${documentId}/download`, {
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map((response) => response.body));
    }

    public getArticleImageDocuments(articleId: string): Observable<Document[]> {
        return this.http
            .get<Document[]>(`${this.serviceUrl}/${articleId}/article`)
            .pipe(map((res) => this.parse(res)));
    }
}
