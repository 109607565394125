import {NgModule} from '@angular/core';
import {EmailService} from '../services/email.service';
import {CommonModule} from "@angular/common";
import {InputTextModule} from 'primeng/inputtext';
import {MailOutComponent} from './mail-out.component';

@NgModule ({
    imports: [CommonModule,InputTextModule ],
    providers: [EmailService]
})

export class MailOutModule {
}
