<form #emailFormID [formGroup]="vimanForm">

    <div class="virman-main-container">
        <div class="virman-container">
            <div class="grid" id="virmanId">
                <div class="col-12 md:col-6">
                    <div class="flex flex-column gap-1 text-lg" style="width:100%; margin:10px; margin-bottom:20px;font-weight: bold;">
                        {{'virman.virmanTitle' | translate}}
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:0;">
                        <label for="donorName">{{'virman.donorName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input  formControlName="DonorName" pInputText id="donorName"
                                   class="p-inputtext full-width"
                                   style="border-radius: 6px 6px 0 0;"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-top:-2px; margin-bottom:20px;">
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-map"></i>
                            <input formControlName="DonorAddress" pInputText id="donorAddress"
                                   class="p-inputtext full-width"
                                   style="border-radius: 0 0 6px 6px;  border-top:1px dashed grey;  "/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="DonorBankAccNr">{{'virman.donorAccountNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="DonorBankAccNr" pInputText id="DonorBankAccNr"
                                   class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="DonorBankName">{{'virman.donorBankName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="DonorBankName" pInputText id="DonorBankName"
                                   class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="PurposeText">{{'virman.purposeOfDonation' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                             <input formControlName="PurposeText" pInputText id="PurposeText"
                                    class="p-inputtext full-width" style="border-radius:6px; border:1px solid green;"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="PurposeNumber">{{'virman.donorReferenceNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                             <input pInputText id="PurposeNumber"
                                    formControlName="PurposeNumber"
                                    class="p-inputtext full-width" style="border-radius:6px"/>
                        </span>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="flex flex-column gap-1 text-lg"
                         style="width:100%; margin:10px; margin-bottom:20px; font-weight: bold;">
                        {{'virman.virmanSubTitle' | translate}}
                    </div>
                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:0;">
                        <label for="fixedReceiverName">{{'virman.beneficiaryName'|translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="fixedReceiverName" pInputText id="fixedReceiverName"
                                   class="p-inputtext full-width"
                                   style="border-radius:6px 6px 0 0 "/>
                        </span>
                    </div>
                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-top:-2px; margin-bottom:20px;">
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="fixedReceiverAddress" pInputText id="fixedReceiverAddress"
                                   class="p-inputtext full-width"
                                   style="border-radius:0 0 6px 6px; border-top:1px dashed grey;"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="fixedReceiverAccNr">{{'virman.beneficiaryAccountNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="fixedReceiverAccNr" pInputText id="fixedReceiverAccNr"
                                   class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="fixedReceiverBankName">{{'virman.beneficiaryBankName' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="fixedReceiverBankName" pInputText id="fixedReceiverBankName"
                                   class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="Amount">{{'virman.amount' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                            <input formControlName="Amount" pInputText id="Amount"
                                   class="p-inputtext full-width"
                                   style="border-radius:6px"/>
                        </span>
                    </div>

                    <div class="flex flex-column gap-1 text-sm" style="width:100%; margin:10px; margin-bottom:20px;">
                        <label for="BeneficiaryRefNr">{{'virman.beneficiaryReferenceNumber' | translate}}</label>
                        <span class="p-input-icon-left" style="width:100%; margin-left:-5px;">
                            <i class="pi pi-tag"></i>
                             <input pInputText id="BeneficiaryRefNr"
                                    formControlName="BeneficiaryID"
                                    class="p-inputtext full-width" style="border-radius: 0"/>
                        </span>
                    </div>

                </div>

                <div class="col-12">
                    <!--                    <div class="horizontal-line" style="margin-bottom:10px; margin-top:10px;"></div>-->
                    <div class="button-container">
                        <button type="button" class="mt-1 p-button" pButton icon="pi pi-times-circle" style="max-width: 120px; border-radius:6px;"
                                (click)="close()" label="Close">
                        </button>
                        <button type="button" class="mt-1 p-button" pButton icon="pi pi-download" style="max-width: 120px; border-radius:6px;"
                                (click)="makePDF()" label="Make PDF">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

