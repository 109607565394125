import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericApiService} from "../components/generics/generic-api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Bank} from "../model/bank";
import {environment} from "../../environments/environment";

@Injectable()
export class BankService extends GenericApiService<Bank> {
    override serviceUrl = `/${environment.API_REF}/banks`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public create(bank: Bank): Observable<Bank> {
        return this.http.post<string>(`${this.serviceUrl}/create`, bank)
            .pipe(map(res => this.parse(res)));
    }
}
