import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    setConsent(value: string) {
        localStorage.setItem('cookieConsent', value);
    }

    getConsent() {
        return localStorage.getItem('cookieConsent');
    }
}
