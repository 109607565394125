<p-confirmDialog header="Info" icon="pi pi-info-circle" [closable]="false"></p-confirmDialog>
<div class="surface-0 h-screen" style="top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="surface-0 pt-4 pl-4 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-0">
                <img [src]="'assets/opop/logo.png'" alt='' class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div class="surface-0" style="margin: 0 5% 0 5%;">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>

        <div class="flex align-items-center flex-column">
            <div class="flex flex-column w-full md:w-5 sm:w-full h-full py-6 px-4 align-items-center  ">

                <div class="p-inputgroup mt-6 mb-2">
                    <span class="p-inputgroup-addon w-9rem justify-content-start">
                        <i class="pi pi-envelope mr-2"></i>
                        <label for="email">{{'login.email' | translate}}</label>
                    </span>
                    <input id="email" formControlName="email" type="text" pInputText autocomplete="off"/>
                </div>

                <div class="p-inputgroup mt-2 mb-2">
                    <span class="p-inputgroup-addon w-9rem justify-content-start">
                        <i class="pi pi-phone mr-2"></i>
                        <label for="phoneNumber">{{'login.phone' | translate}}</label>
                    </span>
                    <input id="phoneNumber" formControlName="phoneNumber" type="text" pInputText autocomplete="off"/>
                </div>

                <div class="p-inputgroup mt-2 mb-2">
                        <span class="p-inputgroup-addon w-9rem justify-content-start">
                            <i class="pi pi-phone mr-2"></i>
                         <label for="password">{{'login.password' | translate}}</label>
                        </span>
                    <input id="password" formControlName="password" type="password"
                           pInputText autocomplete="off"
                           placeholder="{{'login.passwordInfo' | translate}}"/>
                    <small *ngIf="form.get('password').hasError('minlength')"
                           class="text-danger custom-small-text">
                        {{'login.passwordLength' | translate}}
                    </small>
                </div>

                <div class="p-inputgroup mt-2 mb-5">
                    <span class="p-inputgroup-addon w-9rem justify-content-start">
                        <i class="pi pi-phone mr-2"></i>
                        <label for="passwordRetype">{{'login.password' | translate}}</label>
                    </span>
                    <input id="passwordRetype" formControlName="passwordRetype" type="password"
                           pInputText autocomplete="new-password"
                           placeholder="{{'login.retypePasswordInfo' | translate}}"/>
                </div>

                <div *ngIf="form.controls.passwordRetype.value && form.controls.password.value !== form.controls.passwordRetype.value"
                     class="flex text-red-700 mb-3 md:text-l">Passwords mismatch
                </div>
                <button type="submit" icon="pi pi-arrow-right" pButton label="{{'btn.next' | translate}}" class="pt-3 pb-3 block"
                        style="width: 360px; margin-bottom: 32px;"
                        [disabled]="form.invalid || (form.controls.passwordRetype.value && form.controls.password.value !== form.controls.passwordRetype.value)"></button>

            </div>
        </div>
    </form>
</div>
<app-config [minimal]="true"></app-config>
