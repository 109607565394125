import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { MenuItem, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { TimerComponent } from "../timer.component";
import { errorHandler } from "../../../../support/functions";
import { DonorService } from "../../../../services/donor.service";
import { Donor } from "../../../../model/donor";
import { donorIndividualSteps } from "./donor-individual-steps";
import { donorOrganizationSteps } from "./donor-organization-steps";
import { DonorType } from "../../../../enums/donor-type";

@Component({
    templateUrl: './donor-register-step2.component.html'
})
export class DonorRegisterStep2Component implements OnInit {
    isDesktop: boolean = true;
    public form: UntypedFormGroup;
    private readonly donor: Donor;
    disableEmailOtp = false;
    disableSmsOtp = false;
    disableSubmit = false;
    @ViewChild('timerEmailOtp') timerEmailOtp: TimerComponent;
    @ViewChild('timerSmsOtp') timerSmsOtp: TimerComponent;
    items: MenuItem[];

    constructor(public layoutService: LayoutService, private router: Router, private donorService: DonorService,
                private msgService: MessageService, private translate: TranslateService) {
        this.onResize();
        this.donor = this.router.getCurrentNavigation().extras?.state?.donor;
        this.items = this.donor.donorType === DonorType.INDIVIDUAL ? donorIndividualSteps(this.translate) : donorOrganizationSteps(this.translate);
        this.form = new UntypedFormGroup({
            emailPin: new UntypedFormControl('1111'),
            phonePin: new UntypedFormControl('0000'), // Set default value to "0000"
            donorId: new UntypedFormControl(null)
        });
    }

    ngOnInit(): void {
        if (!this.donor) {
            this.router.navigate(['/'])
        } else {
            // console.log ( "Will show the view ");
            this.submitOtp(this.donor.id);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }
    get dark(): boolean {
        return this.layoutService.config.colorScheme !== 'light';
    }

    submitOtp(donorId: string) {
        console.log('---> Calling OTP');
        const otpConfirmation = {
            donorId: donorId,
            emailPin: '1111',
            phonePin: '0000'
        };

        firstValueFrom(this.donorService.verifyOtp(otpConfirmation))
            .then(result => {
                    this.disableSubmit = false;
                    if (result && result.correct) {
                        console.log('---> Going to 3');
                        this.router.navigateByUrl(`auth/register/donor/step3`, { state: { donor: this.donor } });
                    } else {
                        console.log('---> OTP Verification Failed');
                        this.msgService.add({
                            severity: 'error', life: 6000,
                            summary: 'Error',
                            detail: this.translate.instant('OTP verification failed'),
                        });
                    }
                },
                error => {
                    this.disableSubmit = false;
                    this.msgService.add({
                        severity: 'error', life: 6000,
                        summary: 'Error',
                        detail: this.translate.instant('OTP verification failed'),
                    });
                });
    }

    submit() {
        this.disableSubmit = true;
        this.form.controls.donorId.setValue(this.donor?.id);

        const otpConfirmation = this.form.value;
        otpConfirmation.emailPin = otpConfirmation.emailPin.replaceAll(' ', '');
        otpConfirmation.phonePin = '0000'; // Simulate phone pin as "0000"
        otpConfirmation.emailPin = '1111';
        otpConfirmation.donorId = this.donor?.id;

        firstValueFrom(this.donorService.verifyOtp(otpConfirmation))
            .then(result => {
                    if (result && result.correct) {
                        this.router.navigateByUrl(`auth/register/donor/step3`, { state: { donor: this.donor } });
                    } else {
                        errorHandler(this.translate.instant('authentication.otp.invalidOtpCode'), {}, this.msgService, this.translate, this.router);
                    }

                    this.disableSubmit = false;
                },
                error => {
                    this.msgService.add({
                        severity: 'error', life: 6000,
                        summary: 'Error',
                        detail: this.translate.instant(error.error?.message),
                    });
                    this.disableSubmit = false;
                }
            );
    }

    requestNewOtpSms() {
        if (!this.donor) {
            return;
        }

        this.disableSmsOtp = true;

        firstValueFrom(this.donorService.regenerateOtpPhone(this.donor.id))
            .then(() => {
                    this.disableSmsOtp = true;
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('authentication.otp.smsOtpCodeRequested')
                    })

                    this.timerSmsOtp.count();
                    setTimeout(() => this.disableSmsOtp = false, 30000);
                },
                error => {
                    this.disableSmsOtp = false;
                    errorHandler(this.translate.instant('authentication.otp.requestNewOtpSmsFailed'), error, this.msgService, this.translate, this.router)
                }
            );
    }

    requestNewOtpEmail() {
        if (!this.donor) {
            return;
        }

        this.disableEmailOtp = true;

        firstValueFrom(this.donorService.regenerateOtpEmail(this.donor.id))
            .then(() => {
                    this.disableEmailOtp = true;
                    this.msgService.add({
                        severity: 'info', life: 7000,
                        detail: this.translate.instant('authentication.otp.emailOtpCodeRequested')
                    })

                    this.timerEmailOtp.count();
                    setTimeout(() => this.disableEmailOtp = false, 30000);
                },
                error => {
                    this.disableEmailOtp = false;
                    errorHandler(this.translate.instant('authentication.otp.requestNewOtpEmailFailed'), error, this.msgService, this.translate, this.router)
                }
            );
    }
}
