import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GenericApiService } from '../components/generics/generic-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    BankTransaction,
    Transaction,
    TransactionFilter,
    TransactionStatusUpdate,
} from '../model/transaction';
import { PageRequestByExample, PageResponse } from '../support/page';
import { LazyLoadEvent } from 'primeng/api';
import { environment } from '../../environments/environment';

@Injectable()
export class TransactionService extends GenericApiService<Transaction> {
    override serviceUrl = `/${environment.API_REF}/transactions`;
    private baseExpenseServiceUrl = `${environment.API_REF}/expense`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public getWalletTransactions(
        transactionFilter: TransactionFilter,
        event: LazyLoadEvent
    ): Observable<PageResponse<Transaction>> {
        console.log("==> TransactionFilter",transactionFilter);
        console.log("==> ServiceURL",`${this.serviceUrl}/`);




        const body = new PageRequestByExample(transactionFilter, event);
        console.log("==> body",body);

        return this.http
            .post<PageResponse<Transaction>>(`${this.serviceUrl}/`, body)
            .pipe(map((res) => this.parse(res)));
    }

    public getTransactionByTransactionId(
        transactionId: string
    ): Observable<Transaction> {
        return this.http
            .get<Transaction[]>(
                `${this.serviceUrl}/${transactionId}/transaction_id`
            )
            .pipe(map((res) => this.parse(res)));
    }

    private options = {
        headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
        ),
    };

    public donateLocally(transactionId: any): Observable<void> {
        console.log ('Transaction-?>DonateLocaly', transactionId);
        let body = new URLSearchParams();
        body.set('Details2', transactionId);
        body.set('cPayPaymentRef',makeid(8))
        return this.http
            .post<any>(
                `${this.serviceUrl}/cpay_success`,
                body.toString(),
                this.options
            )
            .pipe(map((res) => this.parse(res)));
    }

    public randomCpayRef(): string {
        var randomChars = '0123456789';
        var result = '';
        for (var i = 0; i < 8; i++) {
            result += randomChars.charAt(
                Math.floor(Math.random() * randomChars.length)
            );
        }
        return result;
    }
    public updateTransactionAvailable(cpayId: string, fee: number): Observable<void> {
        const url = `${this.serviceUrl}/available/${cpayId}`;
        const body = { casysFee: fee.toString()};
        // const body = { casysFee: fee};

        console.log('cpayId, fee:',cpayId, fee);
        return this.http.put<void>(url, body);
    }

    public createNewTransaction(transaction: BankTransaction): Observable<any> {
        const saveExpenseUrl = `${this.baseExpenseServiceUrl}/save`; // Using template literals for better readability
        console.log ('--> saveExpenseUrl', saveExpenseUrl)
        return this.http.post(saveExpenseUrl, transaction);
    }

    public getAllExpenses(lazyLoadEvent: LazyLoadEvent): Observable<PageResponse<BankTransaction>> {
        const url = `${this.baseExpenseServiceUrl}/getAllExpenses`;
        return this.http.post<PageResponse<BankTransaction>>(url, { lazyLoadEvent })
            .pipe(
                map(response => {
                    // You can add any transformations here if needed
                    return response;
                })
            );
    }
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
