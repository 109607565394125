import {Component, Input} from "@angular/core";
import {Cpay} from "../../model/cpay";

@Component({
    selector: "app-cpay",
    template: `
        <!-- Amount to pay multiplied by 100 - without decimal and separators, i.e. amount 1,00 will be value 100-->
        <input id="AmountToPay" name="AmountToPay" [(ngModel)]="cpay.AmountToPay" type="hidden"/>

        <!--  Merchant ID - assigned manually for the time being -->
        <input id="PayToMerchant" name="PayToMerchant" [(ngModel)]="cpay.PayToMerchant" type="hidden"/>

        <!--  Merchant name -->
        <input id="MerchantName" name="MerchantName" [(ngModel)]="cpay.MerchantName" type="hidden"/>

        <!--  Currency of payment -->
        <input id="AmountCurrency" name="AmountCurrency" [(ngModel)]="cpay.AmountCurrency" type="hidden"/>

        <!-- User-friendly details of payment-->
        <input id="Details1" name="Details1" [(ngModel)]="cpay.Details1" type="hidden"/>

        <!-- Merchant specific details (OrderID - unique for each payment)-->
        <input id="Details2" name="Details2" [(ngModel)]="cpay.Details2" type="hidden"/>

        <!-- Merchant PaymentSentOK redirect URL-->
        <input id="PaymentOKURL" size="10" name="PaymentOKURL" [(ngModel)]="cpay.PaymentOKURL" type="hidden"/>

        <!-- Merchant PaymentFailed redirect URL-->
        <input id="PaymentFailURL" size="10" name="PaymentFailURL" [(ngModel)]="cpay.PaymentFailURL"
               type="hidden"/>

        <input id="CheckSumHeader" name="CheckSumHeader" [(ngModel)]="cpay.CheckSumHeader" type="hidden"/>

        <input id="CheckSum" name="CheckSum" [(ngModel)]="cpay.CheckSum" type="hidden"/>
    `
})
export class CpayFieldsComponent {
    @Input()
    cpay: Cpay;

    constructor() {
    }
}
