<div class="surface-0 w-full centered-col" style="height: 100vh; background-color: #f2f2f2;">

    <div class="pt-4 pl-6 pb-0 m-0 ">
        <a [routerLink]="['/']" class="mb-2">
            <img [src]="'assets/opop/logo.png'" class="login-logo" style="width: 100px; margin-left:20px" alt="OpOp"/>
        </a>
    </div>

    <div *ngIf="isDesktop">
        <p-steps [model]="itemsOrganization" [readonly]="true"  *ngIf="donorType.value === 'ORGANIZATION'"></p-steps>
        <p-steps [model]="itemsIndividual"   [readonly]="true"  *ngIf="donorType.value === 'INDIVIDUAL'"></p-steps>
    </div>

    <div class="registerScreens-width innerCentered-col custom-margin">
        <div style="margin-top:60px; margin-bottom:10px;  width: 100%;">
            <p-selectButton id="registerDonorType"
                            [options]="donorTypeOptions"
                            [formControl]="donorType"
                            optionLabel="label"
                            optionValue="value"
                            class="center-labels custom-select-button">
            </p-selectButton>
        </div>

        <p style="margin:40px; width: 100%;">
            {{'footer.presentTermsAndCondiditions_1' | translate}}&nbsp;
            <strong><u class="cursor-pointer" (click)="showTerms()">
                {{'footer.presentTermsAndCondiditions_2' | translate}}</u></strong>&nbsp;
            {{'footer.presentTermsAndCondiditions_3' | translate}}
        </p>

        <button type="submit" pButton pRipple label="{{'btn.next' | translate}}"
                class="w-full margin-bottom:20px;" icon="pi pi-arrow-right"
                [disabled]="donorType.invalid || termsAgreed.invalid" (click)="submit()">
        </button>
    </div>
</div>
