import { Component } from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {EmailService} from "../services/email.service";  // Import your email service here
import {Router} from "@angular/router";
import {Input} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute } from '@angular/router';
import {TranslateService } from '@ngx-translate/core'; // or your translation service
import {Language} from '../components/generics/language.service';
import {LanguageService} from '../components/generics/language.service';
import {Subscription } from 'rxjs';

@Component({
  selector: 'app-mail-out',
  templateUrl: './mail-out.component.html'
})

export class MailOutComponent {
    @Input() prefillEmail: string;
    @Input() prefillSubject: string;
    @Input() prefillCC: string;
    @Input() prefillBCC: string;
    @Input() prefillReplyTo: string;
    logoSrc: string;
    language: Language;
    private langSubscription: Subscription;
    displayDialog: boolean = false;
    message: string = '';


    public emailForm: FormGroup;
    constructor(private emailService: EmailService,
                private router: Router,
                private route: ActivatedRoute,
                private location: Location,
                private translateService: TranslateService,
                private languageService: LanguageService) {

        this.emailForm = new FormGroup({
            email:      new UntypedFormControl(null, [Validators.required, Validators.email]),
            subject:    new UntypedFormControl(null, Validators.required),
            message:    new UntypedFormControl(null),
            cc:         new UntypedFormControl(null),
            bcc:        new UntypedFormControl(null),
            replyTo:    new UntypedFormControl(null),
        });

        this.route.paramMap.subscribe(params => {
            const prefillEmail      = params.get('email');
            const prefillSubject    = params.get('subject');
            const prefillCC         = params.get('cc');
            const prefillBCC        = params.get('bcc');
            const prefillReplyTo    = params.get('replyTo');

            if (prefillEmail) {
                this.emailForm.get('email').setValue(prefillEmail);
            }
            if (prefillSubject) {
                this.emailForm.get('subject').setValue(prefillSubject);
            }
            if (prefillCC) {
                this.emailForm.get('cc').setValue(prefillCC);
            }
            if (prefillBCC) {
                this.emailForm.get('bcc').setValue(prefillBCC);
            }
            if (prefillBCC) {
                this.emailForm.get('replyTo').setValue(prefillReplyTo);
            }
        });
    }


    ngOnInit() {
        this.langSubscription = this.languageService.getSelectedLanguage().subscribe(lang => {
                this.language = lang;
        });
        this.setLogoBasedOnLanguage();
        if (this.prefillEmail) {
            this.emailForm.get('email').setValue(this.prefillEmail);
        }
        if (this.prefillSubject) {
            this.emailForm.get('subject').setValue(this.prefillSubject);
        }
        if (this.prefillCC) {
            this.emailForm.get('cc').setValue(this.prefillCC);
        }
        if (this.prefillBCC) {
            this.emailForm.get('bcc').setValue(this.prefillBCC);
        }
        if (this.prefillReplyTo) {
            this.emailForm.get('replyTo').setValue(this.prefillReplyTo);
        }
    }

    ngOnDestroy(): void {
        if (this.langSubscription) {
            this.langSubscription.unsubscribe();
        }
    }

    setLogoBasedOnLanguage(): void {
        const currentLang = this.language.code;
        console.log('==>> currentLang:',this.language );
        console.log('==>> currentLangCode:',currentLang );

        if (currentLang === 'mk') {
            this.logoSrc = 'assets/opop/Logo_Konekt_kirilica.jpg';
            console.log('==>> logo1:',this.logoSrc );
        } else {
          this.logoSrc = 'assets/opop/Logo_Konekt_latinica.jpg';
          console.log('==>> logo2:',this.logoSrc );
        }
      }

    protected validate(): boolean {
        return true;
    }


    submit() {
        const emailData = this.emailForm.getRawValue();
        this.emailService.sendEmail(emailData).subscribe(
            response => {
                this.message = 'Email sent successfully222!';
                this.displayDialog = true;
                this.location.back();
            },
            error => {
                this.message = 'Error sending email. Please try again.';
                this.displayDialog = true;
            }
        );
    }

    close() {
        this.location.back();
    }
}
