import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-youtube-player',
    templateUrl: './youtube-player.html'
})
export class YoutubePlayerComponent implements OnChanges {
    @Input() videoId: string;
    videoUrl: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.videoId && this.videoId) {
            this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.videoId}`);
            // https://youtu.be/NP93sKsgigc?si=xV7ZFZmfYrueT4dc
            // this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/NP93sKsgigc`);
        }
    }
}
