import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";
import {Municipality} from "../model/municipality";
import {environment} from "../../environments/environment";

@Injectable()
export class MunicipalityService extends GenericApiService<Municipality> {
    override serviceUrl = `/${environment.API_REF}/municipalities`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Municipality[]> {
        return this.http.get<Municipality[]>(this.serviceUrl)
            .pipe(map(res => this.parse(res)));
    }
    public filter(query: string): Observable<Municipality[]> {
        return this.http.get<Municipality[]>(`${this.serviceUrl}/autocomplete?name=${query}`)
            .pipe(map(res => this.parse(res)));
    }
}
