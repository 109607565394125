<p-confirmDialog header="Info" icon="pi pi-info-circle" [closable]="false"></p-confirmDialog>

<p-dialog [visible]="showDialog"
          (onHide)="showDialog=false">

        <ng-template pTemplate="header">
                <div style="font-weight: bold; font-size: 16px;">{{'login.welcome' | translate}} {{ displayName }}</div>
        </ng-template>

        <div style="font-weight:normal; font-size: 12px;"> {{'msg.successfullyCreatedDonor2' | translate}} </div>
        <div style="font-weight:normal; font-size: 12px;"> {{'msg.successfullyCreatedDonor3' | translate}} </div>


        <ng-template pTemplate="footer">
            <button type="button" pButton label="OK" (click)="onDialogOk()"></button>
        </ng-template>
</p-dialog>

<div class="surface-0" style="top: 0; left: 0; width: 100%; height: 100%; background-color: #f2f2f2;">

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="surface-0 p-4 m-0 ">
            <a [routerLink]="['/']" class="mb-2">
                <img [src]="'assets/opop/logo.png'" class="login-logo" style="width: 100px; margin-left:20px"/>
            </a>
        </div>

        <div *ngIf="isDesktop">
            <div class="surface-0" style="margin-left: 15%; margin-right: 15%; margin-top:0%; margin-bottom:0%;">
                <p-steps [model]="items" [readonly]="true"></p-steps>
            </div>
        </div>

        <div class="surface-0">
            <div class="flex align-items-center  flex-column h-screen">
                <div class="flex flex-column w-full md:w-5 sm:w-full h-full py-6 px-4 align-items-center">

                    <div class="p-inputgroup mt-2 mb-2" style="width: 100%;">
                        <span class="p-inputgroup-addon w-9rem justify-content-start">
                            <i class="pi pi-envelope mr-2"></i>
                            <label for="email">{{'login.email' | translate}}</label>
                        </span>
                        <input id="email" formControlName="email" type="text" pInputText autocomplete="off"/>

                    </div>

                    <div class="p-inputgroup mt-2 mb-2" style="width: 100%;">
                        <span class="p-inputgroup-addon w-9rem justify-content-start">
                            <i class="pi pi-phone mr-2"></i>
                            <label for="phoneNumber">{{'login.phone' | translate}}</label>
                        </span>
                        <input id="phoneNumber"
                               formControlName="phoneNumber"
                               type="text" pInputText autocomplete="off"/>

                    </div>

                    <div class="p-inputgroup mt-2 mb-2" style="width: 100%;">
                        <span class="p-inputgroup-addon w-9rem justify-content-start">
                            <i class="pi pi-phone mr-2"></i>
                            <label for="password">{{'login.password' | translate}}</label>
                        </span>

                        <input id="password" formControlName="password" type="password"
                               pInputText autocomplete="off"
                               placeholder="{{'login.passwordInfo' | translate}}"/>
<!--                        <small *ngIf="form.get('password').hasError('minlength')"-->
<!--                               class="text-danger custom-small-text">-->
<!--                              {{'login.passwordLength' | translate}}-->
<!--                        </small>-->
                    </div>

                    <div class="p-inputgroup mt-2 mb-5" style="width: 100%;">
                        <span class="p-inputgroup-addon w-9rem justify-content-start">
                            <i class="pi pi-phone mr-2"></i>
                            <label for="passwordRetype">{{'login.password' | translate}}</label>
                        </span>
                        <input id="passwordRetype" formControlName="passwordRetype" type="password"
                               pInputText autocomplete="new-password"
                               placeholder="{{'login.retypePasswordInfo' | translate}}"/>
                    </div>

                    <div  *ngIf="form.controls.passwordRetype.value
                        && form.controls.password.value !== form.controls.passwordRetype.value"
                        class="flex text-red-700 mb-3 md:text-xs">Passwords mismatch
                    </div>

                    <button type="submit" icon="pi pi-arrow-right"
                            pButton
                            label="{{'btn.submit' | translate}}"
                            class="pt-3 pb-3 block"
                            style="max-width: 320px; margin-bottom: 32px;"
                            [disabled]="form.invalid || (form.controls.passwordRetype.value && form.controls.password.value !== form.controls.passwordRetype.value)">
                    </button>

                </div>
            </div>
        </div>
    </form>
</div>
<app-config [minimal]="true"></app-config>
