<div class="surface-0 w-full h-full centered-col" style=" background-color: #f2f2f2;">
    <form [formGroup]="form" (ngSubmit)="submit()">

        <div class="pt-4 pl-6 pb-0 m-0 ">
            <a [routerLink]="['/']" class="mb-2">
                <img [src]="'assets/opop/logo.png'" class="login-logo" style="width: 100px; margin-left:20px" alt="OpOp"/>
            </a>
        </div>

        <div *ngIf="isDesktop">
            <div style="padding-top:20px; padding-bottom:20px; margin-left:20px; margin-right:20px; margin-top:0; margin-bottom:0;">
               <p-steps [model]="items" [readonly]="true"></p-steps>
            </div>
        </div>

        <div class="flex align-items-center flex-column  w-full">
            <div class="flex flex-column w-full md:w-5 sm:w-full h-full py-0 px-1 align-items-center ">
                <div class="flex flex-column w-full  h-full">
<!--                 I commented this out to disable the SMS OTP validation.-->
<!--                 Will bring it back when the Telekom SMS gateway starts working... -->

<!--                    <div class="form-container align-items-center text-center mt-3" style="max-width: 1000px; min-width: 400px;">-->
<!--                        <div class="border-1 surface-border p-3 z-index border-round">-->
<!--                            <span>{{'otp.verifySms' | translate}}</span>-->
<!--                            <div class="formgroup-inline mt-3 justify-content-center">-->
<!--                                <div class="field">-->
<!--                                    <div class="p-inputgroup p-fluid">-->
<!--                                        <span class="p-inputgroup-addon mt-2">-->
<!--                                            <i class="pi pi-comment"></i>-->
<!--                                            <span class="ml-1 text-l">{{'otp.sms' | translate}}</span>-->
<!--                                        </span>-->
<!--                                        <input type="text"-->
<!--                                               formControlName="phonePin"-->
<!--                                               [style]="{'width':'120px', 'border-radius': '0', 'margin-top':'7px'}"-->
<!--                                               class="p-inputtext p-component text-l p-3"-->
<!--                                               placeholder="PIN"-->
<!--                                               maxlength="4">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <button type="button"-->
<!--                                    class="mt-0 p-button-outlined"-->
<!--                                    pButton-->
<!--                                    label="{{'otp.requestNewOtpSms' | translate}}"-->
<!--                                    (click)="requestNewOtpSms()"-->
<!--                                    styleClass="text-m text-center p-button-link"-->
<!--                                    [disabled]="disableEmailOtp">-->
<!--                            </button>-->
<!--                            <div style="display: flex; justify-content: center;">-->
<!--                                <app-timer #timerSmsOtp></app-timer>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="form-container align-items-center text-center mt-3" style="max-width: 1000px; min-width: 400px;">
                        <div class="border-1 surface-border p-3 z-index border-round">
                            <span class="text-l">{{'otp.verifyEmail' | translate}}</span>
                            <div class="formgroup-inline mt-3 justify-content-center">
                                <div class="field">
                                    <div class="p-inputgroup p-fluid">
                    <span class="p-inputgroup-addon mt-2">
                        <i class="pi pi-envelope"></i>
                        <span class="ml-1 text-l">{{'otp.mail-out' | translate}}</span>
                    </span>
                                        <input type="text"
                                               formControlName="emailPin"
                                               [style]="{'width':'120px', 'border-radius': '0', 'margin-top':'7px'}"
                                               class="p-inputtext p-component text-l p-3"
                                               placeholder="PIN"
                                               maxlength="4">
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                                    class="mt-1 p-button-outlined"
                                    pButton
                                    label="{{'otp.requestNewOtpEmail' | translate}}"
                                    (click)="requestNewOtpEmail()"
                                    styleClass="text-m text-center p-button-link"
                                    [disabled]="disableEmailOtp">
                            </button>
                            <div style="display: flex; justify-content: center;">
                                <app-timer #timerEmailOtp></app-timer>
                            </div>
                        </div>
                    </div>


                    <div class="flex align-items-center flex-column w-full" style="margin-top:40px;margin-bottom:40px; max-width:1000px; min-width: 500px;">
                        <button type="submit" icon="pi pi-arrow-right" pButton label="{{'btn.next' | translate}}"
                                style="min-width: 120px; max-width: 120px; margin:auto; margin-top:0px;margin-bottom:0px;"
                                [disabled]="form.invalid || disableSubmit">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
