import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";
import {Country} from "../model/country";
import {environment} from "../../environments/environment";
import {Beneficiary} from "../model/beneficiary";

@Injectable({
  providedIn: 'root'
})

export class CountryService extends GenericApiService<Country> {
    override serviceUrl = `/${environment.API_REF}/countries`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public filter(query: string): Observable<Country[]> {
        return this.http.get<Country[]>(`${this.serviceUrl}/autocomplete?name=${query}`)
            .pipe(map(res => this.parse(res)));
    }


}
