import {TranslateService} from "@ngx-translate/core";

export enum DocumentType {
    CONTRACT="CONTRACT",
    CURRENT_ACCOUNT="CURRENT_ACCOUNT",
    ID_CARD="ID_CARD",
    PASSPORT="PASSPORT",
    PORTRAIT_PHOTO="PORTRAIT_PHOTO",
    COMPANY_LOGO="COMPANY_LOGO",
    COMPANY_PRESENTATION_IMAGE="COMPANY_PRESENTATION_IMAGE",
    SIGNED_CONTRACT="SIGNED_CONTRACT",
    LAST_FINANCIAL_REPORT="LAST_FINANCIAL_REPORT",
    LAST_NARRATIVE_REPORT="LAST_NARRATIVE_REPORT",
    PROJECT_DOCUMENT="PROJECT_DOCUMENT",
    PROJECT_COMPLETION="PROJECT_COMPLETION",
}

export function documentType_projectUploadItems(translate: TranslateService) {
    return [
        {
            label: translate.instant('enum.DocumentType.CONTRACT'),
            value: DocumentType.CONTRACT
        },
        {
            label: translate.instant('enum.DocumentType.SIGNED_CONTRACT'),
            value: DocumentType.SIGNED_CONTRACT
        },
        {
            label: translate.instant('enum.DocumentType.PROJECT_COMPLETION'),
            value: DocumentType.PROJECT_COMPLETION
        }

    ];
}

export function documentType_beneficiaryOrDonorDocumentUploadItems(translate: TranslateService) {
    return [
        {
            label: translate.instant('enum.DocumentType.CONTRACT'),
            value: DocumentType.CONTRACT
        },
        {
            label: translate.instant('enum.DocumentType.CURRENT_ACCOUNT'),
            value: DocumentType.CURRENT_ACCOUNT
        },
        {
            label: translate.instant('enum.DocumentType.ID_CARD'),
            value: DocumentType.ID_CARD
        },
        {
            label: translate.instant('enum.DocumentType.PASSPORT'),
            value: DocumentType.PASSPORT
        },
        {
            label: translate.instant('enum.DocumentType.SIGNED_CONTRACT'),
            value: DocumentType.SIGNED_CONTRACT
        },
        {
            label: translate.instant('enum.DocumentType.LAST_FINANCIAL_REPORT'),
            value: DocumentType.LAST_FINANCIAL_REPORT
        },
        {
            label: translate.instant('enum.DocumentType.LAST_NARRATIVE_REPORT'),
            value: DocumentType.LAST_NARRATIVE_REPORT
        },
        {
            label: translate.instant('enum.DocumentType.PROJECT_DOCUMENT'),
            value: DocumentType.PROJECT_DOCUMENT
        },
        {
            label: translate.instant('enum.DocumentType.PROJECT_COMPLETION'),
            value: DocumentType.PROJECT_COMPLETION
        }

    ];
}
