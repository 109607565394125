import {Component, Input} from "@angular/core";
import {Observable, takeWhile, timer} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-timer',
    template: `<span *ngIf="(timeRemaining$ | async) !== 0">{{ timeRemaining$ | async }}</span>`
})
export class TimerComponent {
    @Input() seconds = 30;
    timeRemaining$: Observable<number>;

    count() {
        this.timeRemaining$ = timer(0, 1000).pipe(
            map(n => (this.seconds - n)),
            takeWhile(n => n >= 0),
        );
    }

}
