import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericApiService } from '../components/generics/generic-api.service';
import { Donor, toDonorCreate, toDonorUpdate, TopUp } from '../model/donor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Otp, OtpResponse } from '../model/otp';
import { Donation, DonationOneTime } from '../model/donation';
import { Transaction, TransactionFilter } from '../model/transaction';
import { PageRequestByExample, PageResponse } from '../support/page';
import { LazyLoadEvent } from 'primeng/api';
import {
    Campaign,
    toCampaignCreate,
    toCampaignUpdate,
} from '../model/campaign';
import { environment } from '../../environments/environment';
import { Wallet } from '../model/wallet';

@Injectable()
export class DonorService extends GenericApiService<Donor> {
    override serviceUrl = `/${environment.API_REF}/donors`;

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public create(donor: Donor): Observable<Donor> {
        const options = {
            headers: this.getHeaders(),
        };
        const payload = toDonorCreate(donor);
        return this.http
            .post<string>(
                `${this.serviceUrl}/create`,
                toDonorCreate(donor),
                options
            )
            .pipe(map((res) => this.parse(res)));
    }
    public update(donor: Donor): Observable<Donor> {
        if (
            donor.company &&
            !(
                donor.company?.contactPerson?.firstName &&
                donor.company?.contactPerson?.lastName
            )
        ) {
            donor.company.contactPerson = null;
        }
        if (
            donor.company &&
            !(
                donor.company?.signaturePerson?.firstName &&
                donor.company?.signaturePerson?.lastName
            )
        ) {
            donor.company.signaturePerson = null;
        }

        return this.http
            .put<Donor>(`${this.serviceUrl}/`, toDonorUpdate(donor))
            .pipe(map((res) => this.parse(res)));
    }

    public verifyOtp(otpConfirmation: any): Observable<OtpResponse> {
        return this.http
            .post<string>(`${this.serviceUrl}/otp`, otpConfirmation)
            .pipe(map((res) => this.parse(res)));
    }

    public regenerateOtpEmail(donorId: string): Observable<Otp> {
        return this.http
            .put<string>(
                `${this.serviceUrl}/${donorId}/regenerate-otp-email`,
                {}
            )
            .pipe(map((res) => this.parse(res)));
    }

    public regenerateOtpPhone(donorId: string): Observable<Otp> {
        return this.http
            .put<string>(
                `${this.serviceUrl}/${donorId}/regenerate-otp-phone`,
                {}
            )
            .pipe(map((res) => this.parse(res)));
    }

    public getAllDonors(): Observable<Donor[]> {
        return this.http
            .get<Donor[]>(`${this.serviceUrl}`)
            .pipe(map((res) => this.parse(res)));
    }

    public getById(id: string): Observable<Donor> {
        return this.http
            .get<Donor>(`${this.serviceUrl}/${id}`)
            .pipe(map((res) => this.parse(res)));
    }

    public updateStatus(donorStatusUpdate: any): Observable<void> {
        return this.http
            .put<Donor>(`${this.serviceUrl}/update_status`, donorStatusUpdate)
            .pipe(map((res) => this.parse(res)));
    }

    public topup(topup: TopUp): Observable<Transaction> {
        return this.http
            .put<Transaction>(`${this.serviceUrl}/topup`, topup)
            .pipe(map((res) => this.parse(res)));
    }
    public donate(donation: Donation): Observable<Transaction> {
        return this.http
            .put<Transaction>(`${this.serviceUrl}/direct_donation`, donation)
            .pipe(map((res) => this.parse(res)));
    }

    public ccDonationDonor(donation: Donation): Observable<Transaction> {
        return this.http
            .put<Transaction>(
                `${this.serviceUrl}/donor_one_time_donation`,
                donation
            )
            .pipe(map((res) => this.parse(res)));
    }

    public donateFromWallet(donation: Donation): Observable<Transaction> {
        console.log('****>>> donateFromWallet', donation);
        return this.http
            .put<Transaction>(`${this.serviceUrl}/donation`, donation)
            .pipe(map((res) => this.parse(res)));
    }

    public donateFromWalletToSystem(
        donation: Donation
    ): Observable<Transaction> {
        return this.http
            .put<Transaction>(`${this.serviceUrl}/system_donation`, donation)
            .pipe(map((res) => this.parse(res)));
    }

    public donateOneTime( donationOneTime: DonationOneTime): Observable<Transaction> {
        return this.http
            .put<Transaction>(
                `${this.serviceUrl}/one_time_donation`,
                donationOneTime
            )
            .pipe(map((res) => this.parse(res)));
    }

    public donateSystemOneTime(
        donation: DonationOneTime
    ): Observable<Transaction> {
        console.log('--->>> donateSystemOneTime', donation);
        const systemDonationPayload = {
            amount: donation.amount,
            email: donation.email,
            firstName: donation.firstName,
            lastName: donation.lastName,
            // Include other necessary fields
        };

        return this.http
            .put<Transaction>(
                `${this.serviceUrl}/system_one_time_donation`,
                systemDonationPayload
            )
            .pipe(map((res) => this.parse(res)));
    }

    public walletDonation(donation: Donation): Observable<Transaction> {
        console.log('****>>> walletDonation', donation);
        return this.http
            .put<Transaction>(`${this.serviceUrl}/wallet_donate`, donation)
            .pipe(map((res) => this.parse(res)));
    }

    public ccDonation(donation: Donation): Observable<Transaction> {
        console.log('****>>> ccDonation', donation);
        return this.http
            .put<Transaction>(`${this.serviceUrl}/cc_donate`, donation)
            .pipe(map((res) => this.parse(res)));
    }

    public ccPlatformDonation(donation: Donation): Observable<Transaction> {
        return this.http
            .put<Transaction>(`${this.serviceUrl}/cc_donate_platform`, donation)
            .pipe(map((res) => this.parse(res)));
    }

    public walletPlatformDonation(donation: Donation): Observable<Transaction> {
        return this.http
            .put<Transaction>(
                `${this.serviceUrl}/wallet_donate_platform`,
                donation
            )
            .pipe(map((res) => this.parse(res)));
    }

    public getWalletTransactions(
        transactionFilter: TransactionFilter,
        event: LazyLoadEvent
    ): Observable<PageResponse<Transaction>> {
        const body = new PageRequestByExample(transactionFilter, event);

        return this.http
            .post<PageResponse<Transaction>>(
                `${this.serviceUrl}/wallet/transactions`,
                body
            )
            .pipe(map((res) => this.parse(res)));
    }

    public createCampaign(campaign: Campaign): Observable<Campaign> {
        return this.http
            .post<Campaign>(
                `${this.serviceUrl}/campaign`,
                toCampaignCreate(campaign)
            )
            .pipe(map((res) => this.parse(res)));
    }

    public updateCampaign(campaign: Campaign): Observable<Campaign> {
        return this.http
            .put<Campaign>(
                `${this.serviceUrl}/campaign`,
                toCampaignUpdate(campaign)
            )
            .pipe(map((res) => this.parse(res)));
    }

    public getCampaignById(id: string): Observable<Campaign> {
        console.log("getCampaignById:", id);
        console.log("theURL:", `${this.serviceUrl}/${id}/campaign`);

        return this.http
            .get<Campaign>(`${this.serviceUrl}/${id}/campaign`)
            .pipe(map((res) => this.parse(res)));
    }

    public getDonorCampaigns(): Observable<Campaign[]> {
        return this.http
            .get<Campaign[]>(`${this.serviceUrl}/campaigns`)
            .pipe(map((res) => this.parse(res)));
    }

    public getDonorWallet(): Observable<Wallet> {
        return this.http
            .get<Wallet>(`${this.serviceUrl}/wallet`)
            .pipe(map((res) => this.parse(res)));
    }
}
