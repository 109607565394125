import {Municipality} from "./municipality";
import {Country} from "./country";
import {IdReferenceType} from "./id-reference-type";

export interface Person {
    id: string;
    firstName: string;
    lastName: string;
    nickName: string;
    email: string;
    phone: string;
    streetAddress: string;
    municipality: Municipality;
    country: Country;
    city: string;
    zip: string;
    idCardNumber: string;
    passportNumber: string;
    lang:string;
}

export interface PersonType {
    id: number;
    name: string;
}

export interface PersonCreate {
    firstName: string;
    lastName: string;
    nickName: string;
    email: string;
    phone: string;
    streetAddress: string;
    municipality: Municipality;
    country: Country;
    city: string;
    zip: string;
    idCardNumber: string;
    passportNumber: string;
    lang:string;
    personType: IdReferenceType;
}

export interface PersonUpdate extends PersonCreate {
    id: string;
}

export interface CompanyPersonCreate {
    person: Person;
    personType: PersonType;
}

export function toPersonUpdate(person: Person) {
    return {
        id: person?.id,
        country: { code: person?.country?.code },
        email: person?.email,
        municipality: person?.municipality,
        firstName: person?.firstName,
        lastName: person?.lastName,
        nickName: person?.nickName,
        phone: person?.phone,
        streetAddress: person?.streetAddress,
        city: person?.city,
        zip: person?.zip,
        idCardNumber: person?.idCardNumber,
        passportNumber: person?.passportNumber,
        lang:person?.lang
    } as PersonUpdate
}

export function toPersonCreate(person: Person) {
    return {
        email: person?.email,
        municipality: person?.municipality,
        country: { code: person?.country?.code },
        firstName: person?.firstName,
        lastName: person?.lastName,
        nickName: person?.nickName,
        phone: person?.phone,
        streetAddress: person?.streetAddress,
        city: person?.city,
        zip: person?.zip,
        idCardNumber: person?.idCardNumber,
        passportNumber: person?.passportNumber,
        lang:person?.lang
    } as PersonCreate;
}

