import { Component, Input,Output, EventEmitter ,OnInit,OnChanges} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Docxtemplater from 'docxtemplater';
import * as PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import {b} from "@fullcalendar/core/internal-common";

@Component({
    selector: 'app-docx-processor',
    templateUrl: 'document-processor.component.html'
})
export class DocxProcessorComponent {
    @Output() documentGenerated: EventEmitter<Blob> = new EventEmitter();
    @Input() params: {
        templateDocumentPath: string;
        replacements: Array<{ find: string, replaceWith: string }>;
        outputDocumentName: string;
        title: string;
        emailTo:string;
        justIcon:boolean;
    };
    @Input() projectEndDate: Date;
    isUploadDisabled: boolean = true;
    get title(): string {
        return this.params?.title || 'Process Document';
    }

    get justIcon(): boolean {
        return this.params?.justIcon || false;
    }


    ngOnInit(): void {
        this.checkIfUploadShouldBeDisabled();
    }


    ngOnChanges(): void {
        this.checkIfUploadShouldBeDisabled();
    }
    private checkIfUploadShouldBeDisabled() {
        const currentDate = new Date();
        this.isUploadDisabled = currentDate < new Date(this.projectEndDate);
    }

    constructor(private http: HttpClient) {}

    processDocument() {
        this.loadAndModifyDocument();
    }

    loadAndModifyDocument() {
        this.http.get(this.params.templateDocumentPath, { responseType: 'arraybuffer' })
            .subscribe(data => {
                const zip = new PizZip(data);
                const doc = new Docxtemplater();
                doc.loadZip(zip);
                const setDataObj = {};
                this.params.replacements.forEach(rep => {
                    setDataObj[rep.find] = rep.replaceWith;
                });
                console.log (this.params);
                doc.setData(setDataObj);

                try {
                    doc.render();
                } catch (error) {
                    console.error(error);
                }

                const out = doc.getZip().generate({
                    type: 'blob',
                    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                });
                this.documentGenerated.emit(out);
                saveAs(out, this.params.outputDocumentName);
            });
    }
}
