import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GenericApiService} from "../components/generics/generic-api.service";
import {environment} from "../../environments/environment";

@Injectable()
export class FileService extends GenericApiService<any> {
    override serviceUrl = `/${environment.API_REF}/files`

    constructor(protected override http: HttpClient) {
        super(http);
    }

    public uploadArticleImage(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.serviceUrl}/image/upload/multiple`, formData, {
            reportProgress: true,
            observe: 'events'
        })
            .pipe(map(res => this.parse(res)));
    }
}
