
// Donor Registrate Individual Component / TS


import {Component,ViewEncapsulation,HostListener} from '@angular/core';
import {LayoutService} from 'src/app/layout/service/app.layout.service';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MenuItem, MessageService} from "primeng/api";
import {firstValueFrom} from "rxjs";
import {UserService}    from "../../../../services/user.service";
import {Country}        from "../../../../model/country";
import {CountryService} from "../../../../services/country.service";
import {DonorType} from "../../../../enums/donor-type";
import {DonorService} from "../../../../services/donor.service";
import {donorIndividualSteps} from "./donor-individual-steps";
import {errorHandler} from "../../../../support/functions";
import { FormControl } from '@angular/forms';
import {Language, LanguageService} from "../../../../components/generics/language.service";

@Component({
    templateUrl: './donor-register-individual.component.html',
    encapsulation: ViewEncapsulation.None
})

export class DonorRegisterIndividualComponent {
    isDesktop: boolean = true;
    items: MenuItem[];
    public form: UntypedFormGroup;
    donorType: DonorType;
    loading: boolean;
    emailExists: boolean;
    currentLanguage: Language;
    public countrySuggestions: Country[];

    constructor(public layoutService: LayoutService, private router: Router, private userService: UserService,
                private translate: TranslateService, private msgService: MessageService,
                private countryService: CountryService,
                private donorService: DonorService,
                private languageService:LanguageService) {
        this.onResize();
        this.donorType = this.router.getCurrentNavigation().extras?.state?.donorType;
        this.items = donorIndividualSteps(this.translate);

        this.form = new UntypedFormGroup({
            donorType: new UntypedFormControl(this.donorType, Validators.required),
            person: new UntypedFormGroup(
                {
                    firstName: new UntypedFormControl(null, Validators.required),
                    lastName: new UntypedFormControl(null, Validators.required),
                    email: new UntypedFormControl(null, Validators.required),
                    phone: new UntypedFormControl('389', Validators.required),
                    country: new UntypedFormControl(null, Validators.required),
                    streetAddress: new UntypedFormControl(),
                    municipality: new UntypedFormControl(),
                    city: new UntypedFormControl(),
                    zip: new UntypedFormControl(),
                    idCardNumber: new UntypedFormControl(),
                    passportNumber: new UntypedFormControl(),
                    lang:new UntypedFormControl('mk')
                }
            ),
            anonymous: new UntypedFormControl(false)
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.isDesktop = window.innerWidth >= 1024;
    }
    get dark(): boolean {
        return this.layoutService.config.colorScheme !== 'light';
    }

    submit() {
        this.currentLanguage = this.languageService.getCurrentLanguage();
        const personForm = this.form.controls.person as FormGroup;
        personForm.controls.lang.setValue(this.currentLanguage.code);
        this.loading = true;
        firstValueFrom(this.donorService.create(this.form.value))
            .then(result => {
                    this.loading = false;
                    this.router.navigateByUrl(`auth/register/donor/step2`, {state: {donor: result, individual: true}})
                },
                error => {
                    this.loading = false;
                    this.msgService.add({
                        severity: 'error', life: 6000,
                        summary: 'Error',
                        detail: this.translate.instant(error.error?.message),
                    });
                }
            );
    }

    onEmailInput() {
        const personForm = this.form.controls.person as FormGroup;
        if (!personForm.controls.email.value.invalid) {
            firstValueFrom(this.userService.checkEmail(personForm.controls.email.value))
                .then(exists => this.emailExists = exists);
        } else {
            this.emailExists = false;
        }
    }

    completeCountry(event: any) {
        firstValueFrom(this.countryService.filter(event.query)).then(results =>
                this.countrySuggestions = results,
            error => errorHandler('Error during auto-complete',
                error, this.msgService, this.translate, this.router)
        );
    }
}


